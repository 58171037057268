import { Switch } from '@mui/material'
import { ONE_HUNDRED_DOLLARS_MONEY_DTO } from '@traba/consts'
import { useAlert } from '@traba/context'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { InitialAcceptShiftBypasses } from '@traba/types'
import { Money } from '@traba/types'
import { GenericAcceptShiftBypasses, Shift } from '@traba/types'
import { ExperienceLevel } from '@traba/types'
import { Worker } from '@traba/types'
import { uniq } from 'lodash'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import {
  Button,
  Col,
  Modal,
  Row,
  DataTable,
  CopyTextIcon,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { ShiftTile } from 'src/components/ShiftTile'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useApi } from 'src/hooks/useApi'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useEligibilities } from 'src/hooks/useWorkerShiftsEligibilities'
import { getErrorMessage } from 'src/utils/errorUtils'
import {
  convertCentsToDollars,
  convertPayRateToCents,
} from 'src/utils/moneyUtils'
import {
  toPercentString,
  truncateString,
  formatPhoneNumber,
} from 'src/utils/stringUtils'
import { CircularProgress } from '../../components/base/CircularProgress/CircularProgress'
import { useAnalytics } from '../../hooks/useAnalytics'
import AcceptShiftBypass from './components/AcceptShiftBypass'
import MissingCriteria from './components/MissingCriteria'

type AcceptShiftModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerIds?: string[]
  shift: Shift
  handleSuccess?: () => void
  sentinelNotificationToUserId?: string
}

type AcceptedResData = { error: boolean; workerId: string }[]

export default function AcceptShiftModal(props: AcceptShiftModalProps) {
  if (!props.isOpen) {
    return null
  }

  return <AcceptShiftModalComponent {...props} />
}

async function addWorkersToShift({
  shiftId,
  workerIds,
  bypasses,
  sentinelNotificationToUserId,
}: {
  shiftId: string
  workerIds: string[]
  bypasses: GenericAcceptShiftBypasses
  sentinelNotificationToUserId?: string
}): Promise<AcceptedResData> {
  const { data } = await trabaApi.post(
    `/worker-shifts/${shiftId}/accept-shift`,
    { workerIds, bypasses, sentinelNotificationToUserId },
  )
  return data
}

async function addPaidBackupsToShift({
  shiftId,
  workerIds,
  companyId,
  bypasses,
  paidBackupPayAmount,
  paidBackupPayAmountMax,
}: {
  shiftId: string
  workerIds: string[]
  companyId: string
  bypasses: GenericAcceptShiftBypasses
  paidBackupPayAmount: number
  paidBackupPayAmountMax: Money
}): Promise<AcceptedResData> {
  if (
    paidBackupPayAmount <= 0 ||
    paidBackupPayAmount > paidBackupPayAmountMax.amount
  ) {
    throw new Error(
      `Paid backup pay amount must be between 1 and ${convertCentsToDollars(
        paidBackupPayAmountMax.amount,
      )}`,
    )
  }
  const { data } = await trabaApi.post(`paid-backup/shifts/${shiftId}/`, {
    workerIds,
    companyId,
    bypasses,
    paidBackupPayAmount,
  })
  return data
}

function AcceptShiftModalComponent({
  isOpen,
  handleClose: parentHandleClose,
  workerIds,
  shift,
  handleSuccess,
  sentinelNotificationToUserId,
}: AcceptShiftModalProps) {
  const { shiftId, companyId } = shift
  const { refetchActiveQueries } = useActiveQueries()
  const [loading, setLoading] = useState<boolean>(false)
  const [acceptedResData, setAcceptedResdata] = useState<AcceptedResData>([])
  const [bypasses, setBypasses] = useState<GenericAcceptShiftBypasses>({})
  const [paidBackupPayAmount, setPaidBackupPayAmount] = useState<
    number | undefined
  >(shift.paidBackupPayAmount)
  const [addPaidBackups, setAddPaidBackups] = useState<boolean>(false)
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { showError } = useAlert()
  const { trackAnalytics } = useAnalytics()

  const { paidBackupPayAmountMax = ONE_HUNDRED_DOLLARS_MONEY_DTO } =
    hotSettings ?? {}

  // Adding workerIds in this pattern, so it gets parsed as an array in the server
  const workerIdParams = uniq(workerIds)
    ?.map((w) => `workerIds[]=${w}`)
    .join('&')

  const { isLoading: isLoadingWorkers, data: workers } = useApi<Worker[]>(
    `/workers?${workerIdParams}`,
    {},
    { enabled: isOpen },
  )

  const { isLoadingEligibilityData, eligibilityData } = useEligibilities({
    workerIds: workerIds ?? [],
    shiftId: shiftId,
    isEnabled: isOpen,
  })

  const handleClose = () => {
    parentHandleClose()
  }

  /*
  Handle Submit Request
  */
  const handleAcceptShift = async () => {
    if (!workerIds) {
      return
    }
    setLoading(true)
    try {
      let data: AcceptedResData
      if (addPaidBackups && paidBackupPayAmount) {
        data = await addPaidBackupsToShift({
          shiftId,
          workerIds,
          companyId,
          bypasses,
          paidBackupPayAmount,
          paidBackupPayAmountMax,
        })
      } else {
        data = await addWorkersToShift({
          shiftId,
          workerIds,
          bypasses,
          sentinelNotificationToUserId,
        })
        if (sentinelNotificationToUserId) {
          trackAnalytics('Sentinel Action Taken Accept Shift', {
            sentinelNotificationToUserId,
            workerIds,
            shiftId,
            bypasses,
          })
        }
      }
      setAcceptedResdata(data)
      const hasError = data.find((d) => !!d.error)
      if (hasError) {
        console.error('error', hasError)
        throw new Error('Some workers could not be added. Check worker table.')
      }

      if (handleSuccess) {
        handleSuccess()
      }
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Assigning Workers to Shift')
    }
    refetchActiveQueries()
    setLoading(false)
    handleClose()
  }

  if (isLoadingWorkers || isLoadingEligibilityData || isLoadingHotSettings) {
    return (
      <Modal
        handleClose={handleClose}
        isOpen={isOpen}
        size={MODAL_SIZE.EXTRA_LARGE}
        title={'Add workers to shift'}
      >
        <Col
          style={{
            display: 'flex',
            with: '100%',
          }}
        >
          <CircularProgress size="medium" color={theme.colors.brand} />
        </Col>
      </Modal>
    )
  }
  if (!hotSettings) {
    return <div>Could not load hot settings</div>
  }

  const bypassSettings =
    hotSettings.acceptShiftBypasses &&
    hotSettings.acceptShiftBypasses.length > 0
      ? hotSettings.acceptShiftBypasses
      : InitialAcceptShiftBypasses

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_LARGE}
      title={'Add workers to shift'}
    >
      <Col style={{ display: 'flex', alignItems: 'space-between' }}>
        <Row
          flexCol
          fullWidth
          style={{ justifyContent: 'flex-start', flex: 1 }}
        >
          <Row mb={32}>
            <ShiftTile shiftId={shiftId} />
          </Row>

          <Text variant="h5" mb={8}>
            Workers to be added
          </Text>

          {/* ************ */}
          {/* Workers list */}
          {/* ************ */}
          {workers && (
            <DataTable
              headers={[
                'Name',
                'Worker ID',
                'Phone',
                'Tier',
                'Reliability',
                'Proven',
                'Missing Criteria',
              ]}
              rows={workers?.map((worker) => {
                const workerId = worker.workerId || worker.uid
                const eligibility = eligibilityData?.find(
                  (e) => e.workerId === (worker.workerId || worker.uid),
                )
                const shouldShowError = !!acceptedResData.find(
                  (d) => d.workerId === workerId,
                )?.error

                const phoneNumber = formatPhoneNumber(worker.phoneNumber, true)

                return {
                  key: workerId,
                  cells: [
                    {
                      renderFn: () => (
                        <div
                          style={
                            shouldShowError
                              ? {
                                  color: theme.palette.error.main,
                                  fontWeight: 'bold',
                                  transition: 'all 0.2s',
                                }
                              : {}
                          }
                        >
                          {worker.firstName} {worker.lastName}
                        </div>
                      ),
                    },
                    {
                      renderFn: () => (
                        <>
                          {truncateString(worker.workerId || worker.uid)}
                          <CopyTextIcon
                            textToCopy={worker.workerId || worker.uid}
                          />{' '}
                        </>
                      ),
                    },
                    {
                      renderFn: () => {
                        return (
                          <>
                            <Anchor href={`tel:${phoneNumber}`}>
                              {phoneNumber}
                            </Anchor>
                            <CopyTextIcon textToCopy={worker.phoneNumber} />
                          </>
                        )
                      },
                    },
                    {
                      renderFn: () => eligibility?.workerTierLevel,
                    },
                    {
                      renderFn: () =>
                        eligibility
                          ? `${toPercentString(eligibility?.reliability)}%`
                          : '-',
                    },
                    {
                      renderFn: () =>
                        eligibility?.experienceLevel === ExperienceLevel.Proven
                          ? 'Yes'
                          : 'No',
                    },
                    {
                      renderFn: () => (
                        <MissingCriteria
                          eligibility={eligibility}
                          bypasses={bypasses}
                          acceptShiftBypassSettings={bypassSettings}
                        />
                      ),
                    },
                  ],
                }
              })}
            />
          )}

          {/* ******** */}
          {/* Bypasses */}
          {/* ******** */}
          <Row mt={theme.space.med} flexCol>
            <Text variant="h5">Bypasses</Text>
            <Text variant="body3" mt={6}>
              Note: Bypasses checked here will be applied to all workers on the
              table above
            </Text>
          </Row>
          <AcceptShiftBypass
            shifts={[shift]}
            bypasses={bypasses}
            setBypasses={setBypasses}
            eligibilityData={eligibilityData}
            acceptShiftBypassSettings={bypassSettings}
            showPaidBackupsBypass={addPaidBackups}
          />
          <Row mt={theme.space.xxxs} flexCol>
            <Row alignCenter gap={theme.space.sm}>
              <Text variant="h5">Add Workers as Paid Backups</Text>
              <Switch
                checked={addPaidBackups}
                onClick={() => setAddPaidBackups((prev) => !prev)}
              />
            </Row>
            {addPaidBackups && (
              <Row alignCenter gap={theme.space.sm}>
                <Text variant="h5">Paid Backup Pay</Text>
                <NumberInput
                  value={
                    paidBackupPayAmount
                      ? convertCentsToDollars(paidBackupPayAmount)
                      : undefined
                  }
                  setValue={(x: number | undefined) => {
                    setPaidBackupPayAmount(
                      x ? convertPayRateToCents(x) : undefined,
                    )
                  }}
                  isMoney={true}
                  step={0.01}
                  min={1}
                  max={100}
                />
              </Row>
            )}
          </Row>
        </Row>
        <Row
          mt={16}
          fullWidth
          style={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}
        >
          <Button
            variant={ButtonVariant.OUTLINED}
            style={{ width: '200px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '200px' }}
            onClick={handleAcceptShift}
            loading={loading}
            disabled={
              !workerIds?.length ||
              (addPaidBackups &&
                (!paidBackupPayAmount ||
                  paidBackupPayAmount >
                    hotSettings.paidBackupPayAmountMax.amount ||
                  paidBackupPayAmount <= 0))
            }
          >
            Accept
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
