import {
  SentinelCheckpointBasedEventType,
  SentinelNotification,
  SentinelTriggerBasedEventType,
} from '@traba/types'
import { QueryClient } from 'react-query'
import { SentinelContextAction } from 'src/context/sentinelContext/SentinelContext'
import { invalidateSentinelQueries } from 'src/hooks/useSentinelNotifications'

const refetchHandler = (queryClient: QueryClient) => {
  return (data: { shiftId: string; internalUserId: string }[]) => {
    const shiftId = data[0]?.shiftId
    if (!shiftId) {
      console.log('Unknown shift ID for refetch notification')
      return
    }
    invalidateSentinelQueries(queryClient, shiftId)
  }
}

/**
 * When a notification comes in:
 *  1. Invalidate field monitor sentinel queries
 *  2. Invalidate sentinel query for that shift
 *  3. Invalidate sentinel query for user
 *     (used to load sidebar notifs on non-field monitor pages)
 *  4. Update context of received notif to trigger list re-rendering
 */
const newSentinelNotificationHandler = (
  queryClient: QueryClient,
  dispatch: React.Dispatch<SentinelContextAction>,
  showNewSentinelNotificationToast: (
    notification: SentinelNotification,
  ) => void,
) => {
  return (data: { notification: SentinelNotification }[]) => {
    const notification = data[0]?.notification
    const shiftId = notification.data.shift.shiftId
    if (!shiftId) {
      console.log('Unknown shift ID for websocket notification, ', notification)
      return
    }
    invalidateSentinelQueries(queryClient, shiftId)
    dispatch({
      type: 'PROCESS_WEBSOCKET_NOTIFICATION',
      payload: data[0]?.notification.id,
    })
    showNewSentinelNotificationToast(notification)
  }
}

export const createSentinelNotificationHandlers = (
  queryClient: QueryClient,
  dispatch: React.Dispatch<SentinelContextAction>,
  showNewSentinelNotificationToast: (
    notification: SentinelNotification,
  ) => void,
) => {
  return {
    'refetch-sentinel-notifications': refetchHandler(queryClient),
    sentinel: newSentinelNotificationHandler(
      queryClient,
      dispatch,
      showNewSentinelNotificationToast,
    ),
  }
}

export function mapSentinelEventToField(
  event: SentinelCheckpointBasedEventType | SentinelTriggerBasedEventType,
) {
  switch (event) {
    case SentinelTriggerBasedEventType.WORKER_LATE_CANCELED:
      return 'workerLateCanceled'
    case SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL:
      return 'workerAcceptedBackfillCall'
    case SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP:
      return 'workerAcceptedBackfillCallNoSignup'
    case SentinelCheckpointBasedEventType.WORKERS_PAYMENT_STOPPED:
      return 'workersPaymentStopped'
    case SentinelCheckpointBasedEventType.WORKERS_NOT_CONFIRMED:
      return 'workersNotConfirmed'
    case SentinelTriggerBasedEventType.WORKER_LEFT_JOBSITE:
      return 'workerLeftJobSite'
    case SentinelCheckpointBasedEventType.WORKERS_NOT_CLOCKED_IN:
      return 'clockInNotifications'
    case SentinelCheckpointBasedEventType.WORKERS_NOT_CLOCKED_OUT:
      return 'clockOutNotifications'
    case SentinelCheckpointBasedEventType.SHIFT_NOT_FILLED:
      return 'shiftNotFilled'
    case SentinelTriggerBasedEventType.WORKER_CONFIRMED_OVER_SLOTS_REQUESTED:
      return 'workerConfirmedOverSlotsRequested'
    case SentinelCheckpointBasedEventType.WORKERS_LOCATION_STATUS:
      return 'workersLocationStatus'
    case SentinelCheckpointBasedEventType.WORKERS_TRANSIT_STATUS:
      return 'workersTransitStatus'
    case SentinelTriggerBasedEventType.WORKER_ON_SHIFT_CALLED:
      return 'workerOnShiftCalled'
    case SentinelTriggerBasedEventType.WORKER_ON_SHIFT_MESSAGED:
      return 'workerOnShiftMessaged'
    case SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_CALLED:
      return 'contactedWorkerOnWaitlistCalled'
    case SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_MESSAGED:
      return 'contactedWorkerOnWaitlistMessaged'
  }
}

export function sentinelNotificationIncludesNotificationToUser(
  sentinelNotification: SentinelNotification,
  internalUserId: string,
): boolean {
  return !!(
    sentinelNotification?.sentinelNotificationToUser?.internalUserId ===
    internalUserId
  )
}
