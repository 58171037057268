import { theme } from '@traba/theme'
import {
  EligibilityConnection,
  EligibilityConnectionLevel,
  EligibilityConnectionMutationPayload,
  EligibilityConnectionsByType,
  EligibilityConnectionType,
  GlobalEligibilityConnections,
  LocationResponse,
  Role,
} from '@traba/types'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Button, ButtonVariant } from '../base-components/Button/Button'
import { Checkbox } from '../base-components/Checkbox'
import { Col } from '../base-components/Col'
import Row from '../base-components/Row'
import { SearchSelect } from '../base-components/SearchSelect/SearchSelect'
import { Tab, Tabs, TabsContainer } from '../base-components/TabPanel'
import { Toggle } from '../base-components/Toggle/Toggle'
import {
  eligibilityConnectionsToPayload,
  getEligibilitySelectionData,
} from './eligibilityConnectionsUtils'

export type IneligibleContentProps = {
  eligibilityConnections: EligibilityConnection[]
  setEligibilityConnections: Dispatch<SetStateAction<EligibilityConnection[]>>
}

export const EligibilityItem = (props: {
  id: string
  displayName: string
  selected: boolean
  onChange: (type: string) => void
}) => {
  return (
    <Row style={{ marginTop: theme.space.xs, width: '50%' }}>
      <Checkbox
        checked={props.selected}
        onChange={() => props.onChange(props.id)}
        label={props.displayName}
      />
    </Row>
  )
}

export const EligibilityContainer = (props: {
  ineligibility: Set<string>
  idList: string[][]
  setEligibilityConnections: Dispatch<SetStateAction<Set<string>>>
  locationIdToRegionMap: Map<string, string>
  regionId: string
}) => {
  const {
    ineligibility,
    idList,
    setEligibilityConnections,
    regionId,
    locationIdToRegionMap,
  } = props

  const filteredIdList = useMemo(() => {
    return idList.filter(
      (entry) =>
        regionId === 'ALL_REGIONS' ||
        locationIdToRegionMap.get(entry[0]) === regionId,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idList, regionId])

  const onChangeHandler = (type: string, isSelected: boolean) => {
    if (!isSelected) {
      setEligibilityConnections((prev) => {
        const newState = new Set(prev)
        newState.delete(type)
        return newState
      })
    } else {
      setEligibilityConnections((prev) => {
        const newState = new Set(prev)
        newState.add(type)
        return newState
      })
    }
  }

  return (
    <Col
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
        padding: `0px ${theme.space.lg}px`,
        gridAutoRows: '1fr',
      }}
    >
      {filteredIdList.map((entry) => {
        const [id, displayName] = entry
        const isSelected = !ineligibility.has(id)
        return (
          <Row key={`eligibilityItem_${id}`} fullWidth>
            <EligibilityItem
              id={id}
              key={`eligibilityItem_${id}`}
              displayName={displayName}
              selected={isSelected}
              onChange={(type) => onChangeHandler(type, isSelected)}
            />
          </Row>
        )
      })}
    </Col>
  )
}

// this function sets up all the states in the IneligibleSelect component
export const ineligibleSelectSetup = ({
  eligibilityConnections,
  globalEligibilityConnection,
  setGlobalEligibilityForRoles,
  setGlobalEligibilityForLocations,
  setIneligibleRoles,
  setIneligibleLocations,
  roleEntries,
  locationEntries,
}: {
  eligibilityConnections: EligibilityConnectionsByType
  globalEligibilityConnection: GlobalEligibilityConnections
  setGlobalEligibilityForRoles: Dispatch<
    SetStateAction<EligibilityConnectionType>
  >
  setGlobalEligibilityForLocations: Dispatch<
    SetStateAction<EligibilityConnectionType>
  >
  setIneligibleRoles: Dispatch<SetStateAction<Set<string>>>
  setIneligibleLocations: Dispatch<SetStateAction<Set<string>>>
  roleEntries: string[][]
  locationEntries: string[][]
}) => {
  const globalEligibilityForRoles =
    globalEligibilityConnection.globalEligibilityForRoles ??
    EligibilityConnectionType.ELIGIBLE

  const globalEligibilityForLocations =
    globalEligibilityConnection.globalEligibilityForLocations ??
    EligibilityConnectionType.ELIGIBLE

  setGlobalEligibilityForRoles(globalEligibilityForRoles)
  setGlobalEligibilityForLocations(globalEligibilityForLocations)

  // determine which roles are ineligible
  setIneligibleRoles(() => {
    let ineligibleRoles = new Set<string>()
    if (globalEligibilityForRoles === EligibilityConnectionType.INELIGIBLE) {
      ineligibleRoles = new Set(roleEntries.map((id) => id[0]))
      eligibilityConnections.roles.forEach((c) => {
        if (
          c.roleId &&
          c.eligibilityConnectionType === EligibilityConnectionType.ELIGIBLE
        ) {
          ineligibleRoles.delete(c.roleId)
        }
      })
      return ineligibleRoles
    } else {
      ineligibleRoles = new Set(
        eligibilityConnections.roles
          .filter(
            (c) =>
              c.eligibilityConnectionType ===
              EligibilityConnectionType.INELIGIBLE,
          )
          .map((c) => c.roleId || ''),
      )
    }

    return ineligibleRoles
  })

  // determine which locations are ineligible
  setIneligibleLocations(() => {
    let ineligibleLocations = new Set<string>()
    if (
      globalEligibilityForLocations === EligibilityConnectionType.INELIGIBLE
    ) {
      ineligibleLocations = new Set(locationEntries.map((id) => id[0]))
      eligibilityConnections.locations.forEach((c) => {
        if (
          c.locationId &&
          c.eligibilityConnectionType === EligibilityConnectionType.ELIGIBLE
        ) {
          ineligibleLocations.delete(c.locationId)
        }
      })
    } else {
      ineligibleLocations = new Set(
        eligibilityConnections.locations
          .filter(
            (c) =>
              c.eligibilityConnectionType ===
              EligibilityConnectionType.INELIGIBLE,
          )
          .map((c) => c.locationId || ''),
      )
    }
    return ineligibleLocations
  })
}

export const IneligibleSelect = ({
  eligibilityConnections,
  globalEligibilityConnection,
  roles,
  locations,
  workerId,
  companyId,
  setPayload,
}: {
  eligibilityConnections: EligibilityConnectionsByType
  globalEligibilityConnection: GlobalEligibilityConnections
  roles: Role[]
  locations: LocationResponse[]
  workerId: string
  companyId: string
  setPayload: Dispatch<
    SetStateAction<EligibilityConnectionMutationPayload | undefined>
  >
}) => {
  const [globalEligibilityForRoles, setGlobalEligibilityForRoles] = useState(
    EligibilityConnectionType.ELIGIBLE,
  )

  const [globalEligibilityForLocations, setGlobalEligibilityForLocations] =
    useState(EligibilityConnectionType.ELIGIBLE)

  const [eligibilityConnectionLevel, setEligibilityConnectionLevel] =
    useState<EligibilityConnectionLevel>(EligibilityConnectionLevel.ROLE)

  const roleEntries = roles.map((r) => [r.roleId, r.roleName])

  const uniqueRegions = [
    { value: 'ALL_REGIONS', label: 'All Regions' },
    ...Array.from(new Set(locations.map((r) => r.regionId))).map((r) => ({
      value: r,
      label: r,
    })),
  ]
  const locationIdToRegionMap = locations.reduce((map, location) => {
    map.set(location.locationId, location.regionId)
    return map
  }, new Map<string, string>())
  const locationEntries = locations.map((l) => [
    l.locationId,
    `${l.address.street1}${l.address.street2 || ''}, ${l.address.city}, ${l.address.state} ${l.address.postalCode}`,
  ])

  const [ineligibleRoles, setIneligibleRoles] = useState(new Set<string>())
  const [ineligibleLocations, setIneligibleLocations] = useState(
    new Set<string>(),
  )
  const [regionId, setRegionId] = useState<string>('ALL_REGIONS')

  const [initialTableState, setInitialTableState] = useState(0)

  useEffect(() => {
    ineligibleSelectSetup({
      eligibilityConnections,
      globalEligibilityConnection,
      setGlobalEligibilityForRoles,
      setGlobalEligibilityForLocations,
      setIneligibleRoles,
      setIneligibleLocations,
      roleEntries,
      locationEntries,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyId,
    workerId,
    eligibilityConnections,
    globalEligibilityConnection,
    setGlobalEligibilityForRoles,
    setGlobalEligibilityForLocations,
    setIneligibleRoles,
    setIneligibleLocations,
  ])

  useEffect(() => {
    setPayload(
      eligibilityConnectionsToPayload(
        workerId,
        companyId,
        globalEligibilityForRoles,
        globalEligibilityForLocations,
        ineligibleRoles,
        ineligibleLocations,
        roleEntries,
        locationEntries,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalEligibilityForRoles,
    globalEligibilityForLocations,
    ineligibleRoles,
    ineligibleLocations,
  ])

  const {
    ids,
    ineligibilities,
    setIneligibilities,
    toggleLabel,
    toggleState,
    setToggle,
  } = getEligibilitySelectionData(
    eligibilityConnectionLevel,
    roleEntries,
    locationEntries,
    ineligibleRoles,
    ineligibleLocations,
    setIneligibleRoles,
    setIneligibleLocations,
    globalEligibilityForRoles,
    globalEligibilityForLocations,
    setGlobalEligibilityForRoles,
    setGlobalEligibilityForLocations,
  )

  const selectAll = (globalConnection: EligibilityConnectionType) => {
    const newIneligibilities = new Set<string>()
    for (const id of ids) {
      const currentRegionId = locationIdToRegionMap.get(id[0])
      if (
        currentRegionId === undefined ||
        regionId === 'ALL_REGIONS' ||
        regionId === currentRegionId
      ) {
        globalConnection === EligibilityConnectionType.ELIGIBLE
          ? newIneligibilities.delete(id[0])
          : newIneligibilities.add(id[0])
      }
    }
    setIneligibilities(newIneligibilities)
  }

  return (
    <Col
      style={{
        width: '100%',
      }}
    >
      <TabsContainer
        style={{
          backgroundColor: theme.colors.Grey,
        }}
      >
        <Tabs value={initialTableState}>
          <Tab
            style={{
              paddingLeft: theme.space.sm,
              paddingRight: theme.space.sm,
            }}
            label={EligibilityConnectionLevel.ROLE}
            onClick={() => {
              setEligibilityConnectionLevel(EligibilityConnectionLevel.ROLE)
              setInitialTableState(0)
            }}
          />
          <Tab
            style={{
              paddingLeft: theme.space.sm,
              paddingRight: theme.space.sm,
              backgroundColor: theme.colors.Grey,
            }}
            label={EligibilityConnectionLevel.LOCATION}
            onClick={() => {
              setEligibilityConnectionLevel(EligibilityConnectionLevel.LOCATION)
              setInitialTableState(1)
            }}
          />
        </Tabs>
      </TabsContainer>
      <Row
        justifyBetween
        alignCenter
        style={{ marginTop: `-${theme.space.xs}px` }}
      >
        Worker is eligible for the following:
        <Row justifyEnd>
          <Button
            variant={ButtonVariant.CANCEL}
            onClick={() => selectAll(EligibilityConnectionType.INELIGIBLE)}
          >
            Unselect All
          </Button>
          <Button
            variant={ButtonVariant.TEXT}
            onClick={() => selectAll(EligibilityConnectionType.ELIGIBLE)}
          >
            Select All
          </Button>
        </Row>
      </Row>
      {eligibilityConnectionLevel === EligibilityConnectionLevel.LOCATION && (
        <Row
          justifyBetween
          alignCenter
          style={{ marginTop: theme.space.xs, width: '25%' }}
        >
          SELECT REGION:
          <SearchSelect
            options={uniqueRegions}
            selectItem={{ value: regionId, label: regionId }}
            handleSelect={(item) => setRegionId(item?.value || 'ALL_REGIONS')}
            label="Region"
          />
        </Row>
      )}
      <EligibilityContainer
        ineligibility={ineligibilities}
        idList={ids}
        setEligibilityConnections={setIneligibilities}
        regionId={
          eligibilityConnectionLevel === EligibilityConnectionLevel.LOCATION
            ? regionId
            : 'ALL_REGIONS'
        }
        locationIdToRegionMap={locationIdToRegionMap}
      />
      <Row
        style={{
          position: 'sticky',
          bottom: theme.space.sm,
          right: theme.space.sm,
          justifyContent: 'flex-end',
          zIndex: 1,
        }}
      >
        <Toggle
          style={{ position: 'sticky' }}
          label={toggleLabel}
          buttonState={toggleState === EligibilityConnectionType.ELIGIBLE}
          runOnChange={() => {
            setToggle(
              toggleState === EligibilityConnectionType.ELIGIBLE
                ? EligibilityConnectionType.INELIGIBLE
                : EligibilityConnectionType.ELIGIBLE,
            )
          }}
        />
      </Row>
    </Col>
  )
}
