import InfoIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  JobStatus,
  ShiftInvitationWithWorkerInfo,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { RobocallCommunication } from '@traba/types'
import { ExtendedShift, ShiftPayType } from '@traba/types'
import { WorkerShiftTimeToDestination } from '@traba/types'
import { getBreaksForWorkerShift, getTotalBreaksTimeString } from '@traba/utils'
import { addSeconds, differenceInMinutes } from 'date-fns'
import { useMemo, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Location } from 'src/assets/svg/icons/Location'
import {
  useBulkWorkerScores,
  indexByWorkerId,
} from 'src/hooks/useBulkWorkerScores'
import useNotes from 'src/hooks/useNotes'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { AdjustmentModal, ClockInModal, ClockOutModal } from 'src/modals'
import { formatDuration } from 'src/utils/dateUtils'
import { sortByProp } from 'src/utils/helperUtils'
import { getPayTypeLabel } from 'src/utils/shiftUtils'
import {
  formatPhoneNumber,
  getMoneyString,
  truncateString,
} from 'src/utils/stringUtils'
import {
  getWorkerShiftStringId,
  getWorkerShiftTimeToDestinationKey,
} from 'src/utils/workerShiftUtils'
import {
  AccountStatusBadge,
  Button,
  CopyTextIcon,
  Icon,
  Link,
  Row,
} from '../base'
import { ButtonVariant } from '../base/Button/types'
import { useModal } from '../base/Modal/Modal'
import { IMenuItem } from '../base/Select/Select'
import { DataTableHeader, TableCell, TableRow } from '../base/Table/DataTable'
import { VirtualizedTable } from '../base/Table/VirtualizedTable'
import EarningsSummaryPaymentIcon from '../EarningsSummaryPaymentIcon'
import InlineClockIn from '../InlineClockIn'
import InlineClockOut from '../InlineClockOut'
import { WorkerAction } from '../WorkerAction/WorkerAction'
import { WorkerNameWithIcons } from '../WorkerNameWithIcons/WorkerNameWithIcons'
import { WorkerNoteDrawer } from '../WorkerNoteDrawer'
import WorkerBusinessApprovalScore from './components/WorkerBusinessApprovalScore'
import WorkerCompletions from './components/WorkerCompletions'
import WorkerCoordinate from './components/WorkerCoordinate'
import WorkerReliabilityScore from './components/WorkerReliabilityScore'
import WorkerTableCommandBar from './components/WorkerTableCommandBar'
import WorkerTableConfirmationIcon from './components/WorkerTableConfirmationIcon'
import WorkerTableJobStatusCell from './components/WorkerTableJobStatusCell'

export type WorkerTableProps = {
  workerShifts: WorkerShift[]
  robocalls?: RobocallCommunication[]
  shift: ExtendedShift
  selectedRows?: TableRow[]
  setSelectedRows?: React.Dispatch<React.SetStateAction<TableRow[]>>
  setEmbedURL?: React.Dispatch<React.SetStateAction<string>>
  workerShiftTimeToDestinationsMap?: Map<string, WorkerShiftTimeToDestination>
  statusCounts: Map<string, number>
  statusOptions: IMenuItem[]
  statusFilters: IMenuItem[]
  setStatusFilters: (value: IMenuItem[]) => void
  handleOpenBulkAdjustModal?: () => void
  handleOpenBulkCancelModal?: () => void
  handleOpenBulkCancelMultiShiftModal?: () => void
  handleOpenBulkRobocallModal?: () => void
  workerSearchString?: string
  setWorkerSearchString?: React.Dispatch<React.SetStateAction<string>>
  shiftInvitations?: ShiftInvitationWithWorkerInfo[]
  favoriteWorkerIds?: Set<string>
  slim?: boolean
}

export function calculateStatusCounts(
  workerShifts: {
    jobStatus: JobStatus
    isShiftConfirmed?: boolean
    workerSiteArrivalTime?: Date
  }[],
): Map<JobStatus | string, number> {
  const statusCounts = new Map<JobStatus | string, number>()
  workerShifts.forEach((workerShift) => {
    statusCounts.set(
      workerShift.jobStatus,
      (statusCounts.get(workerShift.jobStatus) ?? 0) + 1,
    )
    // Filter for only worker shifts that are expected to work
    if (
      workerShift.jobStatus !== JobStatus.NoShow &&
      workerShift.jobStatus !== JobStatus.Canceled &&
      workerShift.jobStatus !== JobStatus.Rejected &&
      workerShift.jobStatus !== JobStatus.Appeased
    ) {
      // tally confirmations
      if (workerShift.isShiftConfirmed) {
        statusCounts.set('CONFIRMED', (statusCounts.get('CONFIRMED') ?? 0) + 1)
      }
      // tally arrivals
      if (workerShift.workerSiteArrivalTime) {
        statusCounts.set('ARRIVED', (statusCounts.get('ARRIVED') ?? 0) + 1)
      }
    }
  })

  return statusCounts
}

export function constructStatusOptions(
  statusCounts: Map<JobStatus | string, number>,
): IMenuItem[] {
  const statusOptions: IMenuItem[] = []

  statusCounts.forEach((_val, key) => {
    statusOptions.push({ label: key, value: key })
  })

  // Compound Filters
  statusOptions.push(
    { label: 'TODO But Not CONFIRMED', value: 'TODO But Not CONFIRMED' },
    {
      label: 'ARRIVED But Not IN_PROGRESS',
      value: 'ARRIVED But Not IN_PROGRESS',
    },
    // This filter is for all workers who might still be on the way to the shift so we can intercept
    {
      label: 'TODO & In Transit, Not Left or No Location Data',
      value: 'TODO & In Transit, Not Left or No Location Data',
    },
  )

  return statusOptions
}

export const WorkerTable = (props: WorkerTableProps) => {
  const {
    workerShifts,
    shift,
    selectedRows,
    setSelectedRows,
    setEmbedURL,
    statusCounts,
    workerShiftTimeToDestinationsMap,
    statusOptions,
    statusFilters,
    setStatusFilters,
    handleOpenBulkAdjustModal,
    handleOpenBulkCancelModal,
    handleOpenBulkCancelMultiShiftModal,
    handleOpenBulkRobocallModal,
    robocalls,
    workerSearchString,
    setWorkerSearchString,
    shiftInvitations,
    favoriteWorkerIds,
    slim,
  } = props
  const { showError } = useAlert()
  const tz = useTimezonedDates(shift.timezone)
  const [workerToOpen, setWorkerToOpen] = useState<string>('')
  const [workerNotesOpen, setWorkerNotesOpen] = useState<boolean>(false)
  const { useWorkersNotesCounts } = useNotes()
  const [allowTableSelect, setAllowTableSelect] = useState<boolean>(false)
  const [currentWorkerShift, setCurrentWorkerShift] =
    useState<WorkerShift | null>(null)
  const clockInModal = useModal()
  const clockOutModal = useModal()
  const adjustmentModal = useModal()
  const { hotSettings } = useHotSettings()
  const enableWorkerEditTime = hotSettings?.enableWorkerEditTime

  const onSelectRows = (selectedRows: TableRow[]) => {
    if (setSelectedRows) {
      setSelectedRows(selectedRows)
    }
  }

  const headers: (DataTableHeader | string)[] = useMemo(
    () => [
      ...(allowTableSelect === false ? ['Action'] : []),
      {
        key: 'name',
        label: 'Worker Name',
        sortable: true,
      },
      {
        key: 'accountStatus',
        label: 'Acct Status',
        sortable: true,
      },
      {
        key: 'workerId',
        label: 'Worker Id',
      },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
      },
      {
        key: 'shiftStatus',
        label: 'Shift Status',
        sortable: true,
      },
      {
        key: 'distance',
        label: 'Dist(mi)',
      },
      {
        key: 'confirmation',
        label: 'Conf',
      },
      {
        key: 'onTimeStatus',
        label: 'On Time Status',
      },
      {
        key: 'arrived',
        label: 'Arrived',
      },
      {
        key: 'eta',
        label: 'ETA',
      },
      {
        key: 'clockIn',
        label: 'Clock In',
      },
      {
        key: 'clockOut',
        label: 'Clock Out',
      },
      {
        key: 'clockOutSource',
        label: 'Clock Out Source',
      },
      {
        key: 'timeWorked',
        label: `${getPayTypeLabel(shift)} Worked`,
      },
      {
        key: 'breaks',
        label: 'Break Time',
      },
      {
        key: 'netPay',
        label: 'Net Pay',
      },
      {
        key: 'paid',
        label: 'Paid',
      },
      {
        key: 'reliability',
        label: 'Rel',
      },
      {
        key: 'businessApproval',
        label: 'Biz Aprv',
      },
      {
        key: 'numberOfShiftCompletions',
        label: '# Shifts',
      },
    ],
    [allowTableSelect, shift],
  )

  const slimHeaders: (DataTableHeader | string)[] = useMemo(
    () => [
      ...(allowTableSelect === false ? ['Action'] : []),
      {
        key: 'name',
        label: 'Worker Name',
        sortable: true,
      },
      {
        key: 'accountStatus',
        label: 'Acct Status',
        sortable: true,
      },
      {
        key: 'workerId',
        label: 'Worker Id',
      },
      {
        key: 'phoneNumber',
        label: 'Phone Number',
      },
      {
        key: 'shiftStatus',
        label: 'Shift Status',
        sortable: true,
      },
      {
        key: 'confirmation',
        label: 'Conf',
      },
      {
        key: 'clockIn',
        label: 'Clock In',
      },
      {
        key: 'clockOut',
        label: 'Clock Out',
      },
      {
        key: 'reliability',
        label: 'Rel',
      },
      {
        key: 'businessApproval',
        label: 'Biz Aprv',
      },
      {
        key: 'numberOfShiftCompletions',
        label: '# Shifts',
      },
    ],
    [allowTableSelect],
  )

  const sortedWorkerShifts = useMemo(() => {
    return workerShifts.sort((a, b) => {
      if (a.jobStatus !== b.jobStatus) {
        return b.jobStatus.localeCompare(a.jobStatus)
      }
      if (
        a.cancellationSource &&
        b.cancellationSource &&
        a.cancellationSource !== b.cancellationSource
      ) {
        return b.cancellationSource.localeCompare(a.cancellationSource)
      }
      const aName = `${a.worker?.firstName} ${a.worker?.lastName}`
      const bName = `${b.worker?.firstName} ${b.worker?.lastName}`
      return aName.localeCompare(bName)
    })
  }, [workerShifts])

  const workerIds = workerShifts.map((ws) => ws.workerId)

  const { isLoading: isWorkersNotesLoading, workersNotesCountMap } =
    useWorkersNotesCounts(workerIds)

  const { bulkWorkerScores } = useBulkWorkerScores(workerIds)

  const rows: TableRow[] = useMemo(() => {
    const openClockInModal = (workerShift: WorkerShift) => {
      setCurrentWorkerShift(workerShift)
      clockInModal.open()
    }

    const openClockOutModal = (workerShift: WorkerShift) => {
      setCurrentWorkerShift(workerShift)
      clockOutModal.open()
    }

    const openAdjustmentModal = (workerShift: WorkerShift) => {
      setCurrentWorkerShift(workerShift)
      adjustmentModal.open()
    }

    const handleEmbedUpdate = (workerShift: WorkerShift) => {
      if (!setEmbedURL) {
        return
      }
      if ('tripId' in workerShift) {
        const iframeElement = document.getElementById(
          `iframe_${workerShift.shiftId}`,
        )
        iframeElement?.scrollIntoView({ behavior: 'smooth' })
        const embedURL = new URL(
          `https://embed.hypertrack.com/trips/${workerShift.tripId}`,
        )
        embedURL.searchParams.append(
          'publishable_key',
          `${import.meta.env.VITE_HYPERTRACK_PUBLISHABLE_KEY}`,
        )
        embedURL.searchParams.append('view', 'trips')
        embedURL.searchParams.append(
          'trip_metadata_filter',
          `{"shiftId":"${workerShift.shiftId}","workerId":"${workerShift.workerId}"}`,
        )
        setEmbedURL(embedURL.href)
      } else {
        showError(
          `Worker Id: ${workerShift.workerId}`,
          'No trip found for worker',
        )
      }
    }

    const getWorkerShiftTimeToDestination = ({
      workerId,
      shiftId,
    }: {
      workerId: string
      shiftId: string
    }) => {
      return workerShiftTimeToDestinationsMap?.get(
        getWorkerShiftTimeToDestinationKey({ workerId, shiftId }),
      )
    }

    const robocallsByWorkerShift = new Map<string, RobocallCommunication[]>()
    for (const robocall of robocalls || []) {
      const key = getWorkerShiftStringId(
        robocall.workerId,
        robocall.relatedShiftId || '',
      )
      let workerShiftRobocalls = robocallsByWorkerShift.get(key)
      if (!workerShiftRobocalls) {
        workerShiftRobocalls = []
        robocallsByWorkerShift.set(key, workerShiftRobocalls)
      }
      workerShiftRobocalls.push(robocall)
    }

    const invitationsIdSet = new Set(
      shiftInvitations?.map((i) => i.worker.id) || [],
    )
    const workerScoresByWorkerId = indexByWorkerId(bulkWorkerScores)

    return sortedWorkerShifts.map((workerShift) => {
      const differenceBetweenArrivalAndStart = workerShift.workerSiteArrivalTime
        ? differenceInMinutes(
            new Date(workerShift.workerSiteArrivalTime),
            new Date(shift.startTime),
          )
        : 0
      const arrivalTimeString = `~${Math.abs(
        differenceBetweenArrivalAndStart,
      )} mins ${differenceBetweenArrivalAndStart > 0 ? 'after' : 'before'} start`

      const phoneNumber = formatPhoneNumber(
        workerShift?.worker?.phoneNumber || '',
        true,
      )

      const isWorkerPayRateAdjusted =
        workerShift.shiftInfo.payRate !== shift.payRate

      const key = getWorkerShiftStringId(
        workerShift.workerId,
        workerShift.shiftId,
      )
      const latestRobocall = robocallsByWorkerShift
        .get(key)
        ?.sort(sortByProp('createdAt', 'DESC'))[0]

      const totalBreakTime =
        getTotalBreaksTimeString({
          workerShift,
          displayHours: true,
          enableWorkerEditTime,
        }) || '-'

      const workerNotesCount = workersNotesCountMap?.[workerShift.workerId]
      const hasWorkerNotes = workerNotesCount && workerNotesCount > 0

      const workerScores = workerScoresByWorkerId?.[workerShift.workerId]

      const workerActionsCell = {
        renderFn: () => {
          return (
            <WorkerAction
              workerShift={workerShift}
              openClockInModal={openClockInModal}
              openClockOutModal={openClockOutModal}
              openAdjustmentModal={openAdjustmentModal}
            />
          )
        },
      }
      const workerNameCell = {
        sortKey: `${workerShift?.worker?.firstName} ${workerShift?.worker?.lastName}`,
        renderFn: () => (
          <Row justifyBetween>
            <Link to={`/workers/${workerShift.workerId}`} target="_blank">
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                <WorkerNameWithIcons
                  name={`${workerShift?.worker?.firstName} ${workerShift?.worker?.lastName}`}
                  maxLength={20}
                  isInvitedWorker={invitationsIdSet.has(workerShift.workerId)}
                  isFirstShiftWithCompany={
                    workerShift.isFirstTimeWorkerShiftWithCompany
                  }
                  isFavoriteWorker={favoriteWorkerIds?.has(
                    workerShift.workerId,
                  )}
                  isFirstShiftWithTraba={
                    workerScores?.numberOfCompletions === 0
                  }
                />
              </Button>
            </Link>
            <Row>
              <Row alignCenter>
                <Button
                  variant={ButtonVariant.OUTLINED}
                  style={{
                    padding: theme.space.xxs,
                    height: theme.space.sm,
                    marginLeft: theme.space.xxs,
                    borderColor: hasWorkerNotes
                      ? theme.colors.Yellow70
                      : undefined,
                    backgroundColor: hasWorkerNotes
                      ? theme.colors.Yellow10
                      : undefined,
                  }}
                  onClick={() => {
                    setWorkerToOpen(workerShift.workerId)
                    setWorkerNotesOpen(!workerNotesOpen)
                  }}
                >
                  <Row>
                    <Icon
                      type="svg"
                      name={'documentFolded'}
                      color={
                        hasWorkerNotes
                          ? theme.colors.Yellow70
                          : theme.colors.Grey50
                      }
                    />

                    <Text
                      variant="caption"
                      ml={theme.space.xxxs}
                      style={{
                        color: hasWorkerNotes
                          ? theme.colors.MidnightBlue
                          : theme.colors.Grey50,
                      }}
                    >
                      {isWorkersNotesLoading ? '' : workerNotesCount}
                    </Text>
                  </Row>
                </Button>
              </Row>
              {!slim && !!workerShift.tripId && (
                <Row
                  center
                  alignCenter
                  ml={theme.space.xxxs}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleEmbedUpdate(workerShift)}
                >
                  <Location
                    width={theme.space.xs}
                    height={theme.space.xs}
                    color={theme.colors.Green70}
                  />
                </Row>
              )}
              {!!isWorkerPayRateAdjusted && (
                <Tooltip
                  title={`Pay rate adjusted to $${workerShift.shiftInfo.payRate}`}
                >
                  <IconButton style={{ padding: 0 }}>
                    <Row center alignCenter ml={theme.space.xxxs}>
                      <Icon name="card" size={8} />
                    </Row>
                  </IconButton>
                </Tooltip>
              )}
            </Row>
          </Row>
        ),
      }
      const accountStatusCell = {
        sortKey: String(workerShift?.accountStatus),
        renderFn: () => {
          return workerShift?.accountStatus ? (
            <AccountStatusBadge
              accountStatus={workerShift?.accountStatus.accountStatus}
              approvalStatusReason={
                workerShift?.accountStatus.approvalStatusReason
              }
              accountSuspensionExpiration={
                workerShift?.accountStatus.suspensionExpiration
              }
            />
          ) : (
            '-'
          )
        },
      }
      const workerIdCell = {
        renderFn: () => {
          return (
            <Row style={{ width: 80 }} alignCenter>
              {truncateString(workerShift?.workerId ?? '')}
              <CopyTextIcon textToCopy={workerShift?.workerId} />
            </Row>
          )
        },
      }
      const phoneNumberCell = {
        renderFn: () => {
          return (
            <Row style={{ width: 120 }} alignCenter>
              <Anchor style={{ fontSize: 12 }} href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </Anchor>
              <CopyTextIcon textToCopy={phoneNumber} />
            </Row>
          )
        },
      }
      const jobStatusCell = {
        sortKey: String(workerShift.jobStatus),
        renderFn: () => {
          return (
            <WorkerTableJobStatusCell
              workerShift={workerShift}
              timezone={shift.timezone}
            />
          )
        },
      }
      const coordinatesCell = {
        renderFn: () => {
          return (
            <WorkerCoordinate
              workerZipCode={workerShift.worker.zipCode}
              shiftCoords={shift.location.coords}
            />
          )
        },
      }
      const confirmationCell = {
        renderFn: () => {
          return (
            <WorkerTableConfirmationIcon
              workerShift={workerShift}
              latestRobocall={latestRobocall}
            />
          )
        },
      }
      const workerETACell = {
        renderFn: () => {
          const timeToDestination = getWorkerShiftTimeToDestination({
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
          })
          return timeToDestination?.onTimeStatus || '-'
        },
      }
      const arrivedAtCell = {
        renderFn: () => {
          const timeToDestination = getWorkerShiftTimeToDestination({
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
          })
          return (
            <Row
              justifyCenter
              data-tooltip-id={`worker-arrival${workerShift.workerId}`}
            >
              {timeToDestination?.transitStatus || '-'}
              <ReactTooltip
                place="top"
                id={`worker-arrival${workerShift.workerId}`}
                style={{ zIndex: 2 }}
              >
                <p style={{ lineHeight: '24px' }}>
                  Arrived At:{' '}
                  {timeToDestination?.arrivedAt
                    ? tz.getTime(timeToDestination.arrivedAt)
                    : '-'}
                </p>
                <p style={{ lineHeight: '24px' }}>{arrivalTimeString}</p>
              </ReactTooltip>
            </Row>
          )
        },
      }
      const timeToDestinationCell = {
        renderFn: () => {
          const timeToDestination = getWorkerShiftTimeToDestination({
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
          })
          return timeToDestination
            ? tz.getTime(
                timeToDestination.arrivedAt
                  ? timeToDestination.arrivedAt
                  : addSeconds(
                      timeToDestination.recordedAt,
                      timeToDestination.timeToDestinationSeconds,
                    ),
              )
            : '-'
        },
      }
      const inlineClockInCell = {
        renderFn: () => (
          <InlineClockIn
            workerShift={workerShift}
            openModal={openClockInModal}
          />
        ),
      }
      const inLineClockOutCell = {
        renderFn: () => (
          <InlineClockOut
            workerShift={workerShift}
            openAdjustmentModal={openAdjustmentModal}
            openClockOutModal={openClockOutModal}
          />
        ),
      }
      const clockOutSourceCell = {
        // Clock out source
        renderFn: () => {
          const clockOutSourceText = workerShift.clockOutSource ?? '--'

          const clockOutContext = workerShift.clockOutContext

          return (
            <>
              {clockOutSourceText}{' '}
              {!!clockOutContext && (
                <Tooltip title={`Clock out context: ${clockOutContext}`}>
                  <IconButton style={{ padding: 0 }}>
                    <Row center alignCenter ml={theme.space.xxxs}>
                      <InfoIcon color="error" fontSize="small" />
                    </Row>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )
        },
      }
      const timeWorkedCell = {
        renderFn: (): string => {
          switch (shift.payType) {
            case ShiftPayType.HOURLY:
              return workerShift.timeWorked
                ? formatDuration(workerShift.timeWorked)
                : '-'
            case ShiftPayType.UNIT:
              return workerShift.unitsWorked
                ? `${workerShift.unitsWorked}`
                : '-'
            default:
              throw new Error('Invalid pay type')
          }
        },
      }
      const breaksTakenCell = {
        renderFn: () => {
          const breaks = getBreaksForWorkerShift(
            workerShift,
            enableWorkerEditTime,
          )
          return (
            <Row
              justifyCenter
              data-tooltip-id={`breaksTooltip-${workerShift.workerId}`}
            >
              {totalBreakTime}
              {breaks.length > 0 && (
                <ReactTooltip
                  place="top"
                  id={`breaksTooltip-${workerShift.workerId}`}
                  style={{ zIndex: 2 }}
                >
                  {breaks.map((b, i) => {
                    if (!b.startTime || !b.endTime) {
                      return null
                    }
                    return (
                      <p style={{ lineHeight: '24px' }}>
                        Break {i + 1} (
                        {differenceInMinutes(
                          new Date(b.endTime),
                          new Date(b.startTime),
                        )}{' '}
                        min) {tz.getShiftTime(b.startTime, b.endTime)}
                      </p>
                    )
                  })}
                </ReactTooltip>
              )}
            </Row>
          )
        },
      }
      const moneyEarnedCell = {
        renderFn: () => {
          return workerShift.netPay ? getMoneyString(workerShift.netPay) : '-'
        },
      }
      const earningsSummaryIconCell = {
        renderFn: () => {
          return (
            <EarningsSummaryPaymentIcon
              workerId={workerShift.workerId}
              shiftId={workerShift.shiftId}
              earningsSummary={workerShift.earningsSummary}
            />
          )
        },
      }
      const workerReliabilityScoreCell = {
        renderFn: () => {
          return <WorkerReliabilityScore workerId={workerShift.workerId} />
        },
      }
      const workerBusinessApprovalScoreCell = {
        renderFn: () => {
          return <WorkerBusinessApprovalScore workerId={workerShift.workerId} />
        },
      }
      const workerCompletionsCell = {
        renderFn: () => {
          return (
            <WorkerCompletions
              workerScores={workerScores}
              companyId={shift.companyId}
              roleId={shift.roleId}
            />
          )
        },
      }

      const cells: TableCell[] = [
        ...(allowTableSelect === false ? [workerActionsCell] : []),
        workerNameCell,
        accountStatusCell,
        workerIdCell,
        phoneNumberCell,
        jobStatusCell,
        coordinatesCell,
        confirmationCell,
        workerETACell,
        arrivedAtCell,
        timeToDestinationCell,
        inlineClockInCell,
        inLineClockOutCell,
        clockOutSourceCell,
        timeWorkedCell,
        breaksTakenCell,
        moneyEarnedCell,
        earningsSummaryIconCell,
        workerReliabilityScoreCell,
        workerBusinessApprovalScoreCell,
        workerCompletionsCell,
      ]
      const slimCells: TableCell[] = [
        workerActionsCell,
        workerNameCell,
        accountStatusCell,
        workerIdCell,
        phoneNumberCell,
        jobStatusCell,
        confirmationCell,
        inlineClockInCell,
        inLineClockOutCell,
        workerReliabilityScoreCell,
        workerBusinessApprovalScoreCell,
        workerCompletionsCell,
      ]
      return {
        key: workerShift.workerId,
        cells: slim ? slimCells : cells,
      }
    })
  }, [
    shiftInvitations,
    sortedWorkerShifts,
    clockInModal,
    clockOutModal,
    adjustmentModal,
    setEmbedURL,
    showError,
    workerShiftTimeToDestinationsMap,
    robocalls,
    shift,
    enableWorkerEditTime,
    workersNotesCountMap,
    favoriteWorkerIds,
    bulkWorkerScores,
    allowTableSelect,
    slim,
    isWorkersNotesLoading,
    workerNotesOpen,
    tz,
  ])

  const tableHeaders = useMemo(() => {
    return (slim ? slimHeaders : headers).map((header) =>
      typeof header === 'string' ? header : header.label?.toString() || '',
    )
  }, [slim, headers, slimHeaders])

  return (
    <Row flexCol fullWidth>
      <WorkerTableCommandBar
        shift={shift}
        workerShifts={workerShifts}
        selectedRows={selectedRows}
        statusCounts={statusCounts}
        statusOptions={statusOptions}
        statusFilters={statusFilters}
        setStatusFilters={setStatusFilters}
        handleOpenBulkAdjustModal={handleOpenBulkAdjustModal}
        handleOpenBulkCancelModal={handleOpenBulkCancelModal}
        handleOpenBulkCancelMultiShiftModal={
          handleOpenBulkCancelMultiShiftModal
        }
        handleOpenBulkRobocallModal={handleOpenBulkRobocallModal}
        setWorkerSearchString={setWorkerSearchString}
        workerSearchString={workerSearchString}
        allowTableSelect={allowTableSelect}
        setAllowTableSelect={setAllowTableSelect}
        slim={slim}
      />
      <VirtualizedTable
        headers={tableHeaders}
        rows={rows}
        allowSelect={allowTableSelect}
        selectedRows={selectedRows}
        onSelectRows={onSelectRows}
        slim={slim}
      />
      {workerToOpen && (
        <WorkerNoteDrawer
          workerId={workerToOpen}
          isOpen={workerNotesOpen}
          setIsOpen={setWorkerNotesOpen}
          hideFAB
          key={workerToOpen}
        />
      )}
      {currentWorkerShift && (
        <>
          <ClockInModal
            {...clockInModal}
            workerShift={currentWorkerShift}
            key={currentWorkerShift.workerId}
          />
          <ClockOutModal
            {...clockOutModal}
            workerShift={currentWorkerShift}
            key={currentWorkerShift.workerId}
          />
          <AdjustmentModal
            {...adjustmentModal}
            shift={shift}
            workerShift={currentWorkerShift}
            key={currentWorkerShift.workerId}
          />
        </>
      )}
    </Row>
  )
}
