import { useEligibilityConnections, useLocations } from '@traba/hooks'
import { IneligibleSelect } from '@traba/react-components'

import { EligibilityConnectionMutationPayload } from '@traba/types'
import { Row } from 'src/components/base'
import { useRoles } from 'src/hooks/useRoles'

type ConnectionProps = {
  workerId: string
  companyId: string
  setPayload: React.Dispatch<
    React.SetStateAction<EligibilityConnectionMutationPayload | undefined>
  >
}

export const EligibilityConnectionContent = (props: ConnectionProps) => {
  const { workerId, companyId, setPayload } = props

  const { eligibilityConnections, globalEligibilityConnection } =
    useEligibilityConnections(companyId, workerId)

  const { roles } = useRoles(companyId)
  const { locations } = useLocations(companyId)

  return (
    <Row style={{ justifyContent: 'center' }} flexCol>
      {eligibilityConnections &&
        globalEligibilityConnection !== undefined &&
        roles &&
        locations && (
          <IneligibleSelect
            eligibilityConnections={eligibilityConnections}
            globalEligibilityConnection={globalEligibilityConnection}
            roles={roles}
            locations={locations}
            workerId={workerId}
            companyId={companyId}
            setPayload={setPayload}
          />
        )}
    </Row>
  )
}
