import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Role, Schedule, ShiftRequestParent } from '@traba/types'
import { anyToDate } from '@traba/utils'
import { useMemo } from 'react'
import { Td, Tr } from '../base/Table/Table'
import { RegionCellContent } from '../CollapsibleShiftRow/components/RegionCell'

export function ShiftRequestParentRow({
  numCols,
  shiftRequestParent,
  employerName,
  regionId,
  rolesInShiftRequestParent,
  locationName,
  schedules,
}: {
  shiftRequestParent: ShiftRequestParent
  rolesInShiftRequestParent: Role[]
  employerName: string
  numCols: number
  regionId: string
  locationName: string | undefined
  schedules?: Schedule[]
}) {
  const name = `${employerName} - ${shiftRequestParent.title}`

  function getRangeString() {
    if (!schedules || schedules.length < 1) {
      return ``
    }
    if (!schedules[0].isRecurringSchedule) {
      return ``
    }
    const startTime = schedules[0].startTime
    const endTime = schedules[0].endTime
    return `${anyToDate(startTime).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })} - ${anyToDate(endTime).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })} ${schedules.length > 1 ? `Bi-` : ''}Weekly | ${schedules.length > 1 ? `A: ` : ``} ${schedules[0].recurringSchedule?.repeatOn}${schedules.length > 1 ? `B: ${schedules[1].recurringSchedule?.repeatOn}` : ``}`
  }
  const rolesString = useMemo(
    () =>
      Array.from(
        new Set(rolesInShiftRequestParent.map((role) => role.roleName)),
      ).join(', '),
    [rolesInShiftRequestParent],
  )
  return (
    <Tr
      style={{
        backgroundColor: theme.colors.Grey10,
      }}
    >
      <Td
        style={{
          borderTop: `10px solid ${theme.colors.Grey20}`,
          borderLeft: `10px solid ${theme.colors.Grey20}`,
          borderTopLeftRadius: theme.space.xxs,
        }}
      />
      <Td
        style={{
          borderTop: `10px solid ${theme.colors.Grey20}`,
        }}
      >
        <Text variant="h7">{name}</Text>
      </Td>
      <Td
        style={{
          borderTop: `10px solid ${theme.colors.Grey20}`,
        }}
      >
        <RegionCellContent regionId={regionId} locationName={locationName} />
      </Td>
      <Td
        style={{
          borderTop: `10px solid ${theme.colors.Grey20}`,
        }}
      >
        <Text style={{ textOverflow: 'ellipsis' }} variant="h7">
          {rolesString}
        </Text>
      </Td>
      <Td
        style={{
          borderTop: `10px solid ${theme.colors.Grey20}`,
          borderRight: `10px solid ${theme.colors.Grey20}`,
          borderTopRightRadius: theme.space.xxs,
        }}
        colSpan={numCols - 3}
      >
        <Text style={{ textAlign: 'end' }} variant="h7">
          {getRangeString()}
        </Text>
      </Td>
    </Tr>
  )
}
