import { SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Locations } from '@traba/types'
import { SetStateAction, useMemo } from 'react'
import { Row } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'

import { getAddressString } from 'src/utils/stringUtils'

import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from '../ShiftPostingInputContainer'

interface Props {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void

  locations?: Locations[]
  timezone: string
}

export const LocationSection = ({
  createShiftRequests,
  locations,
  setCreateShiftRequests,
  timezone,
}: Props) => {
  const locationOptions: IMenuItem[] = useMemo(() => {
    return (
      locations?.map((location) => {
        return {
          label: getAddressString(location.address),
          secondaryLabel: location.name,
          value: location.locationId,
        }
      }) || []
    )
  }, [locations])

  const selectedLocationMenuItem = useMemo(
    () =>
      locationOptions.find(
        (loc) => loc.value === createShiftRequests[0].locationId,
      ),
    [createShiftRequests[0].locationId, locationOptions],
  )

  const selectedParkingLocationMenuItem = useMemo(
    () =>
      locationOptions.find(
        (loc) => loc.value === createShiftRequests[0].parkingLocationId,
      ),
    [createShiftRequests[0].parkingLocationId, locationOptions],
  )

  return (
    <ShiftPostingInputContainer title="Location details">
      <Row
        fullWidth
        style={{ justifyContent: 'space-around' }}
        mb={theme.space.sm}
        wrap
      >
        <ShiftPostingInputContainerSection
          label="Location"
          input={
            <SearchSelect
              options={locationOptions}
              selectItem={selectedLocationMenuItem}
              handleSelect={(item) => {
                setCreateShiftRequests((prev) => {
                  return prev.map((shiftReq) => {
                    return {
                      ...shiftReq,
                      locationId: item?.value || '',
                      schedules: [
                        {
                          ...shiftReq.schedules[0],
                          timeZone: timezone,
                        },
                      ],
                    }
                  })
                })
              }}
              style={{ minWidth: '40%' }}
              shouldAlsoSearchSecondaryLabel={true}
            />
          }
        />
        <ShiftPostingInputContainerSection
          label="Parking Location (optional)"
          input={
            <SearchSelect
              options={locationOptions}
              selectItem={selectedParkingLocationMenuItem}
              handleSelect={(item) => {
                setCreateShiftRequests((prev) => {
                  return prev.map((shiftReq) => {
                    return {
                      ...shiftReq,
                      parkingLocationId: item?.value || '',
                    }
                  })
                })
              }}
              style={{ minWidth: '40%' }}
              shouldAlsoSearchSecondaryLabel={true}
              showClearButton
            />
          }
        />
      </Row>
    </ShiftPostingInputContainer>
  )
}
