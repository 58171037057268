import {
  MultiDatePicker,
  Text,
  Col,
  InfoTooltip,
  SvgIcon,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  combineTwoDatesForDateAndTime,
  getFirstShiftDateError,
  getNextStartAndEndTime,
} from '@traba/utils'
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns'
import { debounce, isUndefined } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Row } from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import TimeField from 'src/components/base/AriaDatePicker/TimeField'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import { useEarlyArrivalBuffer } from 'src/hooks/useEarlyArrivalBuffer'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import { ShiftPostingInputContainerSection } from '../ShiftPostingInputContainer'

interface ShiftTimePickerProps {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: React.Dispatch<
    React.SetStateAction<CreateShiftRequest[]>
  >
  businessStartTime: Date | null
  setBusinessStartTime: React.Dispatch<React.SetStateAction<Date | null>>
  timezone: string
  isInvalidBuffer: boolean
  actualStartTime: Date | null
  isDuplicateShift?: boolean
  setIsInvalidBuffer: React.Dispatch<React.SetStateAction<boolean>>
  selectedSingleShiftDates: Date[] | null
  setSelectedSingleShiftDates: Dispatch<SetStateAction<Date[] | null>>
}

export const ShiftTimePickerWithSchedules = ({
  createShiftRequests,
  setCreateShiftRequests,
  businessStartTime,
  setBusinessStartTime,
  timezone,
  actualStartTime,
  isDuplicateShift,
  setIsInvalidBuffer,
  selectedSingleShiftDates,
  setSelectedSingleShiftDates,
}: ShiftTimePickerProps) => {
  const initialBufferValue = isDuplicateShift
    ? (getEarlyArrivalTimeBufferInMinutes({
        shiftStartTime: actualStartTime,
        businessStartTime: businessStartTime,
      }) ?? undefined)
    : undefined
  const [buffer, setBuffer] = useState<number | undefined>(initialBufferValue) // Initialize buffer state
  const { getEarlyArrivalBufferUpdates } = useEarlyArrivalBuffer()

  // Debounce function for buffer updates
  const handleBufferUpdate = debounce(async (newBuffer: number | undefined) => {
    if (isUndefined(newBuffer)) {
      return
    }
    try {
      const {
        startTime: newStartTime,
        businessStartTime: newBusinessStartTime,
      } = await getEarlyArrivalBufferUpdates(
        newBuffer,
        createShiftRequests[0].schedules[0].startTime,
        businessStartTime,
      )
      setIsInvalidBuffer(false)
      handleStartTimeChange(newStartTime)
      setBusinessStartTime(
        newBusinessStartTime ? new Date(newBusinessStartTime) : null,
      )
    } catch (error) {
      setIsInvalidBuffer(!!businessStartTime)
    }
  }, 200)

  const handleStartTimeChange = (newDate: Date | null) => {
    if (newDate) {
      let newEndTime = new Date(createShiftRequests[0].schedules[0].endTime)

      if (isBefore(createShiftRequests[0].schedules[0].endTime, newDate)) {
        const shiftLength = differenceInMinutes(
          createShiftRequests[0].schedules[0].endTime,
          createShiftRequests[0].schedules[0].startTime,
        )
        newEndTime = addMinutes(newDate, shiftLength)
      }
      if (businessStartTime) {
        const bufferDiff = differenceInMinutes(businessStartTime, newDate)
        setBuffer(bufferDiff)
      }
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          if (sr.schedules.length === 1) {
            return {
              ...sr,
              schedules: [
                {
                  ...sr.schedules[0],
                  startTime: new Date(newDate),
                  endTime: newEndTime,
                },
              ],
            }
          }
          const newScheduleA = {
            ...sr.schedules[0],
            startTime: new Date(newDate),
            endTime: newEndTime,
          }
          const newScheduleB = {
            ...sr.schedules[1],
            ...getNextStartAndEndTime(newScheduleA, sr.schedules[1]),
          }
          return {
            ...sr,
            schedules: [newScheduleA, newScheduleB],
          }
        })
      })
    }
  }

  const handleEndTimeChange = (newDate: Date | null) => {
    if (newDate) {
      setCreateShiftRequests((prev) => {
        return prev.map((sr) => {
          if (sr.schedules.length === 1) {
            return {
              ...sr,
              schedules: [
                {
                  ...sr.schedules[0],
                  endTime: newDate,
                },
              ],
            }
          }
          const newScheduleA = {
            ...sr.schedules[0],
            endTime: newDate,
          }

          const newScheduleB = {
            ...sr.schedules[1],
            ...getNextStartAndEndTime(newScheduleA, sr.schedules[1]),
          }
          return {
            ...sr,
            schedules: [newScheduleA, newScheduleB],
          }
        })
      })

      if (
        createShiftRequests[0].schedules[0].recurringSchedule?.endDate &&
        isBefore(
          createShiftRequests[0].schedules[0].recurringSchedule.endDate,
          newDate,
        )
      ) {
        setCreateShiftRequests((prev) => {
          return prev.map((sr) => ({
            ...sr,
            schedule: {
              ...sr.schedules[0],
              recurringSchedule: {
                repeatOn: sr.schedules[0].recurringSchedule?.repeatOn || [],
                freq: 'WEEKLY',
                interval: 1,
                endDate: newDate,
              },
            },
            schedules: [
              {
                ...sr.schedules[0],
                recurringSchedule: {
                  repeatOn: sr.schedules[0].recurringSchedule?.repeatOn || [],
                  freq: 'WEEKLY',
                  interval: 1,
                  endDate: newDate,
                },
              },
            ],
          }))
        })
      }
    }
  }

  const handleBusinessStartTimeChange = (newDate: Date | null) => {
    // Business start time change logic
    if (!newDate) {
      return
    }
    setBusinessStartTime(newDate)
    setBuffer(
      getEarlyArrivalTimeBufferInMinutes({
        shiftStartTime: createShiftRequests[0].schedules[0].startTime,
        businessStartTime: newDate,
      }) ?? 0,
    )
  }

  const handleBufferChange = (newBuffer: number) => {
    // Buffer change logic
    setBuffer(newBuffer)
    handleBufferUpdate(newBuffer)
  }

  useEffect(() => {
    if (isDuplicateShift) {
      // pre-processing for duplicate shifts
      initialBufferValue && handleBufferChange(initialBufferValue) // initialize with buffer value if it exists
      const newBusinessStartTime = addMinutes(
        createShiftRequests[0].schedules[0].startTime,
        initialBufferValue ?? 0,
      )
      setBusinessStartTime(newBusinessStartTime) // initialize with business start time value
    }
  }, [isDuplicateShift])

  //cannot set a buffer for a shift that starts in less than 2 hours
  const twoHoursFromNow = new Date()
  twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2)

  const fieldDisabled = isBefore(
    createShiftRequests[0].schedules[0].startTime,
    twoHoursFromNow,
  )
  const firstShiftDateError = getFirstShiftDateError(
    createShiftRequests[0],
  )?.message

  return (
    <>
      {selectedSingleShiftDates && (
        <>
          <Row alignCenter gap={theme.space.xxxs}>
            <SvgIcon name="calendar" color={theme.colors.Violet} />
            <Text variant="h6">
              Which dates? ({selectedSingleShiftDates?.length} selected)
            </Text>
          </Row>
          <MultiDatePicker
            selectedDates={
              selectedSingleShiftDates ?? [
                createShiftRequests[0].schedules[0].startTime,
              ]
            }
            onSelectDates={(dates: Date[]) => {
              setSelectedSingleShiftDates(dates)
              setBusinessStartTime(null)
              setBuffer(undefined)
              setCreateShiftRequests((prev) => {
                const newStartTime = combineTwoDatesForDateAndTime(
                  dates[0],
                  prev[0].schedules[0].startTime,
                )
                const newEndTime = combineTwoDatesForDateAndTime(
                  dates[0],
                  prev[0].schedules[0].endTime,
                )
                return prev.map((sr) => ({
                  ...sr,
                  schedules: [
                    {
                      ...sr.schedules[0],
                      startTime: newStartTime,
                      endTime: newEndTime,
                    },
                  ],
                }))
              })
            }}
          />
        </>
      )}
      <Row justifyBetween my={theme.space.sm} wrap>
        {/* Start Time Input Section */}
        <ShiftPostingInputContainerSection
          label={
            createShiftRequests[0].schedules[0].isRecurringSchedule
              ? 'First shift start time'
              : 'Start Time'
          }
          input={
            <Col>
              {selectedSingleShiftDates ? (
                <TimeField
                  time={
                    selectedSingleShiftDates.length > 0
                      ? combineTwoDatesForDateAndTime(
                          selectedSingleShiftDates[0],
                          createShiftRequests[0].schedules[0].startTime,
                        )
                      : createShiftRequests[0].schedules[0].startTime
                  }
                  setTime={handleStartTimeChange}
                  timezone={timezone}
                />
              ) : (
                <DatePicker
                  date={createShiftRequests[0].schedules[0].startTime}
                  showTimeFieldInPopover={true}
                  setDate={handleStartTimeChange}
                  isClearable={false}
                  timezone={timezone}
                  aria-label="Start Time"
                  isDisabled={isDuplicateShift ? false : !!businessStartTime}
                  minDate={new Date()}
                />
              )}

              <Text variant="error">{firstShiftDateError}</Text>
            </Col>
          }
        />
        {/* Business Start Time Input Section */}
        {businessStartTime !== createShiftRequests[0].schedules[0].startTime &&
          buffer !== 0 &&
          businessStartTime && (
            <>
              <InfoTooltip
                title={
                  'The business start time is what is shown to the business on the biz app. They do not see buffers or modified shift times.'
                }
              />
              <ShiftPostingInputContainerSection
                label="Business Start Time"
                input={
                  <DatePicker
                    date={businessStartTime}
                    showTimeFieldInPopover={true}
                    setDate={handleBusinessStartTimeChange}
                    isClearable={false}
                    timezone={timezone}
                    aria-label="Start Time"
                  />
                }
              />
            </>
          )}
        {/* Buffer Input Section */}
        <InfoTooltip
          title={
            'The early arrival buffer can only be <= 2 hours. You can only edit this value for start times <= 2 hours from now. Note that this amount of time is a cost to Traba (cost = buffer/60 * hourlyRate * workers)!'
          }
        />
        <ShiftPostingInputContainerSection
          label="Early Arrival Buffer"
          input={
            <Row alignCenter>
              <NumberInput
                value={buffer}
                setValue={(value) => {
                  if (value) {
                    handleBufferChange(value)
                  } else {
                    handleBufferChange(0)
                  }
                }}
                min={0}
                max={120}
                step={1}
                placeholder={'e.g. 15'}
                onError={() => setIsInvalidBuffer(true)}
                disabled={fieldDisabled}
              />
            </Row>
          }
        />
        {/* End Time Input Section */}
        <ShiftPostingInputContainerSection
          label="End Time"
          input={
            selectedSingleShiftDates ? (
              <TimeField
                time={
                  selectedSingleShiftDates.length > 0
                    ? combineTwoDatesForDateAndTime(
                        selectedSingleShiftDates[0],
                        createShiftRequests[0].schedules[0].endTime,
                      )
                    : createShiftRequests[0].schedules[0].endTime
                }
                setTime={handleEndTimeChange}
                timezone={timezone}
              />
            ) : (
              <DatePicker
                date={createShiftRequests[0].schedules[0].endTime}
                showTimeFieldInPopover={true}
                setDate={handleEndTimeChange}
                isClearable={false}
                timezone={timezone}
                aria-label="End Time"
              />
            )
          }
        />
      </Row>
    </>
  )
}
