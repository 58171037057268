import { Badge } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AttributeLevelType,
  OpsAttributeLevel,
  RoleAttributeCategory,
} from '@traba/types'
import { useCallback, useState } from 'react'
import { BadgeVariant } from 'shared/react-components/src/lib/base-components/Badge/Badge.styles'
import {
  getAttributeBadgeLevel,
  isAttributeLevelRevoked,
  isAttributeLevelUnreported,
  showCheckMarkForAttributeBadge,
} from 'src/utils/attributeUtils'
import { AttributeBadgeMenu } from './AttributeBadgeMenu'

interface AttributePillProps {
  attributeName: string
  attributeType: string
  category: RoleAttributeCategory
  attributeLevel: OpsAttributeLevel
  levelType: AttributeLevelType
  handleSelectAttributeLevel?: (
    attrType: string,
    attrCategory: RoleAttributeCategory,
    level: OpsAttributeLevel,
  ) => void
  hideRevoked?: boolean
  readOnly?: boolean
  fontSize?: number
  iconSize?: number
  sm?: boolean
}

export function AttributeBadge(props: AttributePillProps) {
  const {
    attributeName,
    attributeType,
    attributeLevel,
    levelType,
    category,
    handleSelectAttributeLevel,
    hideRevoked,
    readOnly,
    fontSize,
    iconSize,
    sm,
  } = props

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleUpdateOpsLevel = useCallback(
    (opsLevel: OpsAttributeLevel) => {
      handleSelectAttributeLevel?.(attributeType, category, opsLevel)
    },
    [attributeType, category, handleSelectAttributeLevel],
  )

  const isRevoked = isAttributeLevelRevoked(attributeLevel)
  const isVerified = showCheckMarkForAttributeBadge(attributeLevel)
  const isUnreported = isAttributeLevelUnreported(attributeLevel)
  const attributeBadgeLevel = getAttributeBadgeLevel(attributeLevel)

  const badgeVariant: BadgeVariant = isUnreported
    ? 'clear'
    : isRevoked
      ? 'darkRed'
      : 'info'

  const hoverBorderColor = isUnreported
    ? theme.colors.Grey90
    : isRevoked
      ? theme.colors.Red80
      : theme.colors.Blue50

  const hoverTextColor = isUnreported ? theme.colors.Grey90 : undefined

  const iconName = isRevoked ? 'block' : isVerified ? 'check' : undefined
  const iconColor = isRevoked
    ? theme.colors.Red80
    : isVerified
      ? theme.colors.Blue80
      : undefined

  return (
    <>
      <Badge
        title={`${attributeName} ${attributeBadgeLevel}`}
        iconName={iconName}
        iconProps={{
          size: iconSize ?? 14,
          color: iconColor,
        }}
        variant={badgeVariant}
        style={{
          borderRadius: 6,
          textTransform: 'capitalize',
          fontSize: fontSize ?? 12,
          cursor: !readOnly ? 'pointer' : undefined,
          color: isRevoked ? theme.colors.Red80 : undefined,
        }}
        hoverBorderColor={!readOnly ? hoverBorderColor : undefined}
        hoverTextColor={!readOnly ? hoverTextColor : undefined}
        onClick={!readOnly ? handleClick : undefined}
        sm={sm}
      />
      <AttributeBadgeMenu
        menuAnchorEl={anchorEl}
        attributeLevel={attributeLevel}
        attributeType={attributeType}
        levelType={levelType}
        handleClose={handleClose}
        handleClick={handleUpdateOpsLevel}
        hideRevoke={hideRevoked}
      />
    </>
  )
}
