import { useAlert } from '@traba/context'
import { Text, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ExtendedShift } from '@traba/types'
import { TrainingVideo } from '@traba/types'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { Button, Col, Modal, Row, Table } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { getErrorMessage } from 'src/utils/errorUtils'
import { ShiftTrainingVideosTableRow } from './ShiftTrainingVideosTableRow'

type ShiftTrainingVideosModalProps = {
  handleClose: () => void
  isOpen: boolean
  videos?: TrainingVideo[]
  shift: ExtendedShift
}

export default function ShiftTrainingVideosModal(
  props: ShiftTrainingVideosModalProps,
) {
  const { handleClose, isOpen, shift, videos } = props
  const { trainingVideos: companyVideos } = useTrainingVideos(shift.companyId)
  const companyVideosOptions: IMenuItem[] =
    companyVideos
      ?.filter((cv) => videos?.findIndex((v) => v.id === cv.id) === -1)
      .map((cv) => {
        return { label: cv.name, value: cv.id }
      }) ?? []
  const [videosToAdd, setVideosToAdd] = useState<IMenuItem[]>([])
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDeleteVideoFromShift = async (videoId: string) => {
    setIsLoading(true)
    const newVideoIds = videos?.filter((v) => v.id !== videoId).map((v) => v.id)
    try {
      await trabaApi.patch(
        `/shifts?shiftIds=${shift.id}&shouldNotifyUpcomingOrInProgressShifts=false&shouldNotifyCompletedShifts=false`,
        {
          shiftUpdates: {
            videoIds: newVideoIds,
          },
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error removing training video')
    }
    await refetchActiveQueries()
    setIsLoading(false)
  }

  const handleAddVideoToShift = async () => {
    setIsLoading(true)
    const newVideoIds = videos
      ?.map((v) => v.id)
      .concat(videosToAdd.map((v) => v.value))
    try {
      await trabaApi.patch(
        `/shifts?shiftIds=${shift.id}&shouldNotifyUpcomingOrInProgressShifts=false&shouldNotifyCompletedShifts=false`,
        {
          shiftUpdates: {
            videoIds: newVideoIds,
          },
        },
      )
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error adding training video')
    }
    setVideosToAdd([])
    await refetchActiveQueries()
    setIsLoading(false)
  }

  const tableHeaders = [
    { label: 'Name', key: 'name' },
    { label: 'Video Id', key: 'videoId' },
    { label: 'Video', key: 'videoLink' },
    { label: '' },
  ]

  return (
    <Modal
      size={MODAL_SIZE.MEDIUM}
      handleClose={handleClose}
      isOpen={isOpen}
      style={{ minHeight: `calc(100% - 500px)` }}
      title={'Shift Training Videos'}
    >
      <Row mb={theme.space.sm} fullWidth>
        <Text variant="caption">
          Note: All videos below are required to be watched before signing up
          for this shift.
        </Text>
      </Row>
      <Row mb={theme.space.sm} alignCenter justifyBetween>
        <Col style={{ maxWidth: '500px' }}>
          <SearchSelect
            multiple
            onlyShowLabel
            options={companyVideosOptions}
            selectedItems={videosToAdd}
            handleSelectMultiple={setVideosToAdd}
            label="Add "
          />
        </Col>
        <Button
          disabled={isLoading}
          onClick={() => {
            handleAddVideoToShift()
          }}
        >
          Add
        </Button>
      </Row>

      <Table headers={tableHeaders}>
        {videos?.map((video) => (
          <ShiftTrainingVideosTableRow
            isLoading={isLoading}
            onDelete={handleDeleteVideoFromShift}
            video={video}
          />
        ))}
      </Table>
    </Modal>
  )
}
