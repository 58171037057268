import { CircularProgress, Switch } from '@mui/material'
import { ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE } from '@traba/consts'
import { FileType } from '@traba/hooks'
import { Col, InputInfoCalloutRow, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BGCRequirement,
  COMPANY_WIDE_ID,
  InputStatus,
  RequiredAttributeLevel,
} from '@traba/types'
import { GenderPreference } from '@traba/types'
import { Company } from '@traba/types'
import { ShiftAttribute } from '@traba/types'
import { WorkerCertificationType } from '@traba/types'
import { RoleAttribute, RoleAttributeStatus } from '@traba/types'
import { isRoleNameDupeInSameLocation } from '@traba/utils'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Input, Modal, Row } from 'src/components/base'
import { useAttributes } from 'src/hooks/useAttributes'
import { useCertifications } from 'src/hooks/useCertifications'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { CreateRoleData, EditRoleData, useRoles } from 'src/hooks/useRoles'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { getAttributeAllowOpsLeveling } from 'src/utils/attributeUtils'
import {
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  PAY_RATE_DEFAULT,
  getMinHourlyPayRate,
} from 'src/utils/moneyUtils'
import * as yup from 'yup'
import { ButtonVariant } from '../base/Button/types'
import Checkbox from '../base/Checkbox'
import { NumberInput } from '../base/Input/NumberInput'
import SelectableCard from '../base/SelectableCard/SelectableCard'
import { BGCRequirementSelector } from '../BGCRequirementSelector/BGCRequirementSelector'
import { DefaultPayRateComponent } from '../DefaultPayRate'
import { InlineMessage } from '../InlineMessage'
import { RequiredAttributeLevelsSections } from '../RequiredAttributeLevelsSections/RequiredAttributeLevelsSections'
import { UploadVideoModal } from '../UploadVideo/UploadVideoModal'
import { GenderPreferenceSelector } from './GenderPreferenceSelector'
import { LocationSingleSearchSelector } from './LocationSingleSearchSelector'

interface RoleFormProps {
  onCancel: () => void
  onConfirmEdit?: (role: EditRoleData) => void
  onConfirmCreate?: (role: CreateRoleData) => void
  isEdit: boolean
  company: Company
  initialRoleState?: Partial<CreateRoleData>
  submitLoading: boolean
  currentRoleId?: string
}

export const RoleForm: React.FC<RoleFormProps> = (props) => {
  const {
    onCancel,
    onConfirmEdit,
    onConfirmCreate,
    isEdit,
    company,
    initialRoleState,
    submitLoading,
    currentRoleId,
  } = props

  const { attributes: roleAttributes } = useAttributes(
    RoleAttributeStatus.Active,
  )
  const { attributes: allAttributes } = useAttributes()
  const { certifications } = useCertifications()
  const { trainingVideos, requiredForAllTrainingVideos } = useTrainingVideos(
    company.companyId,
  )
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()

  const [requiredAttributes, setRequiredAttributes] = useState<
    ShiftAttribute[]
  >(initialRoleState?.requiredAttributes ?? [])
  const [requiredAttributeLevels, setRequiredAttributeLevels] = useState<
    RequiredAttributeLevel[]
  >(initialRoleState?.requiredAttributeLevels ?? [])
  const [requiredCertifications, setRequiredCertifications] = useState<
    WorkerCertificationType[]
  >(initialRoleState?.requiredCertifications ?? [])
  const [extraBGCRequirement, setExtraBGCRequirement] =
    useState<BGCRequirement>(
      initialRoleState?.extraBGCRequirement ?? company.extraBGCRequirement,
    )
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(initialRoleState?.genderPreference)
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])
  const [trainingVideosModalIsOpen, setTrainingVideosModalIsOpen] =
    useState(false)
  const { roles } = useRoles(company.companyId)
  const [roleNameError, setRoleNameError] = useState<string | undefined>(
    undefined,
  )

  const minPayRate = getMinHourlyPayRate({
    companyMinHourlyPay: company.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })

  const preSelectedVideoIds = useMemo(() => {
    return (
      trainingVideos?.filter((tv) => tv.requiredForAll).map((tv) => tv.id) || []
    )
  }, [trainingVideos])

  const mergedVideoIds = useMemo(() => {
    return Array.from(
      new Set([...preSelectedVideoIds, ...(initialRoleState?.videoIds ?? [])]),
    )
  }, [preSelectedVideoIds, initialRoleState?.videoIds])

  useEffect(() => {
    setSelectedVideoIds(mergedVideoIds)
  }, [mergedVideoIds])

  useEffect(() => {
    const preSelectedVideoIds =
      trainingVideos?.filter((tv) => tv.requiredForAll).map((tv) => tv.id) || []
    setSelectedVideoIds(
      Array.from(
        new Set([
          ...preSelectedVideoIds,
          ...(initialRoleState?.videoIds ?? []),
        ]),
      ),
    )
  }, [trainingVideos, initialRoleState?.videoIds])

  function onUploadSuccess(videoId: string) {
    setSelectedVideoIds([...selectedVideoIds, videoId])
  }

  function onClickCertification(
    key: WorkerCertificationType,
    isCurrentlySelected: boolean,
  ) {
    const existingCertifications: WorkerCertificationType[] =
      requiredCertifications
    const updatedCertifications = isCurrentlySelected
      ? existingCertifications.filter((c) => c !== key)
      : [...existingCertifications, key]
    setRequiredCertifications(updatedCertifications)
  }

  function onClickAttribute(attribute: RoleAttribute, selected: boolean) {
    const { type, category } = attribute
    const existingAttributes: ShiftAttribute[] = requiredAttributes
    const updatedAttributes = !selected
      ? [...existingAttributes, { type, category }]
      : existingAttributes.filter((a) => a.type !== type)
    setRequiredAttributes(updatedAttributes)
  }

  const formik = useFormik({
    initialValues: {
      roleName: initialRoleState?.roleName ?? '',
      requiredAttire: initialRoleState?.requiredAttire ?? '',
      roleDescription: initialRoleState?.roleDescription ?? '',
      resumeUploadRequired: initialRoleState?.resumeUploadRequired,
      defaultPayRate: initialRoleState?.defaultPayRate ?? PAY_RATE_DEFAULT,
      minimumAgeRequirement: initialRoleState?.minimumAgeRequirement,
      locationId: initialRoleState?.locationId,
    },
    validationSchema: yup.object({
      roleName: yup.string().required(REQUIRED_FIELD_MESSAGE),
      requiredAttire: yup.string().required(REQUIRED_FIELD_MESSAGE),
      roleDescription: yup.string().required(REQUIRED_FIELD_MESSAGE),
      ...(hotSettings?.enableRegionalAccessPhase1
        ? {
            locationId: yup.string().required(REQUIRED_FIELD_MESSAGE),
          }
        : undefined),
      defaultPayRate: yup
        .number()
        .required(REQUIRED_FIELD_MESSAGE)
        .min(minPayRate),
      minimumAgeRequirement: yup.number(),
      resumeUploadRequired: yup.boolean(),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        onConfirmEdit &&
          onConfirmEdit({
            ...values,
            extraBGCRequirement,
            genderPreference:
              genderPreference === undefined ? null : genderPreference,
            requiredAttributes: requiredAttributes.filter(
              (attr) =>
                !hotSettings?.allowStoringRequiredAttributeLevel ||
                !getAttributeAllowOpsLeveling(attr.type, allAttributes),
            ),
            requiredAttributeLevels,
            requiredCertifications,
            videoIds: selectedVideoIds,
            locationId:
              values.locationId === COMPANY_WIDE_ID
                ? undefined
                : values.locationId,
          })
        return
      }
      onConfirmCreate &&
        onConfirmCreate({
          ...values,
          genderPreference,
          extraBGCRequirement,
          requiredAttributes,
          requiredAttributeLevels,
          requiredCertifications,
          videoIds: selectedVideoIds,
          locationId:
            values.locationId === COMPANY_WIDE_ID
              ? undefined
              : values.locationId,
        })
    },
  })
  const { values, errors, touched } = formik

  const onBlurRoleName = useCallback(() => {
    const isNameDuplicatedInLocation = isRoleNameDupeInSameLocation({
      roles,
      newRoleName: values.roleName,
      newRoleLocationId: values.locationId,
      currentRoleId,
    })
    setRoleNameError(
      isNameDuplicatedInLocation && values.locationId
        ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
        : undefined,
    )
  }, [roles, values.roleName, values.locationId])

  const onChangeRoleLocation = useCallback(
    (locationId: string | undefined) => {
      formik.setFieldValue('locationId', locationId)

      const isNameDuplicatedInLocation = isRoleNameDupeInSameLocation({
        roles,
        newRoleName: values.roleName,
        newRoleLocationId: locationId,
        currentRoleId,
      })
      setRoleNameError(
        isNameDuplicatedInLocation && locationId
          ? ROLE_NAME_IS_NOT_UNIQUE_IN_LOCATION_ERROR_MESSAGE
          : undefined,
      )
    },
    [roles, values.roleName],
  )

  if (isLoadingHotSettings) {
    return (
      <Row center>
        <CircularProgress size={theme.space.xxxl} />
      </Row>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text variant="h5">Role details</Text>
      <Input
        label="Role name"
        name="roleName"
        width="100%"
        value={values.roleName}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={
          (touched.roleName && errors.roleName) || roleNameError
            ? InputStatus.error
            : InputStatus.default
        }
        errorMessage={errors.roleName || roleNameError}
        onBlur={onBlurRoleName}
      />
      {hotSettings?.enableRegionalAccessPhase1 && (
        <Col mt={theme.space.xs} gap={theme.space.xxs}>
          <LocationSingleSearchSelector
            companyId={company.companyId}
            onChange={onChangeRoleLocation}
            selectedLocationId={values.locationId}
            errorMessage={touched.locationId ? errors.locationId : undefined}
          />

          {props.isEdit && (
            <InputInfoCalloutRow text="Careful! Make sure this role is not being used in future shifts at different locations!" />
          )}
        </Col>
      )}
      <Input
        label="Required attire"
        name="requiredAttire"
        width="100%"
        value={values.requiredAttire}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={touched.requiredAttire && errors.requiredAttire ? 3 : 1}
        errorMessage={errors.requiredAttire}
      />
      <Input
        label="Role description"
        name="roleDescription"
        rows={4}
        type="textarea"
        defaultValue=""
        width="100%"
        value={values.roleDescription}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={touched.roleDescription && errors.roleDescription ? 3 : 1}
        errorMessage={errors.roleDescription}
      />
      <InlineMessage text="Share all the important details so workers are prepared when they arrive." />
      <DefaultPayRateComponent
        defaultPayRate={values.defaultPayRate}
        setDefaultPayRate={(defaultPayRate) =>
          formik.setFieldValue('defaultPayRate', defaultPayRate)
        }
        minHourlyPayRate={minPayRate}
      />
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
      >
        What should be the minimum worker age for this shift?
      </Text>
      <Text variant="body2" style={{ marginTop: theme.space.xxs }}>
        Workers will have to be at least this age to join the shift.
      </Text>
      <NumberInput
        containerStyle={{ marginTop: theme.space.xxs }}
        setValue={(v) => {
          formik.setFieldValue('minimumAgeRequirement', v)
        }}
        decimals={0}
        value={values.minimumAgeRequirement}
        width={'200'}
        label="Min Age"
      />
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xxs }}
      >
        Should Workers need to upload a resume?
      </Text>
      <Switch
        inputProps={{ 'aria-label': 'controlled' }}
        checked={values.resumeUploadRequired}
        onClick={() =>
          formik.setFieldValue(
            'resumeUploadRequired',
            !values.resumeUploadRequired,
          )
        }
      />

      {!hotSettings?.allowStoringRequiredAttributeLevel ? (
        <>
          <Text
            variant="h5"
            style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
          >
            Requirements ({requiredAttributes.length})
          </Text>
          {!!roleAttributes && (
            <Row wrap style={{ gap: theme.space.xxs }}>
              {roleAttributes.map((attribute) => {
                const selected = !!requiredAttributes
                  ?.map((attribute: ShiftAttribute) => attribute.type)
                  .includes(attribute.type)
                return (
                  <SelectableCard
                    key={attribute.type}
                    label={_.startCase(attribute?.displayName)}
                    onClick={() => onClickAttribute(attribute, selected)}
                    selected={selected}
                  />
                )
              })}
            </Row>
          )}
        </>
      ) : (
        <>
          <Text
            variant="h5"
            style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
          >
            Required Attribute Levels ({requiredAttributeLevels.length})
          </Text>
          <RequiredAttributeLevelsSections
            attributes={allAttributes}
            setRequiredAttributeLevels={setRequiredAttributeLevels}
            requiredAttributeLevels={requiredAttributeLevels}
          />
        </>
      )}

      <Text
        variant="h5"
        style={{ marginTop: theme.space.med, marginBottom: theme.space.xs }}
      >
        Certifications ({requiredCertifications.length})
      </Text>
      <Row wrap style={{ gap: theme.space.xxs }}>
        {certifications
          ?.filter((c) => company.enabledCertifications?.includes(c.type))
          .map((c) => {
            const workerCertType: WorkerCertificationType =
              c.type as WorkerCertificationType
            const selected = !!requiredCertifications?.includes(workerCertType)
            return (
              <SelectableCard
                key={c.type}
                label={c.name}
                onClick={() => onClickCertification(workerCertType, selected)}
                selected={selected}
              />
            )
          })}
      </Row>
      <BGCRequirementSelector
        title={`Does the role have an extra Background Check Requirement?`}
        selectedExtraBGCRequirement={extraBGCRequirement}
        handleSelectExtraBGCRequirement={setExtraBGCRequirement.bind(this)}
      />
      <GenderPreferenceSelector
        selectedGenderPreference={genderPreference}
        setSelectedGenderPreference={setGenderPreference.bind(this)}
      />
      <Text
        variant="h5"
        style={{ marginTop: theme.space.med, marginBottom: theme.space.xs }}
      >
        What training videos are required?
        <Text
          style={{ marginLeft: theme.space.xxs }}
          variant="link"
          onClick={() => {
            setTrainingVideosModalIsOpen(true)
          }}
        >
          Upload new video
        </Text>
      </Text>
      {trainingVideos.map((tv) => {
        const isRequired =
          requiredForAllTrainingVideos?.findIndex((v) => v.id === tv.id) !== -1
        return (
          <Row my={theme.space.xxs}>
            <Checkbox
              key={tv.id}
              disabled={isRequired}
              checked={selectedVideoIds.includes(tv.id)}
              onChange={() => {
                if (isRequired) {
                  return
                }
                const idx = selectedVideoIds.findIndex(
                  (videoId) => videoId === tv.id,
                )
                const newVideoIds = [...selectedVideoIds]
                if (idx === -1) {
                  newVideoIds.push(tv.id)
                } else {
                  newVideoIds.splice(idx, 1)
                }
                setSelectedVideoIds(newVideoIds)
              }}
            />
            <Text variant="body1" style={{ marginLeft: theme.space.xs }}>
              {tv.name}
            </Text>
          </Row>
        )
      })}
      <Modal
        isOpen={trainingVideosModalIsOpen}
        handleClose={() => setTrainingVideosModalIsOpen(false)}
      >
        <UploadVideoModal
          companyId={company.companyId}
          onClose={() => setTrainingVideosModalIsOpen(false)}
          onUploadSuccess={onUploadSuccess}
          fileType={FileType.TRAINING_VIDEOS}
        />
      </Modal>
      <Row justifyBetween style={{ marginTop: theme.space.sm }}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant={ButtonVariant.FILLED}
          loading={submitLoading}
          disabled={!!roleNameError || !!Object.keys(formik.errors).length}
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </Row>
    </form>
  )
}
