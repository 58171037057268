import { useEligibilityConnectionsMutation } from '@traba/hooks'
import { Dialog, MODAL_SIZE, SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EligibilityConnectionMutationPayload } from '@traba/types'
import { useEffect, useState } from 'react'
import { Row } from 'src/components/base'
import { IMenuItem } from 'src/components/base/Select/Select'
import { useCompanies } from 'src/hooks/useCompanies'
import { EligibilityConnectionContent } from 'src/modals/ManageWorkerModal/ManageWorkerEligibilityConnections'

type WorkerEligibilityConnectionModalProps = {
  workerId: string
  handleClose: () => void
  isOpen: boolean
  selectedCompanyId: string
  setSelectedCompanyId: React.Dispatch<React.SetStateAction<string>>
  showSelect: boolean
  setShowSelect: React.Dispatch<React.SetStateAction<boolean>>
}

export const WorkerEligibilityConnectionModal = (
  props: WorkerEligibilityConnectionModalProps,
) => {
  const {
    isOpen,
    handleClose,
    workerId,
    showSelect,
    setShowSelect,
    selectedCompanyId,
    setSelectedCompanyId,
  } = props
  const { companies } = useCompanies({ isApproved: true })
  const [payload, setPayload] = useState<EligibilityConnectionMutationPayload>()
  const { mutate: updateEligibilityConnections } =
    useEligibilityConnectionsMutation()

  const companyOptions: IMenuItem[] = [
    {
      label: '-',
      value: '',
    },
    ...(companies
      ?.filter((company) => !!company.id)
      .map((company) => ({ label: company.employerName, value: company.id })) ||
      []),
  ]

  const [company, setCompany] = useState<IMenuItem | undefined>(
    companyOptions[0],
  )

  const onConfirm = () => {
    if (!payload) {
      return
    }

    updateEligibilityConnections(payload)

    setCompany(companyOptions[0])
    setShowSelect(true)
    setPayload(undefined)
    handleClose()
  }

  const onClose = () => {
    setCompany(companyOptions[0])
    setShowSelect(true)
    setPayload(undefined)
    handleClose()
  }

  useEffect(() => {
    setSelectedCompanyId(company?.value || '')
  }, [company, setSelectedCompanyId])

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      size={MODAL_SIZE.EXTRA_EXTRA_LARGE}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      dialogTitle={'Create Connection for Company'}
    >
      <Row flexCol fullHeight fullWidth>
        {showSelect && (
          <>
            <Text variant="h5" style={{ marginRight: theme.space.xs }}>
              Select a company to make the connection to
            </Text>

            <Row alignCenter mb={theme.space.sm} mt={theme.space.xs}>
              <SearchSelect
                options={companyOptions}
                selectItem={company}
                handleSelect={setCompany}
                label="Business"
                width={260}
              />
            </Row>
          </>
        )}
        {selectedCompanyId && (
          <EligibilityConnectionContent
            companyId={selectedCompanyId}
            workerId={workerId}
            setPayload={setPayload}
          />
        )}
      </Row>
    </Dialog>
  )
}
