export const FIVE_MINUTES_IN_MS = 300000
export const ONE_MINUTE_IN_MS = 60000
export const ONE_HOUR_IN_MS = 3600000

export const REQUIRED_FIELD_MESSAGE = 'This field is required'
export const VALID_ADDRESS = 'Please choose an address from the dropdown'

export const SHIFT_CODE_LEN = 6

export const KILOMETER_TO_MILES_CONVERSION_FACTOR = 0.621371
