import { Slider } from '@mui/material'
import { useAlert } from '@traba/context'
import { LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { FullAddress, KioskModeLevel, KioskModeType } from '@traba/types'
import { Locations, OpsCreateLocationDto } from '@traba/types'
import { useFormik } from 'formik'
import { isEqual } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Select,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Checkbox from 'src/components/base/Checkbox'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { LocationMedia } from 'src/components/LocationMedia'
import { DraggableMapPinInput } from 'src/components/Map/DraggableMapPinInput'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete'
import { useCompany } from 'src/hooks/useCompany'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { kioskModeTypeOptions } from 'src/utils/shiftFormUtils'
import * as yup from 'yup'

export type LocationModalProps = {
  location: OpsCreateLocationDto | Locations
  handleClose: () => void
  handleFormSubmit: (values: LocationModalForm) => void
  hideOpsOverride?: boolean
  isOpen: boolean
  mediaFiles: string[]
  setMediaFiles: Dispatch<SetStateAction<string[]>>
  shiftAreaFence: {
    latitude: string
    longitude: string
    radius_metres: number
  }
  title: string
  initialMediaFiles?: string[]
  companyId: string
}

export type LocationModalForm = {
  name: string
  neighborhoodName: string
  address: {
    city: string
    street1: string
    street2: string | undefined
    postalCode: string
    state: string
  }
  coords: {
    latitude: string
    longitude: string
  }
  shortLocation: string
  locationInstructions: string
  media: string[]
  opsLocationDetails: string | undefined
  opsOverride: boolean
  shiftAreaFence: {
    latitude: string
    longitude: string
    radius_metres: number
  }
  disableLeftFencePromptOverride: boolean
  enableLeftFencePromptOverride: boolean
  kioskModeType: KioskModeType
}

const MAP_DRAG_COORDS_PRECISION = 6
const FENCE_RADIUS_MIN_METRES = 100
const FENCE_RADIUS_MAX_METRES = 1000

export const LocationModal = (props: LocationModalProps) => {
  const {
    handleClose,
    handleFormSubmit,
    hideOpsOverride,
    isOpen,
    location,
    mediaFiles,
    setMediaFiles,
    shiftAreaFence,
    title,
    initialMediaFiles = [],
    companyId,
  } = props

  const { hotSettings } = useHotSettings()
  const { handleError } = useAlert()
  const { company, isLoading: isCompanyLoading } = useCompany(companyId)
  const [loading, setLoading] = useState(false)

  const initialFormValues = {
    name: location.name ?? '',
    neighborhoodName: location.neighborhoodName ?? '',
    address: {
      city: location.address.city,
      street1: location.address.street1,
      street2: location.address.street2,
      postalCode: location.address.postalCode,
      state: location.address.state,
    },
    coords: {
      latitude: location.coords.latitude.toString(),
      longitude: location.coords.longitude.toString(),
    },
    shiftAreaFence,
    shortLocation: location.shortLocation,
    locationInstructions: location.locationInstructions,
    opsLocationDetails: location.opsLocationDetails,
    opsOverride: false,
    disableLeftFencePromptOverride: location.disableLeftFencePromptOverride,
    enableLeftFencePromptOverride: location.enableLeftFencePromptOverride,
    kioskModeType:
      (company?.kioskModeLevel === KioskModeLevel.COMPANY
        ? company.kioskModeType
        : location.kioskModeType) ?? KioskModeType.None,
  }
  const validationSchema = yup.object({
    name: yup.string(),
    neighborhoodName: yup.string(),
    address: yup.object({
      city: yup.string().required(REQUIRED_FIELD_MESSAGE),
      street1: yup.string().required(REQUIRED_FIELD_MESSAGE),
      street2: yup.string(),
      postalCode: yup.string().required(REQUIRED_FIELD_MESSAGE),
      state: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    coords: yup.object({
      latitude: yup.string().required(REQUIRED_FIELD_MESSAGE),
      longitude: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    shortLocation: yup.string().required(REQUIRED_FIELD_MESSAGE),
    locationInstructions: yup.string(),
    opsLocationDetails: yup.string(),
    shiftAreaFence: yup.object({
      latitude: yup.string().required(REQUIRED_FIELD_MESSAGE),
      longitude: yup.string().required(REQUIRED_FIELD_MESSAGE),
      radius_metres: yup.number(),
    }),
    disableLeftFencePromptOverride: yup.boolean(),
    enableLeftFencePromptOverride: yup.boolean(),
  })

  function handleChangePlacesAutocomplete(val: FullAddress) {
    formik.setFieldValue('address', val)
    formik.setFieldValue('coords.latitude', val.location?.latitude.toString())
    formik.setFieldValue('coords.longitude', val.location?.longitude.toString())
    formik.setFieldValue(
      'shiftAreaFence.latitude',
      val.location?.latitude.toString(),
    )
    formik.setFieldValue(
      'shiftAreaFence.longitude',
      val.location?.longitude.toString(),
    )
    formik.setFieldValue('shortLocation', val.shortLocation)
  }

  function genHandleMapPinDrag(coordsField: string) {
    const handleMapPinDrag = (latitude: number, longitude: number) => {
      formik.setFieldValue(
        `${coordsField}.latitude`,
        latitude.toFixed(MAP_DRAG_COORDS_PRECISION),
      )
      formik.setFieldValue(
        `${coordsField}.longitude`,
        longitude.toFixed(MAP_DRAG_COORDS_PRECISION),
      )
    }
    return handleMapPinDrag
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (
      values: Omit<LocationModalForm, 'media'>,
      { resetForm },
    ) => {
      try {
        setLoading(true)
        handleFormSubmit({ ...values, media: mediaFiles })
        resetForm()
      } catch (err) {
        handleError(
          err,
          'LocationModal -> onSubmit',
          'An error occurred while submitting the form. Please verify all fields and try again.',
          'Submission Failed',
        )
      }
      setLoading(false)
      handleClose()
    },
  })
  const { errors, touched } = formik

  const customShiftAreaFenceInput = (hotSettings?.enableCustomGeofence ||
    true) && (
    <>
      <Text variant="h5" style={{ marginTop: theme.space.sm }}>
        Custom Work Area Fence
      </Text>
      <Text variant="body3">
        This will be used to track worker ETAs, arrival and eventually to
        auto-clock them out when they leave the area
      </Text>
      <Row style={{ marginTop: theme.space.xs }}>
        <Col style={{ marginRight: theme.space.xs }}>
          <DraggableMapPinInput
            pinLatitude={parseFloat(formik.values.shiftAreaFence.latitude)}
            pinLongitude={parseFloat(formik.values.shiftAreaFence.longitude)}
            width={'500px'}
            height={'500px'}
            onMovePin={genHandleMapPinDrag('shiftAreaFence')}
            fenceRadiusMetres={formik.values.shiftAreaFence.radius_metres}
          />
        </Col>
        <Col>
          <Row>
            <Text variant="body2">Fence Radius (Metres)</Text>
            <Slider
              value={formik.values.shiftAreaFence.radius_metres}
              max={FENCE_RADIUS_MAX_METRES}
              min={FENCE_RADIUS_MIN_METRES}
              valueLabelDisplay="on"
              onChange={(_e, value) =>
                formik.setFieldValue('shiftAreaFence.radius_metres', value)
              }
            />
          </Row>
        </Col>
      </Row>
    </>
  )

  const arrivalPinInput = (
    <>
      <Text variant="h5" style={{ marginTop: theme.space.sm }}>
        Arrival pin
      </Text>
      <Text variant="body3" style={{ marginTop: theme.space.xxs }}>
        This will be used when workers use "Get Directions" in the app
      </Text>
      <Row style={{ marginTop: theme.space.xs }}>
        <Col style={{ marginRight: theme.space.xs }}>
          <DraggableMapPinInput
            pinLatitude={parseFloat(formik.values.coords.latitude)}
            pinLongitude={parseFloat(formik.values.coords.longitude)}
            width={'500px'}
            height={'500px'}
            onMovePin={genHandleMapPinDrag('coords')}
          />
        </Col>
        <Col>
          <Input
            full
            label="Latitude"
            {...formik.getFieldProps('coords.latitude')}
            inputStatus={
              touched.coords?.latitude && errors.coords?.latitude ? 3 : 1
            }
            errorMessage={errors.coords?.latitude}
            style={{ marginBottom: theme.space.xs }}
          />
          <Input
            full
            label="Longitude"
            {...formik.getFieldProps('coords.longitude')}
            inputStatus={
              touched.coords?.longitude && errors.coords?.longitude ? 3 : 1
            }
            errorMessage={errors.coords?.longitude}
          />
        </Col>
      </Row>
    </>
  )

  const hasChanges =
    !isEqual(initialFormValues, formik.values) ||
    !isEqual(initialMediaFiles.sort(), mediaFiles.sort())
  const coordsSet =
    formik.values.coords.latitude && formik.values.coords.longitude

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      size={MODAL_SIZE.EXTRA_LARGE}
    >
      {isCompanyLoading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Input
            full
            label="Location name (optional)"
            {...formik.getFieldProps('name')}
            inputStatus={touched.name && errors.name && formik.touched ? 3 : 1}
            errorMessage={errors.name}
            containerStyle={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.sm,
            }}
          />
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            Address
          </Text>
          <PlacesAutocomplete
            onSelect={handleChangePlacesAutocomplete}
            onChange={(val) => formik.setFieldValue('address.street1', val)}
            value={formik.values?.address?.street1}
            label="Street Address"
            errorMessage={
              touched.address?.street1 && errors.address?.street1
                ? errors.address?.street1
                : null
            }
          />
          <Input
            full
            label="Floor/Suite (optional)"
            {...formik.getFieldProps('address.street2')}
            inputStatus={
              touched.address?.street2 &&
              errors.address?.street2 &&
              formik.touched
                ? 3
                : 1
            }
            errorMessage={errors.address?.street2}
            containerStyle={{ marginTop: theme.space.xs }}
          />
          <Input
            full
            label="City"
            {...formik.getFieldProps('address.city')}
            inputStatus={
              touched.address?.city && errors.address?.city && formik.touched
                ? 3
                : 1
            }
            errorMessage={errors.address?.city}
            containerStyle={{ marginTop: theme.space.xs }}
          />
          <Input
            full
            label="State / Province"
            {...formik.getFieldProps('address.state')}
            inputStatus={
              touched.address?.state && errors.address?.state ? 3 : 1
            }
            errorMessage={errors.address?.state}
            containerStyle={{ marginTop: theme.space.xs }}
          />
          <Input
            type="text"
            pattern="\d*"
            maxLength={5}
            full
            label="ZIP Code"
            {...formik.getFieldProps('address.postalCode')}
            inputStatus={
              touched.address?.postalCode && errors.address?.postalCode ? 3 : 1
            }
            errorMessage={errors.address?.postalCode}
            containerStyle={{ marginTop: theme.space.xs }}
          />
          <Input
            full
            label="Short Location"
            {...formik.getFieldProps('shortLocation')}
            inputStatus={touched.shortLocation && errors.shortLocation ? 3 : 1}
            errorMessage={errors.shortLocation}
            containerStyle={{ marginTop: theme.space.xs }}
          />

          <Input
            full
            label="Hidden Location Description"
            {...formik.getFieldProps('neighborhoodName')}
            inputStatus={
              touched.neighborhoodName && errors.neighborhoodName ? 3 : 1
            }
            errorMessage={errors.neighborhoodName}
            containerStyle={{
              marginBottom: theme.space.xxs,
              marginRight: theme.space.xs,
              marginTop: theme.space.xs,
            }}
          />
          <Row alignStart>
            <Icon name="info" />
            <Text variant="body3">
              Hidden Location Description will be shown to workers who are not
              on the shift and haven't worked with this company before. If not
              set, we'll show the Short Location instead.
            </Text>
          </Row>

          {coordsSet && arrivalPinInput}

          {coordsSet && customShiftAreaFenceInput}

          <Text variant="h5" style={{ marginTop: theme.space.sm }}>
            Arrival instructions
          </Text>
          <Text variant="body3" style={{ marginTop: theme.space.xxs }}>
            Help workers get to the shift more easily by adding location or
            parking instructions and/or photos.
          </Text>
          <Text variant="body2"></Text>
          <Input
            placeholder="i.e. Once you park, enter the building through the door next to the sign and..."
            name="locationInstructions"
            containerStyle={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.xxs,
            }}
            rows={3}
            type="textarea"
            defaultValue=""
            width="100%"
            value={formik.values.locationInstructions}
            onChange={formik.handleChange}
          />
          <Text variant="h5" style={{ marginTop: theme.space.sm }}>
            Additional Ops Details
          </Text>
          <Text variant="body3" style={{ marginTop: theme.space.xxs }}>
            Note: This will not automatically be shared with workers. For manual
            sharing purposes only for now.
          </Text>
          <Input
            placeholder="Add any additional details"
            name="opsLocationDetails"
            containerStyle={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.xxs,
            }}
            type="textarea"
            defaultValue=""
            width="100%"
            value={formik.values.opsLocationDetails}
            onChange={formik.handleChange}
          />
          <Text variant="h5" style={{ marginTop: theme.space.sm }}>
            Media
          </Text>
          <LocationMedia
            mediaFiles={mediaFiles}
            setMediaFiles={setMediaFiles}
          />
          {hideOpsOverride ? null : (
            <>
              <Text
                variant="h5"
                style={{
                  marginTop: theme.space.sm,
                  marginBottom: theme.space.sm,
                }}
              >
                Ops Override
              </Text>
              <Checkbox
                checked={formik.values.opsOverride}
                label="Ops Override will remove restrictions around allowable distance and will update any shifts that haven't already started."
                onChange={() =>
                  formik.setFieldValue(
                    'opsOverride',
                    !formik.values.opsOverride,
                  )
                }
              />
            </>
          )}
          {hotSettings?.globalShouldShowOutsideFenceFlow ? (
            <>
              <Text
                variant="h5"
                style={{
                  marginTop: theme.space.sm,
                  marginBottom: theme.space.sm,
                }}
              >
                Disable Worker Fence Exit Prompt Override
              </Text>
              <Checkbox
                checked={formik.values.disableLeftFencePromptOverride}
                label="Ops Override will make workers not get prompted when they leave the fence for this location"
                onChange={() =>
                  formik.setFieldValue(
                    'disableLeftFencePromptOverride',
                    !formik.values.disableLeftFencePromptOverride,
                  )
                }
              />
            </>
          ) : (
            <>
              <Text
                variant="h5"
                style={{
                  marginTop: theme.space.sm,
                  marginBottom: theme.space.sm,
                }}
              >
                Enable Worker Fence Exit Prompt Override
              </Text>
              <Checkbox
                checked={formik.values.enableLeftFencePromptOverride}
                label="Ops Override will make workers get prompted when they leave the fence for this location"
                onChange={() =>
                  formik.setFieldValue(
                    'enableLeftFencePromptOverride',
                    !formik.values.enableLeftFencePromptOverride,
                  )
                }
              />
            </>
          )}

          {hotSettings?.locationBasedKioskEnabled && (
            <>
              <Text variant="h5" mt={theme.space.sm} mb={theme.space.xs}>
                Kiosk Settings
              </Text>
              <Text variant="body2">
                {company?.kioskModeLevel !== KioskModeLevel.LOCATION
                  ? 'Currently, the kiosk settings are set at the company level. If you want to change this to be at the location level, please go to the company settings modal.'
                  : 'Currently, the Kiosk settings are set at the location level. If you want to change this to be at the company level, please go to the company settings modal.'}
              </Text>
              <Select
                containerStyle={{
                  marginTop: theme.space.sm,
                  width: '220px',
                }}
                dropdownStyle={{ height: theme.space.xl }}
                label={'Select Type'}
                menuItems={kioskModeTypeOptions}
                handleSelect={(type) => {
                  formik.setFieldValue('kioskModeType', type as KioskModeType)
                }}
                value={formik.values.kioskModeType}
                disabled={company?.kioskModeLevel !== KioskModeLevel.LOCATION}
              />
            </>
          )}

          <Row justifyBetween style={{ marginTop: theme.space.sm }}>
            <Button
              type="button"
              variant={ButtonVariant.OUTLINED}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.FILLED}
              loading={loading}
              disabled={!hasChanges}
            >
              {hasChanges ? 'Save' : 'No changes'}
            </Button>
          </Row>
        </form>
      )}
    </Modal>
  )
}
