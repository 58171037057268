import { Menu } from '@mui/material'
import { AttributeLevelType, OpsAttributeLevel } from '@traba/types'
import { useState } from 'react'
import { AttributeBadgeMenuItem } from './AttributeBadgeMenuItem'
import { AttributeBadgeMenuOption } from './types'
import { getOpsLevelFromAttributeMenuOption } from './utils'

interface AttributeBadgeMenuProps {
  menuAnchorEl: HTMLElement | null
  attributeType: string
  attributeLevel: OpsAttributeLevel
  levelType: AttributeLevelType
  handleClick: (opsLevel: OpsAttributeLevel) => void
  handleClose: () => void
  hideRevoke?: boolean
}

const baseLevelingMenuItems: AttributeBadgeMenuOption[] = [
  AttributeBadgeMenuOption.CONFIRM,
  AttributeBadgeMenuOption.UNKNOWN,
  AttributeBadgeMenuOption.REVOKE,
]

const skillLevelingMenuOptions: AttributeBadgeMenuOption[] = [
  AttributeBadgeMenuOption.VERIFY,
  AttributeBadgeMenuOption.WORKER_REPORTED,
  AttributeBadgeMenuOption.UNKNOWN,
  AttributeBadgeMenuOption.REVOKE,
]

const skillLevelingSecondaryMenuItems: AttributeBadgeMenuOption[] = [
  AttributeBadgeMenuOption.EXPERT,
  AttributeBadgeMenuOption.COMPETENT,
  AttributeBadgeMenuOption.BEGINNER,
]

const starLevelingMenuItems: AttributeBadgeMenuOption[] = [
  AttributeBadgeMenuOption.FIVE_STAR,
  AttributeBadgeMenuOption.FOUR_STAR,
  AttributeBadgeMenuOption.THREE_STAR,
  AttributeBadgeMenuOption.TWO_STAR,
  AttributeBadgeMenuOption.ONE_STAR,
  AttributeBadgeMenuOption.UNKNOWN,
]

export function AttributeBadgeMenu(props: AttributeBadgeMenuProps) {
  const {
    menuAnchorEl,
    attributeLevel,
    attributeType,
    levelType,
    handleClick,
    handleClose,
    hideRevoke,
  } = props

  const [secondaryMenuAnchorEl, setSecondaryMenuAnchorEl] =
    useState<null | HTMLElement>(null)

  function onClick(menuItem: AttributeBadgeMenuOption) {
    const opsLevel = getOpsLevelFromAttributeMenuOption(menuItem, levelType)
    handleClick(opsLevel)
    handleClose()
    handleSecondaryMenuClose()
  }

  const menuItems =
    levelType === AttributeLevelType.BASE_LEVELING
      ? baseLevelingMenuItems
      : levelType === AttributeLevelType.STAR_LEVELING
        ? starLevelingMenuItems
        : skillLevelingMenuOptions

  const filteredMenuItems = menuItems.filter(
    (item) => !(hideRevoke && item === AttributeBadgeMenuOption.REVOKE),
  )

  const handleSecondaryMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSecondaryMenuAnchorEl(event.currentTarget)
  }

  const handleSecondaryMenuClose = () => {
    setSecondaryMenuAnchorEl(null)
  }

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
      >
        {filteredMenuItems.map((item) => (
          <AttributeBadgeMenuItem
            item={item}
            attributeType={attributeType}
            attributeLevel={attributeLevel}
            handleSecondaryMenuOpen={handleSecondaryMenuOpen}
            onClick={onClick}
          />
        ))}
      </Menu>

      <Menu
        anchorEl={secondaryMenuAnchorEl}
        open={Boolean(secondaryMenuAnchorEl)}
        onClose={handleSecondaryMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {skillLevelingSecondaryMenuItems.map((item) => (
          <AttributeBadgeMenuItem
            item={item}
            attributeType={attributeType}
            attributeLevel={attributeLevel}
            handleSecondaryMenuOpen={handleSecondaryMenuOpen}
            onClick={onClick}
          />
        ))}
      </Menu>
    </>
  )
}
