import {
  EligibilityConnection,
  EligibilityConnectionLevel,
  EligibilityConnectionMutationPayload,
  EligibilityConnectionsByType,
  EligibilityConnectionType,
} from '@traba/types'
import { Dispatch, SetStateAction } from 'react'

export const eligibilityConnectionsToPayload = (
  workerId: string,
  companyId: string,
  globalEligibilityForRoles: EligibilityConnectionType,
  globalEligibilityForLocations: EligibilityConnectionType,
  ineligibleRoles: Set<string>,
  ineligibleLocations: Set<string>,
  roleEntries: string[][],
  locationEntries: string[][],
) => {
  const roleIds = roleEntries.map((id) => id[0])
  const locationIds = locationEntries.map((id) => id[0])

  const ineligibleRoleIds = Array.from(ineligibleRoles)
  const eligibleRoleIds = roleIds.filter((id) => !ineligibleRoles.has(id))

  const ineligibleLocationIds = Array.from(ineligibleLocations)
  const eligibleLocationIds = locationIds.filter(
    (id) => !ineligibleLocations.has(id),
  )

  const roleData = [
    ...ineligibleRoleIds.map((roleId) => ({
      roleId,
      eligibilityConnectionType: EligibilityConnectionType.INELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.ROLE,
    })),
    ...eligibleRoleIds.map((roleId) => ({
      roleId,
      eligibilityConnectionType: EligibilityConnectionType.ELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.ROLE,
    })),
  ]
  const locationData = [
    ...ineligibleLocationIds.map((locationId) => ({
      locationId,
      eligibilityConnectionType: EligibilityConnectionType.INELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.LOCATION,
    })),
    ...eligibleLocationIds.map((locationId) => ({
      locationId,
      eligibilityConnectionType: EligibilityConnectionType.ELIGIBLE,
      eligibilityConnectionLevel: EligibilityConnectionLevel.LOCATION,
    })),
  ]

  const eligibilityConnections: EligibilityConnectionsByType = {
    roles: roleData,
    locations: locationData,
    locationAndRole: [],
  }

  const payload: EligibilityConnectionMutationPayload = {
    workerId,
    companyId,
    initiatedBy: 'OPS',
    globalEligibilities: {
      globalEligibilityForLocations: globalEligibilityForLocations,
      globalEligibilityForRoles: globalEligibilityForRoles,
    },
    eligibilityConnections,
  }
  return payload
}

export type IneligibleContentProps = {
  eligibilityConnections: EligibilityConnection[]
  setEligibilityConnections: Dispatch<SetStateAction<EligibilityConnection[]>>
}

export const getEligibilitySelectionData = (
  eligibilityConnectionLevel: EligibilityConnectionLevel,
  roleEntries: string[][],
  locationEntries: string[][],
  ineligibleRoles: Set<string>,
  ineligibleLocations: Set<string>,
  setIneligibleRoles: React.Dispatch<React.SetStateAction<Set<string>>>,
  setIneligibleLocations: React.Dispatch<React.SetStateAction<Set<string>>>,
  globalEligibilityForRoles: EligibilityConnectionType,
  globalEligibilityForLocations: EligibilityConnectionType,
  setGlobalEligibilityForRoles: React.Dispatch<
    React.SetStateAction<EligibilityConnectionType>
  >,
  setGlobalEligibilityForLocations: React.Dispatch<
    React.SetStateAction<EligibilityConnectionType>
  >,
) => {
  let ids = locationEntries
  let ineligibilities = ineligibleLocations
  let setIneligibilities = setIneligibleLocations
  let toggleLabel = ''
  let toggleState = globalEligibilityForLocations
  let setToggle = setGlobalEligibilityForLocations

  switch (eligibilityConnectionLevel) {
    case EligibilityConnectionLevel.ROLE:
      ids = roleEntries
      ineligibilities = ineligibleRoles
      setIneligibilities = setIneligibleRoles
      toggleLabel = 'Eligible for future roles'
      toggleState = globalEligibilityForRoles
      setToggle = setGlobalEligibilityForRoles
      break
    case EligibilityConnectionLevel.LOCATION:
      ids = locationEntries
      ineligibilities = ineligibleLocations
      setIneligibilities = setIneligibleLocations
      toggleLabel = 'Eligible for future locations'
      toggleState = globalEligibilityForLocations
      break
    case EligibilityConnectionLevel.ROLE_AND_LOCATION:
      break
  }

  return {
    ids,
    ineligibilities,
    setIneligibilities,
    toggleLabel,
    toggleState,
    setToggle,
  }
}
