import { Text } from '@traba/react-components'
import { ShiftAssignmentResponse } from '@traba/types'
import { Row } from 'src/components/base'
import { DotText } from 'src/components/base/DotText'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { ShiftAssignmentListItem } from './ShiftAssignmentListItem'

type ShiftAssignmentListProps = {
  shiftsList: OpsExtendedShift[]
  shiftAssignmentsMapByShiftId: Map<string, ShiftAssignmentResponse>
  refetchAssignments: () => Promise<void>
  shouldRefetchAssignmentOnEachUpdate: boolean
}

export const ShiftAssignmentList = (props: ShiftAssignmentListProps) => {
  const {
    shiftsList,
    shiftAssignmentsMapByShiftId,
    refetchAssignments,
    shouldRefetchAssignmentOnEachUpdate,
  } = props

  const tz = useTimezonedDates(shiftsList[0].timezone)

  return (
    <>
      {shiftsList.map((shift, i) => {
        const shiftAssignment = shiftAssignmentsMapByShiftId.get(shift.id)
        return (
          <div key={`${shiftAssignment?.id}_${i}`}>
            <Row alignCenter>
              <Text variant="h7">
                {shift.employerName || shift.company?.employerName} |{' '}
                {shift.shiftRole || shift.role?.roleName} |{' '}
                {shift.shortLocation || shift.location?.shortLocation}
              </Text>
              <DotText />
              <Text variant="caption">{tz.getDateTime(shift.startTime)}</Text>
            </Row>
            {shiftAssignment ? (
              <ShiftAssignmentListItem
                shift={shift}
                shiftAssignment={shiftAssignment}
                refetchAssignments={refetchAssignments}
                shouldRefetchAssignmentOnEachUpdate={
                  shouldRefetchAssignmentOnEachUpdate
                }
              />
            ) : (
              <Text variant="caption">No current assignments</Text>
            )}
          </div>
        )
      })}
    </>
  )
}
