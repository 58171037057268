import { DEFAULT_FENCE_RADIUS_METERS } from '@traba/consts'
import { KioskModeType, WorkerImage, WorkerMediaType } from '@traba/types'
import { GeofenceType, UpdateShiftAreaFenceDto } from '@traba/types'
import { Locations } from '@traba/types'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useGeofences } from 'src/hooks/useGeofences'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { LocationModal } from '../LocationModal/LocationModal'

export type EditLocationModalProps = {
  companyId: string
  location: Locations
  handleClose: () => void
  isOpen: boolean
}

type EditLocationModalForm = {
  name: string
  address: {
    city: string
    street1: string
    street2: string | undefined
    postalCode: string
    state: string
  }
  coords: {
    latitude: string
    longitude: string
  }
  shortLocation: string
  locationInstructions: string
  neighborhoodName: string
  opsLocationDetails: string | undefined
  opsOverride: boolean
  shiftAreaFence: {
    latitude: string
    longitude: string
    radius_metres: number
  }
  disableLeftFencePromptOverride: boolean
  enableLeftFencePromptOverride: boolean
  kioskModeType: KioskModeType
}

export const EditLocationModal = (props: EditLocationModalProps) => {
  const { isOpen, handleClose, companyId, location } = props
  const { editLocation } = useLocations(props.companyId)
  const {
    isLoading: _isLoadingShiftAreaFence,
    shiftAreaFence,
    updateShiftAreaFence,
  } = useGeofences(props.location.locationId)
  const { hotSettings } = useHotSettings()

  const initialMediaFiles = location?.media
    ? location?.media
        ?.filter((media) => media.type === WorkerMediaType.IMAGE)
        .map((media) => (media as WorkerImage).imageUrl)
    : []

  const [mediaFiles, setMediaFiles] = useState(initialMediaFiles)

  const shiftFence =
    shiftAreaFence &&
    shiftAreaFence.circleCoordinates &&
    shiftAreaFence.circleRadiusMeters
      ? {
          latitude: shiftAreaFence.circleCoordinates.latitude.toString(),
          longitude: shiftAreaFence.circleCoordinates.longitude.toString(),
          radius_metres: shiftAreaFence.circleRadiusMeters,
        }
      : {
          latitude: location.coords.latitude.toString(),
          longitude: location.coords.longitude.toString(),
          radius_metres:
            hotSettings?.defaultTrackingRadiusMetres ??
            DEFAULT_FENCE_RADIUS_METERS,
        }

  function handleFormSubmit(values: EditLocationModalForm) {
    const { shiftAreaFence } = values

    editLocation({
      locationId: location.locationId,
      companyId: companyId,
      updatedLocation: {
        shortLocation: values.shortLocation || '',
        name: values.name,
        address: {
          city: values.address.city,
          street1: values.address.street1,
          street2: values.address.street2 ? values.address.street2 : undefined,
          postalCode: values.address.postalCode,
          state: values.address.state,
        },
        coords: {
          latitude: parseFloat(values.coords.latitude),
          longitude: parseFloat(values.coords.longitude),
        },
        locationInstructions: values.locationInstructions,
        neighborhoodName: values.neighborhoodName,
        opsLocationDetails: values.opsLocationDetails,
        opsOverride: values.opsOverride,
        media: mediaFiles,
        disableLeftFencePromptOverride: values.disableLeftFencePromptOverride,
        enableLeftFencePromptOverride: values.enableLeftFencePromptOverride,
        kioskModeType: values.kioskModeType,
      },
    })
    if (!isEqual(shiftFence, values.shiftAreaFence)) {
      const updateFenceDto: UpdateShiftAreaFenceDto = {
        type: GeofenceType.CIRCLE,
        circleCoordinates: {
          longitude: parseFloat(shiftAreaFence.longitude),
          latitude: parseFloat(shiftAreaFence.latitude),
        },
        circleRadiusMeters: shiftAreaFence.radius_metres,
      }

      updateShiftAreaFence({
        locationId: location.locationId,
        updatedFence: updateFenceDto,
      })
    }
  }

  return (
    <LocationModal
      location={location}
      isOpen={isOpen}
      handleClose={handleClose}
      handleFormSubmit={handleFormSubmit}
      hideOpsOverride={false}
      mediaFiles={mediaFiles}
      setMediaFiles={setMediaFiles}
      shiftAreaFence={shiftFence}
      title={'Edit Location'}
      initialMediaFiles={initialMediaFiles}
      companyId={companyId}
    />
  )
}
