import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ONE_MINUTE_IN_MS } from '@traba/consts'
import {
  WorkerShiftTransit,
  WorkerShiftTransitResponse,
  WorkerShiftTransitResponseByShift,
} from '@traba/types'

const WORKER_SHIFT_TRANSIT_KEY = 'workerShiftTransit'

async function getWorkerShiftTransits(
  shiftId: string,
): Promise<WorkerShiftTransitResponseByShift> {
  try {
    const { data } = await trabaApi.get(
      `worker-shift-transit/shift/${shiftId}?includeHistory=true`,
    )
    return data
  } catch (e) {
    console.error(e)
    throw e
  }
}

async function queryAndParseWorkerShiftTransit(
  shiftId: string,
): Promise<WorkerShiftTransit[]> {
  const res: WorkerShiftTransitResponseByShift =
    await getWorkerShiftTransits(shiftId)
  const workerShiftTransits: WorkerShiftTransit[] = res.workerShiftTransits.map(
    (workerShiftTransitResponse: WorkerShiftTransitResponse) => {
      return {
        ...workerShiftTransitResponse,
        latestRemainingMetricsTimestamp: new Date(
          Date.parse(
            workerShiftTransitResponse.latestRemainingMetricsTimestamp,
          ),
        ),
        arrivedAt: workerShiftTransitResponse.arrivedAt
          ? new Date(Date.parse(workerShiftTransitResponse.arrivedAt))
          : undefined,
        workerShiftTransitHistory:
          workerShiftTransitResponse.workerShiftTransitHistory
            ? workerShiftTransitResponse.workerShiftTransitHistory.map(
                (transitHistoryEntryResponse) => {
                  return {
                    ...transitHistoryEntryResponse,
                    eventTimestamp: new Date(
                      Date.parse(transitHistoryEntryResponse.eventTimestamp),
                    ),
                  }
                },
              )
            : [],
      }
    },
  )
  return workerShiftTransits
}

function collectWorkerShiftTransitsByWorkerId(
  data: WorkerShiftTransit[],
): Record<string, WorkerShiftTransit> {
  return data.reduce(
    (acc, transit) => {
      acc[transit.workerId] = transit
      return acc
    },
    {} as Record<string, WorkerShiftTransit>,
  )
}

export function useWorkerShiftTransit(shiftId?: string) {
  if (!shiftId) {
    console.error(`shiftId is invalid, shiftId=${shiftId}`)
    throw new Error('shiftId is invalid')
  }
  const {
    isLoading,
    isError,
    data: workerShiftTransitByWorkerId,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerShiftTransit[], Error, Record<string, WorkerShiftTransit>>(
    {
      queryKey: [WORKER_SHIFT_TRANSIT_KEY, shiftId],
      queryFn: () => queryAndParseWorkerShiftTransit(shiftId),
      staleTime: ONE_MINUTE_IN_MS,
      select: collectWorkerShiftTransitsByWorkerId,
    },
  )

  return {
    isLoading,
    isError,
    workerShiftTransitByWorkerId,
    error,
    isFetched,
    refetch,
  }
}
