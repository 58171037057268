import { CircularProgress } from '@mui/material'
import { SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AssigneeStatus, ShiftStatus, Region } from '@traba/types'
import { startOfDay, addYears } from 'date-fns'
import { ChangeEvent, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { Button, Col, Icon, Input, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useModal } from 'src/components/base/Modal/Modal'
import Pagination from 'src/components/base/Pagination/Pagination'
import { IMenuItem } from 'src/components/base/Select/Select'
import { TableRow } from 'src/components/base/Table/DataTable'
import Toggle from 'src/components/base/Toggle'
import ShiftAssignmentModal from 'src/components/ShiftAssigmentModal/ShiftAssigmentModal'
import { useDownloadCompanyReport } from 'src/hooks/useDownloadCompanyReport'
import { DEFAULT_PAGE_SIZE, SortOrder } from 'src/hooks/usePagination'
import { OpsExtendedShift, useSearchShifts } from 'src/hooks/useShifts'
import BulkCancelShiftsModal from 'src/modals/CancelShiftModal/BulkCancelShiftsModal'
import { AddWorkerToShiftsModal } from 'src/screens/FieldMonitorScreen/components/AddWorkers/AddWorkerToShiftsModal'
import { ShiftActionsMenu } from 'src/screens/FieldMonitorScreen/components/ShiftActionsMenu'
import { openInNewTab } from 'src/utils/helperUtils'
import { useShiftAssignmentFilters } from '../../../hooks/useAssignments'
import CompanyShiftsTable from './CompanyShiftsTable/CompanyShiftsTable'

type CompanyShiftsTabProps = {
  companyId: string
  regions: Region[]
}

const defaultSelectFields = {
  shift: [
    'id',
    'status',
    'createdAt',
    'startTime',
    'endTime',
    'regionId',
    'companyId',
    'timezone',
    'canceledAt',
    'slotsRequested',
    'slotsFilled',
    'overbookSlotsRequested',
    'paidBackupSlotsRequested',
    'paidBackupSlotsFilled',
    'tags',
    'payRate',
    'payType',
    'minimumAcceptedTier',
    'signupStatus',
    'extraBGCRequirement',
    'shiftRequestId',
  ],
  company: ['id', 'employerName'],
  role: ['roleName'],
  location: ['shortLocation'],
}

type ShiftAssignmentFiltersProps = {
  shiftIds: string[]
  shiftAssignmentModal: ReturnType<typeof useModal>
  selectedShiftsList: OpsExtendedShift[]
}

const ShiftAssignmentFilters = ({
  shiftIds,
  shiftAssignmentModal,
  selectedShiftsList,
}: ShiftAssignmentFiltersProps) => {
  const { shiftAssignmentsMapByShiftId, refetch: refetchAssignments } =
    useShiftAssignmentFilters({
      assigneeStatuses: [AssigneeStatus.Assigned],
      includeShift: true,
      shiftIds,
    })

  return (
    <ShiftAssignmentModal
      handleClose={shiftAssignmentModal.handleClose}
      isOpen={shiftAssignmentModal.isOpen}
      shiftsList={selectedShiftsList}
      refetchAssignments={refetchAssignments}
      shiftAssignmentsMapByShiftId={shiftAssignmentsMapByShiftId}
      shouldRefetchAssignmentOnEachUpdate={true}
    />
  )
}

export const CompanyShiftsTab = ({
  companyId,
  regions,
}: CompanyShiftsTabProps) => {
  const [selectedRows, setSelectedRows] = useState<TableRow[]>([])
  const begOfToday = startOfDay(new Date())
  const nextYear = addYears(begOfToday, 1)
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    begOfToday,
    nextYear,
  ])

  const [regionIds, setRegionIds] = useState<IMenuItem[]>([])
  const regionsOptions: IMenuItem[] = regions.map((region) => ({
    label: region.displayName,
    value: region.regionId,
  }))
  const [showCanceledShiftsFilter, setShowCanceledShiftsFilter] =
    useState(false)
  const [magicSearchText, setMagicSearchText] = useState<string>('')
  const [debouncedMagicSearchText, setDebouncedMagicSearchText] =
    useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const activeSearchParams = {
    startTimeBefore: dateRange[1] ? dateRange[1] : undefined,
    startTimeAfter: dateRange[0] ? dateRange[0] : undefined,
    companyId,
    regionIds: regionIds.length
      ? regionIds.map((r) => r.value as string)
      : undefined,
    statuses: showCanceledShiftsFilter
      ? [ShiftStatus.CANCELED, ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
      : [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE],
  }

  const addWorkersToShiftsModal = useModal()
  const shiftAssignmentModal = useModal()
  const bulkCancelShiftsModal = useModal()

  useDebounce(() => setDebouncedMagicSearchText(magicSearchText), 500, [
    magicSearchText,
  ])

  const {
    shifts = [],
    isLoading: isLoadingShifts,
    totalFound,
    goToNextPage,
    goToPreviousPage,
    currentPage,
    setCurrentPage,
  } = useSearchShifts({
    params: activeSearchParams,
    paginationParams: {
      limit: DEFAULT_PAGE_SIZE,
      offset: 0,
      sortBy: 'startTime',
      sortOrder: SortOrder.asc,
    },
    select: defaultSelectFields,
    magicSearchText: debouncedMagicSearchText,
  })

  const { getTimesheetReport, isLoading } = useDownloadCompanyReport(companyId)

  const shiftIds = useMemo(() => shifts.map((shift) => shift.id), [shifts])

  const selectedRowKeys = useMemo(
    () => new Set(selectedRows.map((row) => row.key)),
    [selectedRows],
  )

  const selectedShiftsList = useMemo(
    () => shifts.filter((shift) => selectedRowKeys.has(shift.id)),
    [shifts, selectedRowKeys],
  )

  return (
    <>
      <Col>
        <Row mb={theme.space.sm} fullWidth justifyBetween>
          <Text mb={theme.space.sm} variant="h4">
            {`Shifts (${totalFound})`}
          </Text>
          <Row>
            <Button
              slim
              leftIcon={<Icon name="link" />}
              onClick={() => openInNewTab('/timesheet')}
              variant={ButtonVariant.OUTLINED}
            >
              Upload Timesheet
            </Button>
            {selectedRows.length > 0 && (
              <Button
                slim
                leftIcon={<Icon name="download" />}
                onClick={() =>
                  getTimesheetReport({
                    shiftIds: selectedRows.map((row) => row.key),
                  })
                }
                variant={ButtonVariant.OUTLINED}
                loading={isLoading}
              >
                Download
              </Button>
            )}
          </Row>
        </Row>
        <Row mb={theme.space.sm} fullWidth justifyBetween>
          <Row alignCenter>
            <DateRangePicker
              label="Date range"
              inlineLabel={true}
              dateRange={dateRange}
              setDateRange={setDateRange}
              granularity="day"
              style={{ paddingRight: theme.space.sm }}
            />
            <Col style={{ width: 200 }} mr={theme.space.sm}>
              <SearchSelect
                multiple
                options={regionsOptions}
                selectedItems={regionIds}
                handleSelectMultiple={setRegionIds}
                label="Region"
              />
            </Col>
            <Col mr={theme.space.sm}>
              <Input
                label="Magic Search"
                value={magicSearchText}
                onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  setMagicSearchText(ev.target.value)
                }
                style={{ height: 40 }}
                width="260px"
              />
            </Col>
            <Col mr={theme.space.sm}>
              <Toggle
                label={'Show Canceled'}
                buttonState={showCanceledShiftsFilter}
                runOnChange={() => {
                  setShowCanceledShiftsFilter(!showCanceledShiftsFilter)
                  setCurrentPage(0)
                }}
                containerStyle={{ width: 200, justifyContent: 'center' }}
              />
            </Col>
            <Col>
              <Button
                id="actions-button"
                aria-controls={anchorEl ? 'actions-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget)
                }}
                variant={ButtonVariant.OUTLINED}
                loading={isLoadingShifts}
                disabled={isLoadingShifts || !selectedRows.length}
                style={{ width: 150, height: 40 }}
                ml={theme.space.xxs}
              >
                Shift Actions
              </Button>
              <ShiftActionsMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                handleClose={() => {
                  setAnchorEl(null)
                }}
                openAddWorkersToShiftsModal={() =>
                  addWorkersToShiftsModal.open()
                }
                openShiftAssignmentModal={() => shiftAssignmentModal.open()}
                openBulkCancelShiftsModal={() => bulkCancelShiftsModal.open()}
              ></ShiftActionsMenu>
            </Col>
          </Row>
          <Row justifyCenter mb={theme.space.xs}>
            <Pagination
              dataSize={shifts.length}
              onPageLeft={goToPreviousPage}
              onPageRight={goToNextPage}
              page={currentPage}
              pageSize={DEFAULT_PAGE_SIZE}
              totalFound={totalFound}
            />
          </Row>
        </Row>
        {isLoadingShifts ? (
          <Row style={{ height: 500 }} alignCenter center>
            <Row style={{ top: 100 }}>
              <CircularProgress size={36} color={'primary'} />
            </Row>
          </Row>
        ) : (
          <CompanyShiftsTable
            shifts={shifts}
            selectedRows={selectedRows}
            onSelectRows={(selectedRows: TableRow[]) => {
              setSelectedRows(selectedRows)
            }}
          />
        )}
      </Col>
      <AddWorkerToShiftsModal
        handleClose={addWorkersToShiftsModal.handleClose}
        isOpen={addWorkersToShiftsModal.isOpen}
        shiftsList={selectedShiftsList}
      />
      {shiftIds.length > 0 && (
        <ShiftAssignmentFilters
          shiftIds={shiftIds}
          selectedShiftsList={selectedShiftsList}
          shiftAssignmentModal={shiftAssignmentModal}
        />
      )}
      <BulkCancelShiftsModal
        handleClose={bulkCancelShiftsModal.handleClose}
        isOpen={bulkCancelShiftsModal.isOpen}
        shiftsList={selectedShiftsList}
      />
    </>
  )
}
