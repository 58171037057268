import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  EligibilityConnectionMutationPayload,
  EligibilityConnectionResponse,
} from '@traba/types'
import { AxiosResponse } from 'axios'

export const ELIGIBILITY_CONNECTIONS_QUERY_KEY =
  'eligibilityConnectionsQueryKey'
export const COMPANY_CONNECTIONS_QUERY_KEY = 'companyConnectionsQueryKey'
export const COMPANY_ELIGIBILITY_CONNECTIONS_QUERY_KEY =
  'companyEligibilityConnectionsQueryKey'
export const WORKER_CONNECTIONS_QUERY_KEY = 'workerConnectionsQueryKey'
export const WORKER_ELIGIBILITY_CONNECTIONS_QUERY_KEY =
  'workerEligibilityConnectionsQueryKey'

const getEligibilityConnections = async (
  companyId: string,
  workerId: string,
) => {
  try {
    const res = await trabaApi.get(
      `connections/eligibility-connections/company/${companyId}/worker/${workerId}`,
    )
    return res.data
  } catch (error) {
    console.error(
      'useEligibilityConnections -> getEligibilityConnections() ERROR',
      error,
    )
    Sentry.captureException(error)
    throw error
  }
}

export const useEligibilityConnections = (
  companyId: string,
  workerId: string,
) => {
  const { isLoading, isError, data, error, isFetched } = useQuery<
    EligibilityConnectionResponse,
    Error
  >({
    queryKey: [ELIGIBILITY_CONNECTIONS_QUERY_KEY, companyId, workerId],
    queryFn: () => {
      return getEligibilityConnections(companyId, workerId)
    },
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    eligibilityConnections: data?.eligibilityConnections,
    globalEligibilityConnection: data?.globalEligibilities,
    isLoading,
    isError,
    error,
    isFetched,
  }
}

export function useEligibilityConnectionsMutation() {
  const { showError, showSuccess } = useAlert()
  const queryClient = useQueryClient()

  const mutation = useMutation<
    AxiosResponse<string, string>,
    Error,
    EligibilityConnectionMutationPayload
  >({
    mutationFn: (query: EligibilityConnectionMutationPayload) => {
      return trabaApi.patch(`connections/eligibility-connections`, {
        ...query,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ELIGIBILITY_CONNECTIONS_QUERY_KEY],
      })
      showSuccess('Eligibility connections updated successfully')
      queryClient.invalidateQueries({
        queryKey: [ELIGIBILITY_CONNECTIONS_QUERY_KEY],
      })
      return
    },
    onError: (err) => {
      queryClient.invalidateQueries({
        queryKey: [ELIGIBILITY_CONNECTIONS_QUERY_KEY],
      })
      showError(
        `There was an error updating eligibility connections: ${err.message}`,
      )
    },
  })

  const mutationFnWrapper = (payload: EligibilityConnectionMutationPayload) =>
    mutation.mutate(payload)

  return {
    mutate: mutationFnWrapper,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
