import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { WorkerShiftAvailabilityResponse } from '@traba/types'
import { getErrorMessage } from '@traba/utils'
import { trabaApi } from 'src/api/helpers'

async function getWorkerAvailabilities(
  workerIds: string[],
  shiftId?: string,
): Promise<WorkerShiftAvailabilityResponse> {
  if (!shiftId) {
    return { availabilities: [] }
  }
  try {
    const res = await trabaApi.post('shifts/worker-availabilities', {
      workerIds,
      shiftInfo: { shiftId },
    })
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

export function useWorkerAvailabilities({
  workerIds,
  shiftId,
}: {
  workerIds: string[]
  shiftId?: string
}) {
  const {
    isLoading,
    isError,
    isFetched,
    data: response,
    error,
    refetch,
  } = useQuery<WorkerShiftAvailabilityResponse, Error>({
    queryKey: ['worker_availabilities', workerIds, shiftId],
    queryFn: () => getWorkerAvailabilities(workerIds, shiftId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const availabilities = response?.availabilities || []
  const workerAvailabilitiesByWorkerId = new Map(
    availabilities.map((a) => [a.workerId, a]),
  )

  return {
    isLoading,
    isError,
    isFetched,
    workerAvailabilitiesByWorkerId,
    error,
    refetch,
  }
}
