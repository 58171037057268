import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Text } from '@traba/react-components'
import { IncentiveRules, IncentiveTypeIds, ProgressLine } from '@traba/types'
import { WorkerIncentive } from '@traba/types'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useIncentives } from '../../hooks/useIncentives'
import { getMoneyString, truncateString } from '../../utils/stringUtils'
import { Badge, Button, CopyTextIcon, Icon, Row } from '../base'
import { ButtonVariant } from '../base/Button/types'

const getBadgeColor = (status: string) => {
  switch (status) {
    case 'REVERSED':
      return 'warning'
    case 'FAILED':
      return 'danger'
    case 'COMPLETE':
      return 'success'
    default:
      return 'info'
  }
}

function extractProgressFromArray(progress: ProgressLine[]) {
  const currentProgress = progress.find(
    (p) => p.type === IncentiveRules.ShiftsCompletionRequired,
  )
  if (!currentProgress) {
    return
  }
  return `${currentProgress.count}/${currentProgress.target}`
}

const workerDetailColumns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 160,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 180 }}>
        <Text>{params.value}</Text>
      </Row>
    ),
    minWidth: 180,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween>
        <Badge
          variant={getBadgeColor(params.value)}
          title={params.value}
          style={{ minWidth: 80, height: 25, borderRadius: 8 }}
        />
      </Row>
    ),
  },
  {
    field: 'progress',
    headerName: 'Progress',
    width: 120,
    renderCell: (params) => (
      <Row justifyCenter style={{ width: 120 }}>
        {params.value.isProcessing ? (
          <Text>...</Text>
        ) : (
          <Text>
            {params.value.progress.length
              ? extractProgressFromArray(params.value.progress)
              : '-'}
          </Text>
        )}
      </Row>
    ),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 120,
    renderCell: (params) => (
      <Row justifyCenter style={{ width: 120 }}>
        <Text>{params.value ?? '-'}</Text>
      </Row>
    ),
  },
  {
    field: 'id',
    headerName: 'Worker Incentive ID',
    width: 180,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{truncateString(params.value, 12)}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'incentiveId',
    headerName: 'Incentive ID',
    width: 180,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{truncateString(params.value, 12)}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'earnedAt',
    headerName: 'Earned At',
    width: 100,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 180 }}>
        <Text>
          {params.value
            ? format(new Date(params.value), 'MMM dd, yyyy hh:mm a')
            : '-'}
        </Text>
      </Row>
    ),
    minWidth: 180,
  },
  {
    field: 'typeId',
    headerName: 'Type ID',
    width: 140,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 140 }}>
        <Text>{params.value}</Text>
      </Row>
    ),
  },
  {
    field: 'referralType',
    headerName: 'Referral type',
    width: 140,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 140 }}>
        <Text>{params.value}</Text>
      </Row>
    ),
  },
  {
    field: 'valueType',
    headerName: 'Value Type',
    width: 140,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 140 }}>
        <Text>{params.value}</Text>
      </Row>
    ),
  },
  {
    field: 'action',
    headerName: '...',
    width: 140,
    renderCell: (params) => (
      <WorkerIncentiveResyncActionsCell
        wi={params.value.wi}
        handleResync={params.value.handleResync}
        isProcessing={params.value.isProcessing}
      />
    ),
  },
  {
    field: 'reverse',
    headerName: '...',
    width: 140,
    renderCell: (params) => (
      <WorkerIncentiveReverseCell
        wi={params.value.wi}
        handleReverse={params.value.handleReverse}
        isProcessing={params.value.isProcessing}
      />
    ),
  },
]

const incentiveDetailsColumns: GridColDef[] = [
  {
    field: 'workerId',
    headerName: 'Worker ID',
    width: 180,
    flex: 1,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{params.value}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'id',
    headerName: 'Worker Incentive ID',
    width: 180,
    flex: 1,
    renderCell: (params) => (
      <Row justifyBetween>
        <Text>{params.value}</Text>
        <CopyTextIcon textToCopy={params.value} />
      </Row>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    renderCell: (params) => (
      <Row justifyBetween>
        <Badge
          variant={getBadgeColor(params.value)}
          title={params.value}
          style={{ minWidth: 80, height: 25, borderRadius: 8 }}
        />
      </Row>
    ),
  },
  {
    field: 'progress',
    headerName: 'Progress',
    width: 120,
    renderCell: (params) => (
      <Row justifyCenter style={{ width: 120 }}>
        {params.value.isProcessing ? (
          <Text>...</Text>
        ) : (
          <Text>
            {params.value.progress.length
              ? extractProgressFromArray(params.value.progress)
              : '-'}
          </Text>
        )}
      </Row>
    ),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 120,
    renderCell: (params) => (
      <Row justifyCenter style={{ width: 120 }}>
        <Text>{params.value ?? '-'}</Text>
      </Row>
    ),
  },
  {
    field: 'earnedAt',
    headerName: 'Earned At',
    width: 100,
    renderCell: (params) => (
      <Row justifyBetween style={{ width: 180 }}>
        <Text>
          {params.value
            ? format(new Date(params.value), 'MMM dd, yyyy hh:mm a')
            : '-'}
        </Text>
      </Row>
    ),
    minWidth: 180,
  },
  {
    field: 'action',
    headerName: '...',
    width: 140,
    renderCell: (params) => (
      <WorkerIncentiveResyncActionsCell
        wi={params.value.wi}
        handleResync={params.value.handleResync}
        isProcessing={params.value.isProcessing}
      />
    ),
  },
  {
    field: 'reverse',
    headerName: '...',
    width: 140,
    renderCell: (params) => (
      <WorkerIncentiveReverseCell
        wi={params.value.wi}
        handleReverse={params.value.handleReverse}
        isProcessing={params.value.isProcessing}
      />
    ),
  },
]

const WorkerIncentiveResyncActionsCell = ({
  wi,
  handleResync,
  isProcessing,
}: {
  wi: WorkerIncentive
  handleResync: (wi: WorkerIncentive) => void
  isProcessing: boolean
}) => {
  return (
    <Row justifyBetween style={{ width: 140 }}>
      {wi.incentiveInfo.typeId === IncentiveTypeIds.dXgY && (
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => handleResync(wi)}
          disabled={isProcessing}
          slim
          leftIcon={isProcessing ? undefined : <Icon name="refresh" />}
          style={{ width: 120 }}
        >
          {isProcessing ? '...' : 'Resync'}
        </Button>
      )}
    </Row>
  )
}

const WorkerIncentiveReverseCell = ({
  wi,
  handleReverse,
  isProcessing,
}: {
  wi: WorkerIncentive
  handleReverse: (wi: WorkerIncentive) => void
  isProcessing: boolean
}) => {
  if (wi.status === 'REVERSED') {
    return null
  }
  return (
    <Row justifyBetween style={{ width: 140 }}>
      {wi.incentiveInfo.typeId === IncentiveTypeIds.dXgY && (
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => handleReverse(wi)}
          disabled={isProcessing}
          slim
          leftIcon={isProcessing ? undefined : <Icon name="undo" />}
          style={{ width: 120 }}
        >
          {isProcessing ? '...' : 'Revert'}
        </Button>
      )}
    </Row>
  )
}

function WorkerIncentivesTable({
  workerIncentives,
  isLoading,
  incentiveDetails,
  refetch,
}: {
  workerIncentives: WorkerIncentive[]
  isLoading: boolean
  incentiveDetails?: boolean
  refetch: () => void
}) {
  const [processing, setProcessing] = useState<string | undefined>()
  const { resyncWorkerIncentive, reverseWorkerIncentive, isLoadingIncentives } =
    useIncentives()
  const { trackAnalytics } = useAnalytics()

  const handleResync = useCallback(
    async (wi: WorkerIncentive) => {
      if (processing) {
        return
      }
      setProcessing(wi.id)

      const resyncRes = await resyncWorkerIncentive(wi.workerId, wi.incentiveId)
      setProcessing(undefined)

      window.alert(
        `Finished resync worker incentive:\n${resyncRes[0].progress || ''}\n${
          resyncRes[0].status || ''
        }\n${resyncRes[0].message || ''}`,
      )
    },
    [processing, resyncWorkerIncentive],
  )

  const handleReverse = useCallback(
    async (wi: WorkerIncentive) => {
      if (processing || !wi.id) {
        return
      }

      const reversalReason = window.prompt('Please enter the reversal reason:')
      if (!reversalReason) {
        alert('A reason is required to proceed with the reversal.')
        return
      }

      setProcessing(wi.workerIncentiveId)

      await reverseWorkerIncentive(wi.workerId, wi.id, reversalReason)

      setProcessing(undefined)
      refetch()
      trackAnalytics('Worker Incentive Reversed', {
        workerId: wi.workerId,
        workerIncentiveId: wi.id,
      })
    },
    [processing, reverseWorkerIncentive],
  )

  const rowsMemo = useMemo(
    () =>
      workerIncentives?.length
        ? workerIncentives.map((wi) => ({
            id: wi.id,
            incentiveId: wi.incentiveId,
            amount:
              wi.total?.amount &&
              wi.total?.currency &&
              getMoneyString({
                amount: wi.total.amount,
                currency: wi.total.currency,
              }),
            progress: {
              progress: wi.progress,
              isProcessing: processing === wi.id,
            },
            status: wi.status,
            earnedAt: wi.earnedAt,
            title: wi.incentiveInfo.title,
            typeId: wi.incentiveInfo.typeId,
            valueType: wi.incentiveInfo.valueType,
            referralType: wi.referralType,
            action: {
              wi,
              handleResync,
              isProcessing: !!processing,
            },
            reverse: {
              wi,
              handleReverse,
              isProcessing: !!processing,
            },
            workerId: wi.workerId,
          }))
        : [],
    [workerIncentives, processing, handleResync, handleReverse],
  )
  const columnsMemo = useMemo(
    () => (incentiveDetails ? incentiveDetailsColumns : workerDetailColumns),
    [incentiveDetails],
  )

  return (
    <DataGrid
      rows={rowsMemo}
      columns={columnsMemo}
      loading={isLoadingIncentives || isLoading}
      autoHeight
      hideFooterPagination
      disableColumnFilter
    />
  )
}

export default WorkerIncentivesTable
