import { SearchSelect, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { RecordStatus, Roster } from '@traba/types'
import React, { useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useRoles } from 'src/hooks/useRoles'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import { Button, Row, Table } from '../base'
import { ButtonVariant } from '../base/Button/types'
import { IMenuItem } from '../base/Select/Select'
import { ViewRosterRow } from './ViewRosterRow'

type RostersTableProps = {
  companyId: string
  data: Roster[]
  onClickNewRoster: () => void
  onClickEditRoster: React.Dispatch<React.SetStateAction<Roster | undefined>>
  onClickDeleteRoster: React.Dispatch<React.SetStateAction<Roster | undefined>>
}

export const RostersTable = (props: RostersTableProps) => {
  const {
    data,
    onClickNewRoster,
    onClickEditRoster,
    onClickDeleteRoster,
    companyId,
  } = props
  const [selectedVirtualRosterLocation, setSelectedVirtualRosterLocation] =
    useState<IMenuItem | undefined>(undefined)
  const [selectedVirtualRosterRole, setSelectedVirtualRosterRole] = useState<
    IMenuItem | undefined
  >(undefined)
  const { virtualRosters } = useVirtualRosters(
    companyId,
    selectedVirtualRosterLocation?.value,
    selectedVirtualRosterRole?.value,
  )

  const { locations } = useLocations(companyId)
  const { roles } = useRoles(companyId)

  const activeLocations =
    locations?.filter(
      (location) => location.recordStatus === RecordStatus.Active,
    ) ?? []
  const activeRoles =
    roles?.filter((role) => role.recordStatus === RecordStatus.Active) ?? []

  const locationOptions =
    activeLocations.map((location) => ({
      label:
        location.name === '' || location.name === undefined
          ? 'N/A'
          : location.name,
      value: location.locationId,
    })) ?? []
  const roleOptions =
    activeRoles.map((role) => ({
      label:
        role.roleName === '' || role.roleName === undefined
          ? 'N/A'
          : role.roleName,
      value: role.roleId,
    })) ?? []

  const onFilterChange = (value: IMenuItem | undefined, field: string) => {
    switch (field) {
      case 'location':
        setSelectedVirtualRosterLocation(value)
        break
      case 'role':
        setSelectedVirtualRosterRole(value)
        break
      default:
        break
    }
  }

  return (
    <>
      <Row
        justifyEnd
        alignCenter
        style={{
          paddingBottom: theme.space.xs,
          justifyContent: 'space-between',
        }}
      >
        <Text mb={theme.space.sm} variant="h4">
          Rosters
        </Text>
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={onClickNewRoster}
        >
          New Roster
        </Button>
      </Row>
      <Table>
        {data.map((rosterDetails: Roster) => (
          <ViewRosterRow
            key={rosterDetails.id}
            roster={rosterDetails}
            onClickDeleteRoster={onClickDeleteRoster}
            onClickEditRoster={onClickEditRoster}
          />
        ))}
      </Table>
      <Row
        justifyEnd
        alignCenter
        style={{
          paddingTop: theme.space.med,
          justifyContent: 'space-between',
        }}
      >
        <Text mb={theme.space.sm} variant="h4">
          Virtual Rosters
        </Text>
      </Row>
      <Row
        style={{
          paddingBottom: theme.space.xs,
        }}
      >
        <SearchSelect
          style={{ minWidth: 200, marginRight: theme.space.sm }}
          options={locationOptions}
          selectItem={selectedVirtualRosterLocation}
          handleSelect={(value) => onFilterChange(value, 'location')}
          label="Location"
        />
        <SearchSelect
          style={{ minWidth: 200, marginRight: theme.space.sm }}
          options={roleOptions}
          selectItem={selectedVirtualRosterRole}
          handleSelect={(value) => onFilterChange(value, 'role')}
          label="Role"
        />
      </Row>
      <Table>
        {virtualRosters.length > 0 &&
          virtualRosters.map((roster) => (
            <ViewRosterRow
              key={roster.id}
              roster={roster}
              onClickDeleteRoster={onClickDeleteRoster}
              onClickEditRoster={onClickEditRoster}
              isVirtualRoster={true}
            />
          ))}
      </Table>
    </>
  )
}

export default RostersTable
