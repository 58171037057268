import { Avatar } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Anchor, Text } from '@traba/react-components'
import format from 'date-fns/format'
import { useMemo } from 'react'
import AccountStatusBadge from 'src/components/base/Badge/AccountStatusBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import { formatPhoneNumber } from 'src/utils/stringUtils'
import { Button, CopyTextIcon, Link, Row } from '../../../components/base'
import { SortOrder } from '../../../hooks/usePagination'
import { PopulatedWorker } from '../worker-search.types'
import WorkerSearchDropdown from './WorkerSearchDropdown'

type WorkerSearchResultsProps = {
  isLoading?: boolean
  workers?: PopulatedWorker[]
  sort: { sortBy: string; sortOrder: SortOrder }
  runSearch: (sortBy?: string, order?: SortOrder) => void
  onSortChange: (field: string, order: SortOrder) => void
}

const makeWorkerTableRow = (worker: PopulatedWorker) => {
  const phoneNumber = formatPhoneNumber(worker?.phoneNumber || '', true)
  const accountStatus = worker.accountStatus
  const uid = worker.id
  if (!uid) {
    throw new Error('Worker must have an id')
  }

  return {
    id: uid,
    photoUrl: () => (
      <Link to={`/workers/${uid}`} target="_blank">
        <Avatar
          alt={`worker_${worker.id}_avatar`}
          src={worker.photoUrl}
          sizes="40px"
        />
      </Link>
    ),
    firstName: () => (
      <Link to={`/workers/${uid}`} target="_blank">
        <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
          {worker?.firstName} {worker?.lastName}
        </Button>
      </Link>
    ),
    accountStatus: () => (
      <AccountStatusBadge
        accountStatus={accountStatus.accountStatus}
        approvalStatusReason={accountStatus.approvalStatusReason}
        accountSuspensionExpiration={accountStatus.suspensionExpiration}
      />
    ),
    regionId: worker.regionId,
    tier: worker.workerMetric?.tierLevel,
    zipCode: worker.zipCode,
    phoneNumber: () => (
      <Row justifyBetween style={{ maxWidth: '18ch' }}>
        <Anchor openInNewTab={false} href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </Anchor>
        <CopyTextIcon textToCopy={phoneNumber} />
      </Row>
    ),
    workerId: () => (
      <Row justifyBetween>
        <Text>{uid.slice(0, 12)}...</Text>
        <CopyTextIcon textToCopy={uid} />
      </Row>
    ),
    lastActiveAt: worker.lastActiveAt
      ? format(new Date(worker.lastActiveAt), 'MMM dd, yyyy hh:mm a')
      : '-',
    actions: () => <WorkerSearchDropdown worker={worker} />,
  }
}

const columns: GridColDef[] = [
  {
    field: 'photoUrl',
    headerName: 'Photo',
    width: 80,
    renderCell: (params) => params.value(),
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'firstName',
    headerName: 'Name',
    width: 220,
    renderCell: (params) => params.value(),
    hideable: false,
    sortingOrder: ['asc', 'desc'],
    flex: 1,
  },
  {
    field: 'accountStatus',
    headerName: 'Status',
    type: 'js',
    width: 120,
    renderCell: (params) => params.value(),
    sortable: false,
  },
  {
    field: 'regionId',
    headerName: 'Region',
    width: 120,
    sortingOrder: ['asc', 'desc'],
    sortable: false,
  },
  {
    field: 'zipCode',
    headerName: 'Zip Code',
    width: 100,
    sortingOrder: ['asc', 'desc'],
    sortable: false,
  },
  {
    field: 'tier',
    headerName: 'Tier',
    width: 120,
    sortingOrder: ['asc', 'desc'],
    sortable: false,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 180,
    renderCell: (params) => params.value(),
    sortable: false,
    flex: 1,
  },
  {
    field: 'workerId',
    headerName: 'Worker ID',
    renderCell: (params) => params.value(),
    sortable: false,
    flex: 1,
  },
  {
    field: 'lastActiveAt',
    headerName: 'Last active',
    sortingOrder: ['asc', 'desc'],
    sortable: false,
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    renderCell: (params) => params.value(),
    sortable: false,
    align: 'right',
  },
]

export default function WorkerSearchResults({
  isLoading,
  workers = [],
  runSearch,
  onSortChange,
  sort,
}: WorkerSearchResultsProps) {
  const rows = useMemo(() => {
    const rows = workers.map((worker) => makeWorkerTableRow(worker))
    if (sort.sortOrder === SortOrder.desc && sort.sortBy === 'firstName') {
      rows.reverse()
    }
    return rows
  }, [workers, sort])

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      autoHeight
      hideFooterPagination
      disableColumnFilter
      onSortModelChange={(params) => {
        const field = params[0]?.field
        const order = params[0]?.sort === 'asc' ? SortOrder.asc : SortOrder.desc
        onSortChange(field, order)
        runSearch(field, order)
      }}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: sort.sortBy,
              sort: sort.sortOrder || 'asc',
            },
          ],
        },
      }}
    />
  )
}
