import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  SentinelEventAction,
  SentinelEventActionPreset,
  SentinelEventActionType,
  SentinelEventCustomActionType,
  SentinelNotification,
  SentinelTriggerBasedEventType,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import copyToClipboard from 'copy-to-clipboard'
import { useCallback, useMemo, useState } from 'react'
import { ButtonVariant } from 'src/components/base/Button/types'
import { useAnalytics } from '../../../hooks/useAnalytics'
import { useHotSettings } from '../../../hooks/useHotSettings'
import { Button, Icon, Row } from '../../base'
import { useModal } from '../../base/Modal/Modal'
import { WorkerCommunicationsWorkerMessages } from '../../WorkerCommunications/WorkerCommunicationsWorkerMessages'
import {
  enableActionForWorkersSelected,
  iconNameForEventActionType,
  isActionImplemented,
  showWorkerCommunicationsForNotificationEventType,
  showWorkerDetailsListForNotificationEventType,
  titleForEventActionType,
} from '../utils'
import { SentinelActionModals } from './SentinelActionModals'
import { SentinelWorkerDetailsSection } from './SentinelWorkerDetailsSection'

function getCustomAction(notification: SentinelNotification) {
  const { eventType, sentinelNotificationToUser, data } = notification
  const workerPhoneNumber = data?.worker?.phoneNumber
  const openPhoneDashboardUrl = 'https://my.openphone.com/inbox'

  const handleClick = () => {
    if (workerPhoneNumber) {
      copyToClipboard(workerPhoneNumber)
    }
    window.open(openPhoneDashboardUrl, '_blank')
  }

  if (
    eventType === SentinelTriggerBasedEventType.WORKER_ON_SHIFT_CALLED ||
    eventType === SentinelTriggerBasedEventType.WORKER_ON_SHIFT_MESSAGED ||
    eventType ===
      SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_CALLED ||
    eventType ===
      SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_MESSAGED
  ) {
    return (
      <Button
        key={`${sentinelNotificationToUser.id}_${eventType}`}
        slim
        variant={ButtonVariant.FILLED}
        mt={theme.space.xxxs}
        mr={theme.space.xxs}
        leftIcon={<Icon name="message" />}
        onClick={handleClick}
      >
        {titleForEventActionType(SentinelEventCustomActionType.OPENPHONE_LINK)}
      </Button>
    )
  }
}

export const SentinelActions = ({
  loading,
  notification,
  inlineActions,
  setSelectedWorkerIds,
  goToFullLog,
}: {
  loading: boolean
  notification: SentinelNotification
  inlineActions?: boolean
  setSelectedWorkerIds?: React.Dispatch<React.SetStateAction<string[]>>
  goToFullLog?: () => void
}) => {
  const [selectedPrimaryAction, setSelectedPrimaryAction] =
    useState<SentinelEventAction>()
  const [selectedPreset, setSelectedPreset] =
    useState<SentinelEventActionPreset>()
  const [selectedWorkerShifts, setSelectedWorkerShifts] = useState<
    WorkerShift[]
  >([])
  const { trackAnalytics } = useAnalytics()
  const { hotSettings } = useHotSettings()
  const { enableEchoMessagesInSentinel } = hotSettings || {}

  const editShiftModal = useModal()
  const bulkCommsDrawer = useModal()
  const bulkCancelModal = useModal()
  const bulkRobocallModal = useModal()
  const clockInModal = useModal()
  const clockOutModal = useModal()
  const noShowModal = useModal()
  const abandonModal = useModal()
  const acceptShiftModal = useModal()
  const directSmsModal = useModal()
  const directPushModal = useModal()

  const handleActionButtonClick = useCallback(
    (action: SentinelEventAction) => {
      trackAnalytics('Sentinel Action Clicked', {
        action,
        sentinelNotificationToUserId: notification.id,
      })

      switch (action.actionType) {
        case SentinelEventActionType.EDIT_SHIFT:
          editShiftModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.TRIGGER_ROBOCALLS_TO_WORKER:
          bulkRobocallModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.CANCEL_WORKERS:
          bulkCancelModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.CLOCK_IN_WORKERS:
          clockInModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.CLOCK_OUT_WORKERS:
          clockOutModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.NO_SHOW_WORKERS:
          noShowModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.ABANDON_WORKERS:
          abandonModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.ADD_WORKER_TO_SHIFT:
          acceptShiftModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.DIRECT_SMS:
          directSmsModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.DIRECT_PUSH:
          directPushModal.open()
          setSelectedPrimaryAction(undefined)
          break
        case SentinelEventActionType.BUGLE_PUSH:
        case SentinelEventActionType.BUGLE_SMS:
        case SentinelEventActionType.BACKFILL_CALLS:
          bulkCommsDrawer.open()
          break
        default:
          setSelectedPrimaryAction((prevAction) => {
            // Handle deselect
            if (prevAction?.actionType === action.actionType) {
              return
            }
            setSelectedPreset(undefined)
            return action
          })
      }
    },
    [
      editShiftModal,
      bulkRobocallModal,
      bulkCancelModal,
      clockInModal,
      clockOutModal,
      noShowModal,
      abandonModal,
      acceptShiftModal,
      directSmsModal,
      directPushModal,
      notification.id,
      trackAnalytics,
      bulkCommsDrawer,
    ],
  )

  const customAction = useMemo(
    () => getCustomAction(notification),
    [notification],
  )

  const actionButtons = useMemo(
    () =>
      notification.actionsSuggested?.map((action: SentinelEventAction) => {
        const iconName = iconNameForEventActionType(action.actionType)
        const isSelected =
          action.actionType === selectedPrimaryAction?.actionType

        return (
          <Button
            key={`${notification.sentinelNotificationToUser.id}_${action.actionType}`}
            slim
            variant={
              isSelected ? ButtonVariant.FILLED : ButtonVariant.PURPLEGRADIENT
            }
            mt={theme.space.xxxs}
            mr={theme.space.xxs}
            leftIcon={
              isSelected ? (
                <Icon name="checkmark" />
              ) : iconName ? (
                <Icon name={iconName} />
              ) : undefined
            }
            onClick={() => {
              handleActionButtonClick(action)
            }}
            disabled={
              !isActionImplemented(action.actionType) ||
              !enableActionForWorkersSelected(
                action.actionType,
                selectedWorkerShifts.length,
              )
            }
          >
            {titleForEventActionType(action.actionType)}
          </Button>
        )
      }),
    [
      notification.actionsSuggested,
      notification.sentinelNotificationToUser.id,
      selectedPrimaryAction?.actionType,
      selectedWorkerShifts.length,
      handleActionButtonClick,
    ],
  )

  const shiftId = notification.data.shift.shiftId
  const showWorkerList =
    shiftId &&
    showWorkerDetailsListForNotificationEventType(notification.eventType)
  const showWorkerCommunications =
    showWorkerCommunicationsForNotificationEventType(notification.eventType)

  const workerIds = useMemo(() => {
    return (
      notification.data.relevantWorkerShifts?.map((ws) => ws.workerId) ?? []
    )
  }, [notification.data.relevantWorkerShifts])

  return (
    <div
      style={{
        ...(inlineActions && {
          border: `1px solid ${theme.colors.Violet70}`,
          borderLeft: `4px solid ${theme.colors.Violet70}`,
          borderTop: 'none',
        }),
        width: '100%',
        marginBottom: theme.space.xs,
      }}
      key={notification.sentinelNotificationToUser.id}
    >
      <Text
        variant="h6"
        pt={!inlineActions ? theme.space.xs : 0}
        ml={theme.space.xs}
      >
        Actions
      </Text>
      <Row flexCol>
        {showWorkerList && (
          <SentinelWorkerDetailsSection
            shiftId={shiftId}
            workerIds={workerIds}
            setSelectedWorkerShifts={(workerShifts: WorkerShift[]) => {
              setSelectedWorkerShifts(workerShifts)
              setSelectedWorkerIds &&
                setSelectedWorkerIds(workerShifts.map((ws) => ws.workerId))
            }}
            notification={notification}
            slim={showWorkerCommunications}
          />
        )}
        {enableEchoMessagesInSentinel &&
          showWorkerCommunications &&
          notification.data.worker?.workerId && (
            <Row mx={theme.space.xs} mb={theme.space.xxs} flexCol>
              <WorkerCommunicationsWorkerMessages
                workerId={notification.data.worker?.workerId}
              />
              <Row
                justifyBetween
                justifyEnd
                py={theme.space.xxs}
                pr={theme.space.xs}
              >
                {goToFullLog && (
                  <Button
                    onClick={goToFullLog}
                    variant={ButtonVariant.OUTLINED}
                  >
                    Go to full log
                  </Button>
                )}
              </Row>
            </Row>
          )}
        <Row
          mx={theme.space.xs}
          style={{
            flexWrap: 'wrap',
          }}
        >
          {loading ? (
            <Row center>
              <CircularProgress size={theme.space.med} />
            </Row>
          ) : notification.actionsSuggested?.length ? (
            actionButtons
          ) : customAction ? (
            customAction
          ) : (
            <Text>No suggested actions</Text>
          )}
        </Row>
      </Row>
      <SentinelActionModals
        shiftId={shiftId}
        editShiftModal={editShiftModal}
        bulkCommsDrawer={bulkCommsDrawer}
        bulkCancelModal={bulkCancelModal}
        bulkRobocallModal={bulkRobocallModal}
        clockInModal={clockInModal}
        clockOutModal={clockOutModal}
        noShowModal={noShowModal}
        abandonModal={abandonModal}
        acceptShiftModal={acceptShiftModal}
        directSmsModal={directSmsModal}
        directPushModal={directPushModal}
        sentinelNotificationToUserId={
          notification.sentinelNotificationToUser.id
        }
        handleClose={() => {
          setSelectedPreset(undefined)
        }}
        selectedPrimaryAction={selectedPrimaryAction}
        selectedPreset={selectedPreset}
        selectedWorkerShifts={selectedWorkerShifts}
      />
    </div>
  )
}
