import { CircularProgress } from '@mui/material'
import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftAssignmentResponse, ShiftStatus } from '@traba/types'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import {
  AssigneeStatus,
  RobocallCommunication,
  Incentive,
  IncentiveStatus,
  WaitlistWithBasicWorkerOpsDetails,
  TrainingVideo,
  JobStatus,
  TransitStatusToDestination,
} from '@traba/types'
import { format, isPast, isSameDay } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Collapsible,
  CollapsibleIcon,
  Icon,
  IconButton,
  Row,
} from 'src/components/base'
import ShiftAssignmentBadge from 'src/components/base/Badge/ShiftAssignmentBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import ImagesModal from 'src/components/base/ImagesModal/ImagesModal'
import { useModal } from 'src/components/base/Modal/Modal'
import { IMenuItem } from 'src/components/base/Select/Select'
import { TableRow } from 'src/components/base/Table/DataTable'
import { CollapsibleShiftTable } from 'src/components/CollapsibleShiftTable'
import { CompanyHeader } from 'src/components/CompanyHeader'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { ShiftAndCompanyNoteDrawer } from 'src/components/ShiftAndCompanyNoteDrawer'
import ShiftAssignmentModal from 'src/components/ShiftAssigmentModal/ShiftAssigmentModal'
import { ShiftDetailsScheduleInfo } from 'src/components/ShiftDetails/ShiftDetailsScheduleInfo'
import WorkerEligibility from 'src/components/WorkerEligibility/WorkerEligibility'
import {
  calculateStatusCounts,
  constructStatusOptions,
  WorkerTable,
} from 'src/components/WorkerTable/WorkerTable'
import { UserRolePermission } from 'src/context/user/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useApi } from 'src/hooks/useApi'
import { useShiftAssignmentFilters } from 'src/hooks/useAssignments'
import { useAttributes } from 'src/hooks/useAttributes'
import { useCompany } from 'src/hooks/useCompany'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useBusinessComplaints } from 'src/hooks/useComplaints'
import { useCompanyConnections } from 'src/hooks/useConnections'
import { usePaidBackups } from 'src/hooks/usePaidBackups'
import { usePermissions } from 'src/hooks/usePermissions'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { useShift } from 'src/hooks/useShifts'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { useWaitlist } from 'src/hooks/useWaitlist'
import { useShiftWardenExemptions } from 'src/hooks/useWardenExemptions'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { useWorkerShiftTimeToDestinations } from 'src/hooks/useWorkerShiftTimeToDestinations'
import BulkActionModal from 'src/modals/BulkActionModal/BulkActionModal'
import BusinessComplaintModal from 'src/modals/BusinessComplaintModal/BusinessComplaintModal'
import CancelShiftModal from 'src/modals/CancelShiftModal/CancelShiftModal'
import DownloadReportModal from 'src/modals/DownloadReport/DownloadReport'
import { EditShiftModal } from 'src/modals/EditShiftModal/EditShiftModal'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import ShiftLocationEditModal from 'src/modals/LocationEditModal/ShiftLocationEditModal'
import ShiftBadgeModal from 'src/modals/ShiftBadgeModal/ShiftBadgeModal'
import ShiftTrainingVideosModal from 'src/modals/ShiftTrainingVideosModal/ShiftTrainingVideosModal'
import { WardenExemptionInfoModal } from 'src/modals/WardenExemptionInfoModal/WardenExemptionInfoModal'

import { normalizeString } from 'src/utils/stringUtils'
import {
  buildWorkerShiftTimeToDestinationMap,
  getWorkerShiftTimeToDestinationKey,
} from 'src/utils/workerShiftUtils'
import CommunicationsDrawer from '../../components/BugleDrawer/BugleDrawer'
import { ShiftDetailsTimeSheet } from '../../components/ShiftDetails/ShiftDetailsTimeSheet'
import { useHotSettings } from '../../hooks/useHotSettings'
import { ONE_MINUTE_IN_MS } from '../../libs/constants'
import BulkAdjustModal from '../../modals/BulkAdjustmentModal/BulkAdjustModal'
import { AddWorkerToShiftsModal } from '../FieldMonitorScreen/components/AddWorkers/AddWorkerToShiftsModal'
import { downloadArrayAsCSV } from '../WorkerSearchScreen/components/ExportDrawer/utils'
import { PopulatedWorker } from '../WorkerSearchScreen/worker-search.types'
import { AnnouncementsSection } from './components/AnnouncementsSection/AnnouncementsSection'
import DescriptionSection from './components/DescriptionSection/DescriptionSection'
import IncentiveItem from './components/IncentiveItem/IncentiveItem'
import IncidentsTable from './components/IncidentsTable/IncidentsTable'
import NotificationsTable from './components/NotificationsTable/NotificationsTable'
import PaidBackupsTable from './components/PaidBackupsTable/PaidBackupsTable'
import { SearchWorkers } from './components/SearchWorkers'
import { AddWorkersToShift } from './components/SearchWorkers/AddWorkersToShift'
import SentinelNotificationsSection from './components/SentinelNotificationsSection/SentinelNotificationsSection'
import ShiftInvitationsTable from './components/ShiftInvitationsTable/ShiftInvitationsTable'
import ShiftSettingsModal from './components/ShiftSettingsModal/ShiftSettingsModal'
import TransactionsTable from './components/TransactionsTable/TransactionsTable'
import WaitlistTable from './components/WaitlistTable/WaitlistTable'
import { WardenExemptionsSection } from './components/WardenExemptionsSection/WardenExemptionsSection'

const getShiftVideos = (
  trainingVideos: TrainingVideo[],
  videoIds: string[] | undefined,
) => {
  if (trainingVideos.length > 0 && !!videoIds && videoIds?.length > 0) {
    const results: TrainingVideo[] = []
    trainingVideos.forEach((tv) => {
      if (videoIds.includes(tv.id)) {
        results.push(tv)
      }
    })
    return results
  }
  return []
}

const parseWaitlistToCSV = (waitlist?: WaitlistWithBasicWorkerOpsDetails[]) => {
  return waitlist?.map((w) => ({
    workerId: w.workerDetails.id,
    firstName: w.workerDetails.firstName,
    lastName: w.workerDetails.lastName,
    phoneNumber: w.workerDetails.phoneNumber,
    ...w,
  }))
}

type WaitlistForCSV = {
  workerId: string
  firstName: string
  lastName: string
  phoneNumber: string
} & WaitlistWithBasicWorkerOpsDetails

export default function ShiftDetailsScreen() {
  const { shiftId = '' } = useParams<{ shiftId: string }>()
  const navigate = useNavigate()

  const { shift, isLoading: isLoadingShift } = useShift(shiftId)
  const { company, isLoading: isLoadingCompany } = useCompany(shift?.companyId)
  const { companyUsers } = useCompanyUsers(shift?.companyId)
  const { workerShifts = [], refetch: refetchWorkerShifts } =
    useWorkerShifts(shiftId)
  const {
    paidBackups = [],
    isLoading: isLoadingPaidBackups,
    refetch: refetchPaidBackups,
  } = usePaidBackups(shiftId)
  const { trainingVideos } = useTrainingVideos(shift?.companyId)
  const {
    waitlistWorkers,
    isLoading: isLoadingWaitlist,
    removeFromWaitlist,
  } = useWaitlist(shiftId)
  const {
    shiftWardenExemptions,
    isLoading: isLoadingWardenExemptions,
    refetch: refetchWardenExemptions,
  } = useShiftWardenExemptions(shiftId)
  const { state } = useUserContext()
  const [checkedWorkers, setCheckedWorkers] = useState<PopulatedWorker[]>([])

  const handleWaitlistCsv = useCallback(
    () =>
      downloadArrayAsCSV<WaitlistForCSV>(
        parseWaitlistToCSV(waitlistWorkers),
        'waitlists',
      ),
    [waitlistWorkers],
  )

  const { data: shiftIncentives, isLoading: areIncentivesLoading } = useApi<
    Incentive[]
  >(`incentives/query?shiftId=${shiftId}&status=${IncentiveStatus.Active}`)

  const { workerShiftTimeToDestinations } =
    useWorkerShiftTimeToDestinations(shiftId)
  const workerShiftTimeToDestinationsMap = useMemo(
    () =>
      buildWorkerShiftTimeToDestinationMap(workerShiftTimeToDestinations ?? []),
    [workerShiftTimeToDestinations],
  )

  const { businessComplaints, isLoading: isLoadingBusinessComplaints } =
    useBusinessComplaints(shiftId)
  const { data: robocalls } = useApi<RobocallCommunication[]>(
    `communication/robocalls?shiftId=${shiftId}`,
    undefined,
    {
      staleTime: ONE_MINUTE_IN_MS,
    },
  )
  const { shiftAssignmentsMapByShiftId, refetch: refetchAssignments } =
    useShiftAssignmentFilters({
      shiftIds: [shiftId],
      assigneeStatuses: [AssigneeStatus.Assigned],
    })

  const shiftAssignment: ShiftAssignmentResponse | undefined =
    shiftAssignmentsMapByShiftId.get(shiftId)
  const shiftAssignmentModal = useModal()

  const [isBugleOpen, setIsBugleOpen] = useState<boolean>(false)

  const [editShiftModalOpen, setEditShiftModalOpen] = useState<boolean>(false)

  const cancelShiftAccess = usePermissions([UserRolePermission.CancelShift])
  const bugleAccess = usePermissions([UserRolePermission.SendBugle])
  const [notesOpen, setNotesOpen] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<TableRow[]>([])
  const [
    isSentinelNotificationSectionCollapsed,
    setIsSentinelNotificationSectionCollapsed,
  ] = useState<boolean>(false)
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] =
    useState<boolean>(false)
  const [isAnnouncementsCollapsed, setIsAnnouncementsCollapsed] =
    useState<boolean>(false)
  const [mapCollapsed, setMapCollapsed] = useState<boolean>(true)
  const [isWaitlistCollapsed, setIsWaitlistCollapsed] = useState<boolean>(
    shift?.endTime ? isPast(new Date(shift?.endTime)) : true,
  )
  const [isPaidBackupsCollapsed, setIsPaidBackupsCollapsed] = useState<boolean>(
    shift?.endTime ? isPast(new Date(shift?.endTime)) : !paidBackups?.length,
  )
  const [isWardenExemptionsCollapsed, setIsWardenExemptionsCollapsed] =
    useState(true)
  const [isIncidentsCollapsed, setIsIncidentsCollapsed] =
    useState<boolean>(false)
  const [currentNotificationsTab, setCurrentNotificationsTab] =
    useState<number>(0)

  const selectedWorkerIds = selectedRows.map((sr) => sr.key)
  const selectedWorkerShifts = workerShifts.filter((ws) =>
    selectedWorkerIds.includes(ws.workerId),
  )

  const { favoriteWorkers } = useCompanyConnections(shift?.companyId)
  const favoriteWorkerIds = new Set(favoriteWorkers.map((worker) => worker.uid))

  /*
   * Build embed URL for map
   */
  const defaultEmbedURL = useMemo(() => {
    const convertedStartTime = shift?.startTime
      ? new Date(shift.startTime)
      : new Date()
    const dateString = format(convertedStartTime, 'yyyy-MM-dd')
    const embedURL = new URL('https://embed.hypertrack.com/tracking')
    embedURL.searchParams.append(
      'publishable_key',
      `${import.meta.env.VITE_HYPERTRACK_PUBLISHABLE_KEY}`,
    )
    embedURL.searchParams.append('view', 'trips')
    embedURL.searchParams.append(
      'trip_metadata_filter',
      `{"shiftId":"${shiftId}"}`,
    )
    embedURL.searchParams.append('from_date', dateString)
    return embedURL
  }, [shift?.startTime, shiftId])

  useEffect(() => {
    setEmbedURL(defaultEmbedURL.href)
  }, [defaultEmbedURL.href])

  const resetUrl = useCallback(() => {
    setEmbedURL(defaultEmbedURL.href)
  }, [defaultEmbedURL.href])

  const [embedURL, setEmbedURL] = useState<string>(defaultEmbedURL.href)

  const { attributes: roleAttributes } = useAttributes()

  useEffect(() => {
    if (shift?.startTime) {
      const shouldShowMapByDefault = isSameDay(
        new Date(shift?.startTime),
        new Date(),
      )

      if (shouldShowMapByDefault) {
        setMapCollapsed(false)
      }
    }
  }, [shift?.startTime])

  /*
  Modals
   */
  const bizComplaintModal = useModal()
  const cancelShiftModal = useModal()
  const downloadReportModal = useModal()
  const bulkAdjustModal = useModal()
  const bulkCancelModal = useModal()
  const bulkCancelMultiShiftModal = useModal()
  const bulkRobocallModal = useModal()
  const shiftSettingsModal = useModal()
  const imagesModal = useModal()
  const shiftLocationEditModal = useModal()
  const trainingVideosModal = useModal()
  const wardensExemptionInfoModal = useModal()
  const shiftBadgeModal = useModal()
  const addWorkersToShiftsModal = useModal()

  /*
  Image Modal
  */
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | undefined>()
  const handleClickImage = useCallback(
    (imageUrl: string) => {
      setSelectedImageUrl(imageUrl)
      imagesModal.open()
    },
    [imagesModal],
  )

  /*
  Location Edit Modal
  */
  const [prePopulatedEditLocationText, setPrePopulatedEditLocationText] =
    useState<string | undefined>()
  const handleClickEditLocation = useCallback(
    (prePopulatedText?: string) => {
      setPrePopulatedEditLocationText(prePopulatedText)
      shiftLocationEditModal.open()
    },
    [shiftLocationEditModal],
  )

  /*
  Training Videos Modal
  */
  const handleClickEditTrainingVideo = useCallback(() => {
    trainingVideosModal.open()
  }, [trainingVideosModal])

  /*
  shift badge Modal
  */
  const handleClickEditShiftBadge = useCallback(() => {
    shiftBadgeModal.open()
  }, [shiftBadgeModal])

  /* 
  Count statuses of each worker shift 
  */
  const statusCounts = useMemo(
    () => calculateStatusCounts(workerShifts),
    [workerShifts],
  )
  const statusOptions = useMemo(
    () => constructStatusOptions(statusCounts),
    [statusCounts],
  )

  const [statusFilters, setStatusFilters] = useState<IMenuItem[]>([])
  const {
    shiftInvitations,
    isLoading: isLoadingShiftInvitations,
    sendInvitations,
    bulkResendInvitations,
  } = useShiftInvitations(shiftId)

  // TODO memoize this
  // Filter worker shifts shown to those matching the selected status filters
  const filteredWorkerShifts = workerShifts.filter((workerShift) => {
    const statusFilterValues = statusFilters.map((s) => s.value as string)
    if (!statusFilterValues.length) {
      return true
    } // show everything if no filters selected
    const workerShiftTimeToDestination = workerShiftTimeToDestinationsMap.get(
      getWorkerShiftTimeToDestinationKey({
        workerId: workerShift.workerId,
        shiftId,
      }),
    )
    return (
      statusFilterValues.includes(workerShift.jobStatus) ||
      (workerShift.isShiftConfirmed &&
        statusFilterValues.includes('CONFIRMED')) ||
      (!!workerShift.workerSiteArrivalTime &&
        statusFilterValues.includes('ARRIVED')) ||
      //Compound Filters
      (workerShift.jobStatus === JobStatus.ToDo &&
        !workerShift.isShiftConfirmed &&
        statusFilterValues.includes('TODO But Not CONFIRMED')) ||
      (!!workerShift.workerSiteArrivalTime &&
        workerShift.jobStatus !== JobStatus.InProgress &&
        statusFilterValues.includes('ARRIVED But Not IN_PROGRESS')) ||
      (workerShift.jobStatus === JobStatus.ToDo &&
        (workerShiftTimeToDestination === undefined ||
          workerShiftTimeToDestination.transitStatus ===
            TransitStatusToDestination.IN_TRANSIT ||
          workerShiftTimeToDestination.transitStatus ===
            TransitStatusToDestination.NOT_LEFT) &&
        statusFilterValues.includes(
          'TODO & In Transit, Not Left or No Location Data',
        ))
    )
  })

  const [workerSearchString, setWorkerSearchString] = useState<string>('')
  const searchedWorkerShifts = useMemo(
    () =>
      filteredWorkerShifts.filter((workerShift) => {
        if (!workerSearchString) {
          return true
        }
        const searchString = normalizeString(workerSearchString)
        const searchableString = normalizeString(
          `${workerShift.worker.firstName} ${workerShift.worker.lastName} ${workerShift.workerId}`,
        )
        return searchableString.includes(searchString)
      }),
    [filteredWorkerShifts, workerSearchString],
  )

  const { hotSettings } = useHotSettings()

  if (isLoadingShift || isLoadingCompany || !hotSettings) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <CircularProgress
            size={75}
            sx={{
              position: 'absolute',
              top: '200px',
              left: '50%',
              marginTop: '-18px',
              marginLeft: '-18px',
            }}
          />
        </Row>
      </MainLayout>
    )
  }

  if (!company) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <Text variant="h1">We are having trouble loading your shift</Text>
        </Row>
      </MainLayout>
    )
  }

  if (!shift) {
    return (
      <MainLayout>
        <Row center alignCenter>
          <Text variant="h1">We are having trouble finding your shift</Text>
        </Row>
      </MainLayout>
    )
  }
  const shouldFreezeShiftPosting = hotSettings.freezeShiftsPosting

  const ShiftDetailsActions = () =>
    useMemo(() => {
      return (
        <Row my={theme.space.xxs} justifyBetween>
          <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
            <Icon name="leftArrow" style={{ paddingRight: '8px' }} />
            Go back
          </Button>

          <Row alignCenter>
            <Row style={{ flexWrap: 'wrap' }}>
              {shiftAssignment?.assignees.map((assignee) => (
                <Row
                  style={{ marginRight: theme.space.xxxs }}
                  key={`${assignee.id}`}
                >
                  <ShiftAssignmentBadge
                    shiftAssignment={shiftAssignment}
                    includeDelete={false}
                    internalUser={assignee.internalUser}
                  />
                </Row>
              ))}
            </Row>
            <Link to={`/field-monitor/${shiftId}/messages-log`}>
              <Button
                variant={ButtonVariant.OUTLINED}
                slim
                mr={theme.space.xs}
                onClick={() => shiftAssignmentModal.open()}
              >
                Messages log
              </Button>
            </Link>
            <Button
              variant={ButtonVariant.OUTLINED}
              slim
              mr={theme.space.xs}
              onClick={() => shiftAssignmentModal.open()}
            >
              Assign
            </Button>
            <IconButton
              iconName="accountSettings"
              onClick={shiftSettingsModal.open}
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <Button
              slim
              style={{ marginLeft: theme.space.xs }}
              variant={ButtonVariant.OUTLINED}
              onClick={() => setIsBugleOpen(true)}
              disabled={!bugleAccess}
            >
              Bugle
            </Button>
            <Button
              slim
              style={{ marginLeft: theme.space.xs }}
              variant={ButtonVariant.OUTLINED}
              onClick={() => setEditShiftModalOpen(true)}
              disabled={shouldFreezeShiftPosting}
            >
              Edit Shift
            </Button>
            <Button
              slim
              style={{ marginLeft: theme.space.xs }}
              variant={ButtonVariant.OUTLINED}
              onClick={() => bizComplaintModal.open()}
            >
              Log Biz Complaint
            </Button>
            {shift.status !== ShiftStatus.CANCELED && (
              <Button
                slim
                style={{ marginLeft: theme.space.xs }}
                variant={ButtonVariant.CANCEL}
                onClick={() => cancelShiftModal.open()}
                disabled={!cancelShiftAccess}
              >
                Cancel Shift
              </Button>
            )}
            <Link to={`/field-monitor/${shiftId}/logs`}>
              <Button
                slim
                variant={ButtonVariant.OUTLINED}
                style={{ marginLeft: theme.space.xxs }}
              >
                Audit Log
              </Button>
            </Link>
            <Link
              to={`/post/${company?.companyId}?duplicateShiftId=${shiftId}`}
            >
              <Button
                slim
                variant={ButtonVariant.FILLED}
                style={{ marginLeft: theme.space.xxs }}
              >
                Post Again
              </Button>
            </Link>
          </Row>
        </Row>
      )
    }, [])

  return (
    <MainLayout title={`${shift.shiftRole} @ ${shift.employerName}`}>
      <CompanyHeader shift={shift} />
      <Divider />

      <ShiftDetailsActions />
      <ShiftDetailsScheduleInfo shift={shift} />
      <CollapsibleShiftTable
        shifts={[
          {
            ...shift,
            company,
            workerShifts: workerShifts.map((ws) => ({
              ...ws,
              workerShiftTimeToDestination:
                workerShiftTimeToDestinationsMap.get(
                  getWorkerShiftTimeToDestinationKey({
                    workerId: ws.workerId,
                    shiftId,
                  }),
                ),
            })),
          },
        ]}
        shiftRequestParents={[]}
        roleAttributes={roleAttributes}
        disableCollapsible
      />

      {!!state.userProfile?.internalUser && (
        <>
          <Row mt={24}>
            <Text variant="h4" style={{ marginBottom: '16px' }}>
              Sentinel Notifications
            </Text>
            <CollapsibleIcon
              collapsed={isSentinelNotificationSectionCollapsed}
              setCollapsed={setIsSentinelNotificationSectionCollapsed}
            />
          </Row>
          <Row mb={36} flexCol>
            <Collapsible collapsed={isSentinelNotificationSectionCollapsed}>
              <SentinelNotificationsSection
                shiftId={shift.shiftId}
                timezone={shift.timezone}
                internalUser={state.userProfile.internalUser}
                onClose={() => setIsSentinelNotificationSectionCollapsed(true)}
              />
            </Collapsible>
          </Row>
        </>
      )}

      <Row mt={24}>
        <Text variant="h4" style={{ marginBottom: '16px' }}>
          Description
        </Text>
        <CollapsibleIcon
          collapsed={isDescriptionCollapsed}
          setCollapsed={setIsDescriptionCollapsed}
        />
      </Row>
      <Row mb={36} flexCol>
        <Collapsible collapsed={isDescriptionCollapsed}>
          <DescriptionSection
            companyUsers={companyUsers}
            shift={shift}
            handleClickEditLocation={handleClickEditLocation}
            handleClickImage={handleClickImage}
            handleClickEditTrainingVideo={handleClickEditTrainingVideo}
            handleClickEditShiftBadge={handleClickEditShiftBadge}
          />
        </Collapsible>
      </Row>

      <Row mt={24}>
        <Text variant="h4" style={{ marginBottom: '16px' }}>
          Announcements
        </Text>
        <CollapsibleIcon
          collapsed={isAnnouncementsCollapsed}
          setCollapsed={setIsAnnouncementsCollapsed}
        />
      </Row>
      <Row mb={36} flexCol>
        <Collapsible collapsed={isAnnouncementsCollapsed}>
          <AnnouncementsSection shiftId={shiftId} shift={shift} />
        </Collapsible>
      </Row>

      {!areIncentivesLoading &&
        Array.isArray(shiftIncentives) &&
        shiftIncentives.length > 0 && (
          <Row mb={36} flexCol>
            <Text variant="h4" style={{ marginBottom: '16px' }}>
              Shift Incentives
            </Text>
            <Row
              style={{
                flexWrap: 'wrap',
              }}
            >
              {shiftIncentives.map((incentive) => (
                <IncentiveItem incentive={incentive} />
              ))}
            </Row>
          </Row>
        )}

      <Text variant="h4">Add Workers to Shift</Text>

      <Row style={{ alignItems: 'end' }} alignStart>
        <SearchWorkers
          checkedWorkers={checkedWorkers}
          setCheckedWorkers={setCheckedWorkers}
          actionButton={
            <AddWorkersToShift
              shift={shift}
              workers={checkedWorkers}
              openModal={addWorkersToShiftsModal.open}
            />
          }
        />

        <AddWorkerToShiftsModal
          handleClose={addWorkersToShiftsModal.handleClose}
          isOpen={addWorkersToShiftsModal.isOpen}
          shiftsList={[shift]}
          workers={checkedWorkers}
        />
      </Row>
      <WorkerEligibility shiftId={shiftId} />

      <Row fullWidth mt={48} alignCenter>
        <Text variant="h4" style={{ marginBottom: '16px' }}>
          Worker Map
        </Text>
        <CollapsibleIcon
          collapsed={mapCollapsed}
          setCollapsed={setMapCollapsed}
        />
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ marginLeft: theme.space.xs }}
          onClick={resetUrl}
        >
          Reset
        </Button>
      </Row>
      <Collapsible collapsed={mapCollapsed}>
        <iframe
          id={`iframe_${shiftId}`}
          title={`shiftmap_${shiftId}`}
          src={embedURL}
          width="100%"
          height="700px"
        />
      </Collapsible>

      <Row mt={24}>
        <WorkerTable
          workerShifts={searchedWorkerShifts}
          shiftInvitations={shiftInvitations}
          robocalls={robocalls || []}
          shift={shift}
          statusOptions={statusOptions}
          statusFilters={statusFilters}
          setStatusFilters={setStatusFilters}
          workerSearchString={workerSearchString}
          setWorkerSearchString={setWorkerSearchString}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setEmbedURL={setEmbedURL}
          statusCounts={statusCounts}
          handleOpenBulkAdjustModal={bulkAdjustModal.open}
          handleOpenBulkCancelModal={bulkCancelModal.open}
          handleOpenBulkCancelMultiShiftModal={bulkCancelMultiShiftModal.open}
          handleOpenBulkRobocallModal={bulkRobocallModal.open}
          workerShiftTimeToDestinationsMap={workerShiftTimeToDestinationsMap}
          favoriteWorkerIds={favoriteWorkerIds}
        />
      </Row>
      {!!workerShifts.length && <ShiftDetailsTimeSheet shift={shift} />}
      <Row mt={60}>
        <Text variant="h4">Paid Backups ({paidBackups?.length || 0})</Text>
        <CollapsibleIcon
          collapsed={isPaidBackupsCollapsed}
          setCollapsed={setIsPaidBackupsCollapsed}
        />
      </Row>
      {!isLoadingPaidBackups && paidBackups.length > 0 && (
        <Row mt={24}>
          <Collapsible collapsed={isPaidBackupsCollapsed}>
            <PaidBackupsTable
              workerShiftTimeToDestinations={workerShiftTimeToDestinations}
              paidBackups={paidBackups}
              shift={shift}
              favoriteWorkerIds={favoriteWorkerIds}
              refetch={() => {
                refetchPaidBackups()
                refetchWorkerShifts()
              }}
            />
          </Collapsible>
        </Row>
      )}
      <Row mt={60} alignCenter justifyBetween>
        <Row>
          <Text variant="h4">Waitlist ({waitlistWorkers?.length || 0})</Text>
          <CollapsibleIcon
            collapsed={isWaitlistCollapsed}
            setCollapsed={setIsWaitlistCollapsed}
          />
        </Row>
        {!!waitlistWorkers?.length && (
          <IconButton
            iconName="download"
            onClick={handleWaitlistCsv}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        )}
      </Row>
      {!isLoadingWaitlist && waitlistWorkers && (
        <Row mt={24}>
          <Collapsible collapsed={isWaitlistCollapsed}>
            <WaitlistTable
              waitlistWorkers={waitlistWorkers}
              shift={shift}
              favoriteWorkerIds={favoriteWorkerIds}
              removeFromWaitlist={removeFromWaitlist}
            />
          </Collapsible>
        </Row>
      )}
      <Row mt={theme.space.lg} alignCenter>
        <Text variant="h4" mr={theme.space.xxxs}>
          Warden Exemptions ({shiftWardenExemptions?.length || 0})
        </Text>
        <IconButton
          iconName="info"
          style={{ padding: theme.space.xxxs }}
          onClick={() => wardensExemptionInfoModal.open()}
        />

        <CollapsibleIcon
          collapsed={isWardenExemptionsCollapsed}
          setCollapsed={setIsWardenExemptionsCollapsed}
        />
      </Row>
      {!isLoadingWardenExemptions && shiftWardenExemptions && (
        <Row mt={24}>
          <Collapsible collapsed={isWardenExemptionsCollapsed}>
            <WardenExemptionsSection
              shiftId={shiftId}
              wardenExemptions={shiftWardenExemptions}
              refetch={refetchWardenExemptions}
              workersOnShift={workerShifts
                .filter((ws) => ws.jobStatus !== 'CANCELED')
                .map((ws) => ws.worker)}
            />
          </Collapsible>
        </Row>
      )}
      <TransactionsTable workerShifts={workerShifts as WorkerShift[]} />

      <Row mt={60}>
        <Text variant="h4">
          Incident Log ({businessComplaints?.length || 0})
        </Text>
        <CollapsibleIcon
          collapsed={isIncidentsCollapsed}
          setCollapsed={setIsIncidentsCollapsed}
        />
      </Row>
      {!isLoadingBusinessComplaints && businessComplaints && (
        <Row mt={24}>
          <Collapsible collapsed={isIncidentsCollapsed}>
            <IncidentsTable businessComplaints={businessComplaints} />
          </Collapsible>
        </Row>
      )}

      <>
        <TabsContainer style={{ marginTop: theme.space.xl }}>
          <Tabs
            value={currentNotificationsTab}
            onChange={(_, v) => {
              setCurrentNotificationsTab(v)
            }}
          >
            <Tab label="Notifications" />
            <Tab label="Invitations" />
          </Tabs>
        </TabsContainer>
        <TabPanel value={currentNotificationsTab} index={0}>
          <NotificationsTable shift={shift} />
        </TabPanel>
        <TabPanel value={currentNotificationsTab} index={1}>
          <ShiftInvitationsTable
            shift={shift}
            shiftInvitations={shiftInvitations}
            isLoadingShiftInvitations={isLoadingShiftInvitations}
            sendInvitations={sendInvitations}
            bulkResendInvitations={bulkResendInvitations}
          />
        </TabPanel>
      </>

      <BusinessComplaintModal shift={shift} {...bizComplaintModal} />
      <CancelShiftModal
        shift={shift}
        {...cancelShiftModal}
        company={shift.company}
      />
      <DownloadReportModal {...downloadReportModal} shift={shift} />
      <BulkAdjustModal
        {...bulkAdjustModal}
        shift={shift}
        workerShifts={selectedWorkerShifts}
      />
      <BulkActionModal
        {...bulkCancelModal}
        action="cancel"
        workerShifts={selectedWorkerShifts}
      />
      <BulkActionModal
        {...bulkCancelMultiShiftModal}
        action="cancel multiple shifts"
        workerShifts={selectedWorkerShifts}
      />
      <BulkActionModal
        {...bulkRobocallModal}
        action="robocall"
        workerShifts={selectedWorkerShifts}
      />
      <ShiftSettingsModal shift={shift} {...shiftSettingsModal} />
      <ImagesModal
        {...imagesModal}
        imageUrls={selectedImageUrl ? [selectedImageUrl] : undefined}
      />
      <ShiftLocationEditModal
        {...shiftLocationEditModal}
        prePopulatedText={prePopulatedEditLocationText}
        shift={shift}
      />
      <ShiftTrainingVideosModal
        {...trainingVideosModal}
        videos={
          trainingVideos
            ? getShiftVideos(trainingVideos, shift.videoIds)
            : undefined
        }
        shift={shift}
      />
      <ShiftBadgeModal {...shiftBadgeModal} shift={shift} />
      <ShiftAndCompanyNoteDrawer
        companyId={shift.companyId}
        shiftId={shiftId}
        isOpen={notesOpen}
        setIsOpen={setNotesOpen}
      />
      <CommunicationsDrawer
        isOpen={isBugleOpen}
        onClose={() => setIsBugleOpen(false)}
        shift={shift}
      />
      <ShiftAssignmentModal
        handleClose={shiftAssignmentModal.handleClose}
        isOpen={shiftAssignmentModal.isOpen}
        shiftsList={[shift]}
        refetchAssignments={refetchAssignments}
        shouldRefetchAssignmentOnEachUpdate={true}
        shiftAssignmentsMapByShiftId={shiftAssignmentsMapByShiftId}
      />
      <EditShiftModal
        handleClose={() => setEditShiftModalOpen(false)}
        isOpen={editShiftModalOpen}
        shift={shift}
        minHourlyPayRate={getPayRate({
          companyMin: company?.minHourlyPayRate,
          hotSettingMin: hotSettings.platformMinHourlyPayRate,
        })}
      />
      <WardenExemptionInfoModal {...wardensExemptionInfoModal} />
    </MainLayout>
  )
}
