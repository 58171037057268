import { useEligibilityConnectionsMutation } from '@traba/hooks'
import { Dialog, MODAL_SIZE, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EligibilityConnectionMutationPayload } from '@traba/types'
import { useEffect, useState } from 'react'
import { Row } from 'src/components/base'
import { EligibilityConnectionContent } from 'src/modals/ManageWorkerModal/ManageWorkerEligibilityConnections'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

type CompanyConnectionModalProps = {
  companyId: string
  handleClose: () => void
  isOpen: boolean
  selectedWorkerId: string
  setSelectedWorkerId: React.Dispatch<React.SetStateAction<string>>
  showSelect?: boolean
}

export const CompanyEligibilityConnectionModal = (
  props: CompanyConnectionModalProps,
) => {
  const {
    isOpen,
    handleClose,
    companyId,
    selectedWorkerId,
    setSelectedWorkerId,
    showSelect,
  } = props

  const [selectedWorker, setSelectedWorker] = useState<PopulatedWorker[]>([])
  const [payload, setPayload] = useState<EligibilityConnectionMutationPayload>()
  const { mutate: updateEligibilityConnections } =
    useEligibilityConnectionsMutation()

  useEffect(() => {
    setSelectedWorkerId(
      selectedWorker[0]?.id || selectedWorker[0]?.workerId || '',
    )
  }, [selectedWorker, setSelectedWorkerId])

  const onConfirm = () => {
    if (!payload) {
      return
    }

    updateEligibilityConnections(payload)

    setSelectedWorkerId('')
    setSelectedWorker([])
    setPayload(undefined)
    handleClose()
  }

  const onClose = () => {
    setSelectedWorkerId('')
    setSelectedWorker([])
    setPayload(undefined)
    handleClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      size={MODAL_SIZE.EXTRA_EXTRA_LARGE}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      dialogTitle={'Create Connection for Company'}
    >
      <Row flexCol fullHeight fullWidth className="modalWrapper">
        {showSelect && (
          <>
            <Text variant="h5" style={{ marginRight: theme.space.xs }}>
              Select a worker to make the connection to
            </Text>
            <Row alignCenter mb={theme.space.sm}>
              <SearchWorkers
                checkedWorkers={selectedWorker}
                setCheckedWorkers={setSelectedWorker}
                hideApprovedOnlyToggle
                hideInstructionText
                selectOneOnly
              />
            </Row>
          </>
        )}
        {selectedWorkerId !== '' && (
          <EligibilityConnectionContent
            companyId={companyId}
            workerId={selectedWorkerId}
            setPayload={setPayload}
          />
        )}
      </Row>
    </Dialog>
  )
}
