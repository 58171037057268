import { useHotSettings } from '@traba/hooks'
import {
  HotSettings,
  InternalUser,
  Role,
  JOB_STATUSES_FOR_WORKERS_ON_THE_WAY,
  SentinelNotification,
  ShiftNotificationSettingsForShift,
  ShiftRequestParent,
} from '@traba/types'
import { RoleAttribute } from '@traba/types'
import { anyToDate, recurringSchedulesEnabled } from '@traba/utils'
import { useCallback, useMemo, useEffect } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import { useBulkSentinelNotificationSettings } from 'src/hooks/useSentinelNotifications'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { FieldMonitorFiltersType as FieldMonitorFilters } from 'src/screens/FieldMonitorScreen/components/FieldMonitorFilters/FieldMonitorFilters'
import { Table } from '../base'
import { IMenuItem } from '../base/Select/Select'
import { CollapsibleShiftRow } from '../CollapsibleShiftRow'
import { ShiftRequestParentRow } from '../ShiftRequestParentRow'
import {
  ShiftRequestParentWithShifts,
  isShiftRequestParent,
  isShiftRequestParentWithShifts,
  shouldShowShift,
} from './util'

export interface CollapsibleShiftTableProps {
  shifts: OpsExtendedShift[]
  shiftRequestParents: ShiftRequestParent[]
  sentinelNotifications?: {
    shiftId: string
    notifications: SentinelNotification[]
  }[]
  fieldMonitorFilters?: FieldMonitorFilters
  activeFilterCount?: number
  workerFirstName?: string
  workerLastName?: string
  disableCollapsible?: boolean
  roleAttributes?: RoleAttribute[]
  selectedShiftIds?: string[]
  setSelectedShiftIds?: React.Dispatch<React.SetStateAction<string[]>>
  assigneeDisplay?: IMenuItem[]
  assignedTo?: IMenuItem[]
  showSelect?: boolean
  ffMax?: IMenuItem[]
  uncollapseRow?: boolean
  onDisplayShiftsChange?: (displayedShiftIds: string[]) => void
}

function getItemDate(
  item: ShiftRequestParentWithShifts | OpsExtendedShift,
): Date {
  if (isShiftRequestParentWithShifts(item)) {
    return anyToDate(item.shifts[0].startTime)
  }
  return anyToDate(item.startTime)
}

function getDisplayItems({
  shiftRequestParents,
  shiftsToDisplay,
  hotSettings,
  activeFilterCount,
  assignedTo,
  ffMax,
  fieldMonitorFilters,
  workerFirstName,
  workerLastName,
  internalUser,
}: {
  shiftsToDisplay: OpsExtendedShift[]
  shiftRequestParents: ShiftRequestParent[]
  hotSettings?: HotSettings
  fieldMonitorFilters: FieldMonitorFilters | undefined
  activeFilterCount: number | undefined
  assignedTo: IMenuItem[] | undefined
  ffMax: IMenuItem[] | undefined
  workerFirstName: string | undefined
  workerLastName: string | undefined
  internalUser: InternalUser | undefined
}) {
  const unexpandedDisplayItems: (
    | ShiftRequestParentWithShifts
    | OpsExtendedShift
  )[] = []
  const shiftRequestParentIdToShifts = new Map<
    string,
    ShiftRequestParentWithShifts
  >(
    shiftRequestParents
      .filter((shiftRequestParent) =>
        recurringSchedulesEnabled({
          companyId: shiftRequestParent.companyId,
          hotSettings,
        }),
      )
      .map((shiftRequestParent) => [
        shiftRequestParent.shiftRequestParentId,
        { shiftRequestParent, shifts: [] },
      ]),
  )
  unexpandedDisplayItems.push(
    ...Array.from(shiftRequestParentIdToShifts.values()),
  )
  for (const shift of shiftsToDisplay) {
    if (
      !shift.shiftRequestParentId ||
      !recurringSchedulesEnabled({ companyId: shift.companyId, hotSettings })
    ) {
      unexpandedDisplayItems.push(shift)
      continue
    }
    const item = shiftRequestParentIdToShifts.get(shift.shiftRequestParentId)
    if (!item) {
      // should never happen
      continue
    }
    item.shifts.push(shift)
  }
  for (const item of unexpandedDisplayItems) {
    if (isShiftRequestParentWithShifts(item)) {
      item.shifts.sort((a, b) => {
        return (
          anyToDate(a.startTime).getTime() - anyToDate(b.startTime).getTime()
        )
      })
    }
  }
  unexpandedDisplayItems.sort((a, b) => {
    return getItemDate(a).getTime() - getItemDate(b).getTime()
  })

  function filterShifts(shifts: OpsExtendedShift[]) {
    const filteredShifts: OpsExtendedShift[] = []
    for (const shift of shifts) {
      const { showShift } = shouldShowShift({
        shift,
        fieldMonitorFilters,
        activeFilterCount,
        assignedTo,
        ffMax,
        internalUser,
        workerFirstName,
        workerLastName,
        useLegacyFieldMonitorFiltering:
          hotSettings?.useLegacyFieldMonitorFiltering ?? true,
      })
      if (showShift) {
        filteredShifts.push(shift)
      }
    }
    return filteredShifts
  }

  const filteredShiftsToDisplay = filterShifts(shiftsToDisplay)

  function shouldShowParent(shifts: OpsExtendedShift[]) {
    return filterShifts(shifts).length > 0
  }
  const displayItems: (OpsExtendedShift | ShiftRequestParent)[] = []
  for (const item of unexpandedDisplayItems) {
    if (isShiftRequestParentWithShifts(item)) {
      // don't add the shift request parent if there are no shifts to be shown
      // the filtering is done at the ShiftRow level so we have to recreate the
      // filtering here
      if (!shouldShowParent(item.shifts)) {
        continue
      }
      displayItems.push(item.shiftRequestParent)
      displayItems.push(...item.shifts)
      continue
    }
    displayItems.push(item)
  }
  return { displayItems, shiftRequestParentIdToShifts, filteredShiftsToDisplay }
}

const isSentinelNotificationsMuted = (
  shiftId: string,
  sentinelNotificationSettings:
    | Record<string, ShiftNotificationSettingsForShift>
    | undefined,
  internalUserId?: string,
) => {
  return (
    sentinelNotificationSettings?.[shiftId]?.shiftSettings.shiftSilenced ||
    sentinelNotificationSettings?.[shiftId]?.userSpecificSettings[
      internalUserId ?? ''
    ]?.shiftSilenced
  )
}

export const CollapsibleShiftTable = (props: CollapsibleShiftTableProps) => {
  const {
    selectedShiftIds,
    setSelectedShiftIds,
    showSelect,
    shifts,
    shiftRequestParents,
    activeFilterCount,
    assignedTo,
    ffMax,
    workerFirstName,
    workerLastName,
    fieldMonitorFilters,
    onDisplayShiftsChange,
  } = props
  const { sentinelNotifications, ...rest } = props
  const { hotSettings } = useHotSettings()

  const handleSelectShiftId = useCallback(
    (shiftId: string) => {
      setSelectedShiftIds &&
        setSelectedShiftIds((prevShiftIds) =>
          prevShiftIds.includes(shiftId)
            ? prevShiftIds.filter((id) => id !== shiftId)
            : [...prevShiftIds, shiftId],
        )
    },
    [setSelectedShiftIds],
  )

  const headers = [
    '',
    'Employer Name',
    'Short Location / Region',
    'Role',
    'date',
    'start time',
    'end time',
    'reliability',
    'on the way',
    'confirmed',
    'clock in',
    'clock out',
    'slots filled',
    'paid',
    'notes',
    '',
  ]
  const modifiedHeaders = showSelect ? headers : headers.slice(1)

  const shiftsToDisplay = useMemo(() => shifts, [shifts])

  const { state } = useUserContext()

  const { data: sentinelNotificationSettings } =
    useBulkSentinelNotificationSettings({
      shiftIds: shiftsToDisplay.map((shift) => shift.id),
      internalUserId: state.userProfile?.internalUser?.id,
    })

  const {
    displayItems,
    shiftRequestParentIdToShifts,
    filteredShiftsToDisplay,
  } = useMemo(
    () =>
      getDisplayItems({
        shiftsToDisplay,
        shiftRequestParents,
        hotSettings,
        activeFilterCount,
        fieldMonitorFilters,
        assignedTo,
        ffMax,
        workerFirstName,
        workerLastName,
        internalUser: state.userProfile?.internalUser,
      }),
    [
      shiftsToDisplay,
      shiftRequestParents,
      hotSettings,
      activeFilterCount,
      fieldMonitorFilters,
      assignedTo,
      ffMax,
      workerFirstName,
      workerLastName,
      state.userProfile?.internalUser,
    ],
  )

  const numCols = modifiedHeaders.length
  const shiftComponents = useMemo(() => {
    let lastSeenShiftRequestParentId: string | undefined
    return displayItems.map(
      (displayItem: OpsExtendedShift | ShiftRequestParent, i: number) => {
        if (isShiftRequestParent(displayItem)) {
          const item = shiftRequestParentIdToShifts.get(
            displayItem.shiftRequestParentId,
          )
          if (!item) {
            return undefined
          }
          const roles: Role[] = []
          for (const shift of item.shifts) {
            if (shift.role) {
              roles.push(shift.role)
            }
          }
          lastSeenShiftRequestParentId = displayItem.shiftRequestParentId
          return (
            <ShiftRequestParentRow
              shiftRequestParent={displayItem}
              employerName={item.shifts[0].company.employerName}
              regionId={item.shifts[0].regionId}
              rolesInShiftRequestParent={roles}
              key={displayItem.shiftRequestParentId}
              numCols={numCols}
              locationName={
                item.shifts[0].location.name ||
                item.shifts[0].location.shortLocation
              }
              schedules={item.shifts[0].schedules}
            />
          )
        }
        const shift = displayItem
        const workersMovingOrArrived =
          shift.workerShifts
            ?.filter((ws) => !!ws.workerShiftTimeToDestination)
            ?.filter((ws) =>
              JOB_STATUSES_FOR_WORKERS_ON_THE_WAY.has(ws.jobStatus),
            ).length || 0
        const propsList = {
          shift: { ...shift, workersMovingOrArrived },
          onSelectShift: setSelectedShiftIds ? handleSelectShiftId : undefined,
          isSelected: !!selectedShiftIds?.includes(shift.id),
          isSentinelNotificationsMuted: isSentinelNotificationsMuted(
            shift.id,
            sentinelNotificationSettings,
            state.userProfile?.internalUser?.id,
          ),
          sentinelNotifications: sentinelNotifications
            ?.filter((s) => s.shiftId === shift.id)
            .flatMap((s) => s.notifications)
            .filter(
              (notification) =>
                notification.sentinelNotificationToUser?.internalUserId &&
                notification.sentinelNotificationToUser.internalUserId ===
                  state.userProfile?.internalUser?.id,
            ),
          ...rest,
        }
        const nextItem = displayItems[i + 1]
        const isInShiftRequestParentGroup =
          shift.shiftRequestParentId === lastSeenShiftRequestParentId
        const includeBottomBorder =
          isInShiftRequestParentGroup &&
          (nextItem === undefined ||
            isShiftRequestParent(nextItem) ||
            nextItem.shiftRequestParentId !== lastSeenShiftRequestParentId)
        const hasShiftRequestParent = !!(
          shift.shiftRequestParentId &&
          shiftRequestParentIdToShifts.has(shift.shiftRequestParentId)
        )
        return (
          <CollapsibleShiftRow
            {...propsList}
            key={`${shift.id}-${shift.createdAt}`}
            includeBorderSides={
              recurringSchedulesEnabled({
                companyId: shift.companyId,
                hotSettings,
              }) &&
              isInShiftRequestParentGroup &&
              hasShiftRequestParent
            }
            includeBorderBottom={
              recurringSchedulesEnabled({
                companyId: shift.companyId,
                hotSettings,
              }) &&
              includeBottomBorder &&
              hasShiftRequestParent
            }
            hideLocation={
              recurringSchedulesEnabled({
                companyId: shift.companyId,
                hotSettings,
              }) &&
              isInShiftRequestParentGroup &&
              hasShiftRequestParent
            }
            numCols={numCols}
          />
        )
      },
    )
  }, [
    displayItems,
    sentinelNotificationSettings,
    state.userProfile?.internalUser?.id,
    sentinelNotifications,
    setSelectedShiftIds,
    rest,
    selectedShiftIds,
    handleSelectShiftId,
    numCols,
    shiftRequestParentIdToShifts,
    hotSettings,
  ])

  useEffect(() => {
    if (onDisplayShiftsChange) {
      onDisplayShiftsChange(filteredShiftsToDisplay.map((shift) => shift.id))
    }
  }, [filteredShiftsToDisplay, onDisplayShiftsChange])

  return <Table headers={modifiedHeaders}>{shiftComponents}</Table>
}
