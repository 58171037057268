import { Col, ModalButtons, Row } from '@traba/react-components'
import { RequiredAttributeLevel, RoleAttribute } from '@traba/types'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'src/components/base/Modal/Modal'
import { RequiredAttributeLevelsSections } from 'src/components/RequiredAttributeLevelsSections/RequiredAttributeLevelsSections'
import { valueEdited } from 'src/utils/helperUtils'

interface Props {
  attributes: RoleAttribute[]
  handleConfirm: () => void
  handleClose: () => void
  isOpen: boolean
  requiredAttributeLevels: RequiredAttributeLevel[]
  setRequiredAttributeLevels: Dispatch<SetStateAction<RequiredAttributeLevel[]>>
}

export function ShiftAttributesModal(props: Props) {
  const { isOpen, attributes, handleClose } = props

  const [requiredAttributeLevels, setRequiredAttributeLevels] = useState<
    RequiredAttributeLevel[]
  >(props.requiredAttributeLevels)

  useEffect(() => {
    if (isOpen) {
      setRequiredAttributeLevels(props.requiredAttributeLevels)
    }
  }, [isOpen, setRequiredAttributeLevels, props.requiredAttributeLevels])

  function handleConfirm() {
    props.setRequiredAttributeLevels(requiredAttributeLevels)
    props.handleConfirm()
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Shift Required Attribute Levels"
    >
      <Col>
        <RequiredAttributeLevelsSections
          attributes={attributes}
          setRequiredAttributeLevels={setRequiredAttributeLevels}
          requiredAttributeLevels={requiredAttributeLevels}
        />

        <Row>
          <ModalButtons
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            isDisabled={
              !valueEdited(
                requiredAttributeLevels,
                props.requiredAttributeLevels,
              )
            }
          />
        </Row>
      </Col>
    </Modal>
  )
}
