import { ScheduleDaySquares } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import {
  formatScheduleDateRange,
  getAddressString,
  isRecurringShiftRequest,
} from '@traba/utils'
import { useMemo } from 'react'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { SupervisorInfo } from 'src/screens/ShiftDetailsScreen/components/DescriptionSection/SupervisorInfo'
import { Row } from '../base'
import { ScheduleListItem } from './ScheduleListItem'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}

export const ScheduleDetailsContentSection = ({
  shiftRequestParent,
}: Props) => {
  const { locations } = useLocations(shiftRequestParent.companyId)
  const { companyUsers } = useCompanyUsers(shiftRequestParent?.companyId || '')
  const firstRecurringShiftRequest = useMemo(
    () =>
      shiftRequestParent.shiftRequests.find((shiftRequest) => {
        return shiftRequest.schedules.some(
          (schedule) => schedule.isRecurringSchedule,
        )
      }),
    [shiftRequestParent.shiftRequests],
  )
  const tz = useTimezonedDates(firstRecurringShiftRequest?.timezone)
  const firstSchedule = firstRecurringShiftRequest?.schedules[0]

  const parkingAddress = useMemo(() => {
    const address = locations?.find(
      (location) =>
        location.locationId === firstRecurringShiftRequest?.parkingLocationId,
    )?.address
    return address ? getAddressString(address) : 'Same as shift location'
  }, [locations, firstRecurringShiftRequest?.parkingLocationId])

  const shiftAddress = useMemo(() => {
    const address = locations?.find(
      (location) =>
        location.locationId === firstRecurringShiftRequest?.locationId,
    )?.address
    return address ? getAddressString(address) : 'No location'
  }, [locations, firstRecurringShiftRequest?.locationId])

  const supervisor = useMemo(
    () =>
      companyUsers?.find(
        (user) => user.uid === firstRecurringShiftRequest?.supervisorId,
      ),
    [companyUsers, firstRecurringShiftRequest?.supervisorId],
  )

  const roles = useMemo(
    () =>
      shiftRequestParent.shiftRequests
        .filter((sr) => isRecurringShiftRequest(sr))
        .map((sr) => `${sr.slotsRequested} ${sr.shiftRole}`),
    [shiftRequestParent.shiftRequests],
  )

  if (!firstSchedule) {
    return null
  }
  const timeString = tz.getShiftTime(
    firstSchedule.startTime,
    firstSchedule.endTime,
  )

  return (
    <>
      <Row mt={theme.space.sm} wrap>
        <ScheduleListItem
          label="SCHEDULE RANGE"
          content={formatScheduleDateRange(
            firstRecurringShiftRequest?.schedules,
          )}
        />
        <ScheduleListItem
          label="ROLES FOR EACH SHIFT"
          content={roles.join(', ')}
        />
        <ScheduleListItem label="LOCATION" content={shiftAddress} />
      </Row>
      <Row mt={theme.space.sm} wrap>
        <ScheduleListItem
          label="WEEKLY PATTERN"
          content={
            <Row wrap>
              {firstRecurringShiftRequest.schedules.map((schedule, index) => (
                <ScheduleDaySquares
                  repeatOn={schedule.recurringSchedule?.repeatOn || []}
                  instance={index}
                />
              ))}
            </Row>
          }
        />
        <ScheduleListItem label="PARKING LOCATION" content={parkingAddress} />
      </Row>
      <Row mt={theme.space.sm}>
        <ScheduleListItem label="SHIFT START - END" content={timeString} />
        <ScheduleListItem
          label="SUPERVISOR"
          content={<SupervisorInfo supervisor={supervisor} />}
        />
      </Row>
    </>
  )
}
