import { CircularProgress } from '@mui/material'
import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import { WorkerShiftForOps as WorkerShift } from '@traba/types'
import { Shift } from '@traba/types'
import { JobStatus } from '@traba/types'
import { HandleAdjustmentParams } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import { FutureAdjustmentForm } from 'src/components/AdjustmentForm/FutureShiftAdjustmentForm'
import { Modal, Row } from 'src/components/base'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useUserContext } from 'src/context/user/UserContext'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { getQueryParams } from 'src/hooks/useApi'
import { extractOptionalDate } from 'src/utils/dateUtils'
import { getErrorMessage } from 'src/utils/errorUtils'
import { AdjustmentForm } from '../../components/AdjustmentForm/AdjustmentForm'

type AdjustmentModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShift?: WorkerShift
  shift?: Shift
  isLoading?: boolean
}

export default function AdjustmentModal({
  isOpen,
  handleClose,
  workerShift,
  shift,
  isLoading,
}: AdjustmentModalProps) {
  /*
  Hooks
  */
  const { refetchActiveQueries } = useActiveQueries()
  const { showError } = useAlert()
  const { state } = useUserContext()

  const handleAdjustment = async ({
    shouldAdjustPayment,
    shouldInstantPay,
    adjustmentReason,
    clockInTime,
    clockOutTime,
    breaks,
    minPaidTime,
    unitsWorked,
    breakType,
    payRate,
    adjustmentLabel,
  }: HandleAdjustmentParams) => {
    if (!workerShift) {
      return
    }
    try {
      const queryString = getQueryParams([
        ['shouldAdjustPayment', shouldAdjustPayment.toString()],
        ['shouldInstantPay', shouldInstantPay.toString()],
      ])

      const clockInTimeValue = extractOptionalDate(
        clockInTime,
        workerShift.clockInTime,
      )

      const clockOutTimeValue = extractOptionalDate(
        clockOutTime,
        workerShift.clockOutTime,
      )

      await trabaApi.post(
        `/workers/${workerShift.workerId}/worker-shifts/${
          workerShift.shiftId
        }/adjustments${shouldAdjustPayment ? `${queryString}` : ''}`,
        {
          adjustmentReason,
          adjustmentLabel,
          source: `OPS`,
          clockInTime: clockInTimeValue,
          clockOutTime: clockOutTimeValue,
          breaks,
          unitsWorked,
          shiftInfo: {
            payRate,
            minimumPaidTime: minPaidTime,
            breakType,
          },
        },
      )
      window.analytics.track('Worker Shift Adjusted From Console', {
        workerId: workerShift.workerId,
        shiftId: workerShift.shiftId,
        adjustmentReason,
        shouldAdjustPayment,
        shouldInstantPay,
        adjustedBy: state.userProfile?.email,
      })
    } catch (e: unknown) {
      showError(getErrorMessage(e), 'Error Adjusting Worker')
    }
    refetchActiveQueries()
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={'Adjust Payment'}
    >
      {isLoading || !shift || !workerShift ? (
        <Row fullWidth fullHeight alignCenter justifyCenter>
          <CircularProgress size={theme.space.xxxl} />
        </Row>
      ) : workerShift.jobStatus === JobStatus.Complete ? (
        <AdjustmentForm
          handleClose={handleClose}
          handleAdjustment={handleAdjustment}
          workerShifts={[workerShift]}
          unitsPerWorker={
            shift.numberOfUnits
              ? shift.numberOfUnits / shift.slotsRequested
              : undefined
          }
        />
      ) : (
        <FutureAdjustmentForm
          shift={shift}
          handleClose={handleClose}
          workerShifts={[workerShift]}
        />
      )}
    </Modal>
  )
}
