import { CircularProgress } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { Text } from '@traba/react-components'
import { ScheduleStrip } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  BGCRequirement,
  CompanyCategoryValues,
  PaymentType,
} from '@traba/types'
import { ExtendedShift, ShiftPayType } from '@traba/types'
import { RoleAttribute, RoleAttributeStatus } from '@traba/types'
import { useMemo } from 'react'
import { Badge, Col, CopyTextIcon, Icon, Row } from 'src/components/base'
import { convertCentsToDollars } from 'src/utils/moneyUtils'
import { getShiftCode } from 'src/utils/shiftCodeUtils'
import {
  getEstimatedRevenuePerWorkerShift,
  getEstimatedGrossPayForWorkerShift,
} from 'src/utils/shiftUtils'
import {
  getAddressString,
  getMoneyString,
  toPercentString,
  truncateString,
} from 'src/utils/stringUtils'
import { OpsExtendedShift, useShift } from '../../hooks/useShifts'
import { AttributeBadge } from '../AttributeBadge/AttributeBadge'
import GenderBadge from '../base/Badge/GenderBadge'
import { EnvironmentCopyLink } from '../base/EnvironmentCopyLink/EnvironmentCopyLink'
import * as S from './ShiftDetails.styles'
import HeaderWithDetailItem from './ShiftDetailsItem'

export interface ShiftDetailsProps {
  shift: ExtendedShift
  roleAttributes?: RoleAttribute[]
}

export const ShiftDetails = (props: ShiftDetailsProps) => {
  const {
    shift: { shiftId, company },
    roleAttributes,
  } = props
  const { shift, isLoading } = useShift(shiftId)
  const { hotSettings } = useHotSettings()

  const shiftCode = useMemo(() => getShiftCode(shift?.id), [shift?.id])
  const requestedAttributes = useMemo(() => {
    return (
      shift?.requiredAttributes
        ?.filter(
          (attribute) =>
            !hotSettings?.allowStoringRequiredAttributeLevel ||
            !shift.requiredAttributeLevels?.some((level) =>
              typeof attribute === 'string'
                ? level.attribute.type === attribute
                : level.attribute.type === attribute.type,
            ),
        )
        ?.map((attribute, i) => {
          const roleAttribute = roleAttributes?.find((sa) =>
            typeof attribute === 'string'
              ? sa.type === attribute
              : sa.type === attribute.type,
          )
          const name = roleAttribute?.displayName ?? ''
          const active = roleAttribute?.status === RoleAttributeStatus.Active
          const title = `${active ? '🔒' : ''} ${name}`
          return <Badge sm title={title} key={`${shift.id}_${i}_${title}`} />
        }) ?? []
    )
  }, [shift, hotSettings, roleAttributes])

  if (isLoading) {
    return (
      <S.ShiftDetailsWrapper>
        <CircularProgress size={theme.space.med} />
      </S.ShiftDetailsWrapper>
    )
  }

  if (!shift) {
    return (
      <S.ShiftDetailsWrapper>
        <Text variant="error"> No Shift found with id {shiftId}</Text>
      </S.ShiftDetailsWrapper>
    )
  }

  const addressString = getAddressString(shift.location.address)
  const parkingAddressString =
    shift.parkingLocation && getAddressString(shift.parkingLocation.address)
  const payRateString = getMoneyString(shift.payRate)

  // Truncate markup string at 4th decimal
  const trimmedCalculatedMarkup = truncateString(
    `${shift.calculatedMarkup * 100}`,
    7,
  )

  const estimatedRevenuePerWorkerShift =
    getEstimatedRevenuePerWorkerShift(shift)
  const estimatedGrossPayForWorkerShift =
    getEstimatedGrossPayForWorkerShift(shift)
  const estimatedTotalRevenueForShift =
    estimatedRevenuePerWorkerShift * shift.slotsRequested

  const invoiceGroupNameToDisplay = shift.invoiceGroupName ? (
    shift.invoiceGroupName
  ) : !!shift.eventName && shift.invoiceGroupId && !shift.invoiceGroupName ? (
    <>[legacy group] {shift.eventName}</>
  ) : (
    '-'
  )
  const invoiceGroupIdToDisplay = shift.parentInvoiceGroupId ? (
    <div>
      {truncateString(shift.parentInvoiceGroupId, 20)}
      <CopyTextIcon textToCopy={shift.parentInvoiceGroupId} />
    </div>
  ) : null

  const getPaymentType = (shift: OpsExtendedShift) => {
    if (shift.paymentType) {
      return shift.paymentType
    }

    return shift.payType === ShiftPayType.UNIT
      ? PaymentType.MANUAL
      : PaymentType.AUTO
  }

  return (
    <S.ShiftDetailsWrapper>
      {/* Col 1 */}
      <Col>
        <HeaderWithDetailItem title="address" renderTextWrapper={false}>
          {addressString} <CopyTextIcon textToCopy={addressString} />
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="industry" renderTextWrapper={false}>
          {company.category ? CompanyCategoryValues[company.category] : '-'}{' '}
        </HeaderWithDetailItem>
        {parkingAddressString && (
          <HeaderWithDetailItem title="parking address">
            {parkingAddressString}{' '}
            <CopyTextIcon textToCopy={parkingAddressString} />
          </HeaderWithDetailItem>
        )}
        <HeaderWithDetailItem title="shift id" renderTextWrapper={false}>
          {shift.id} <CopyTextIcon textToCopy={shift.id} />
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="shift code" renderTextWrapper={false}>
          {shiftCode} <CopyTextIcon textToCopy={shiftCode} />
        </HeaderWithDetailItem>
        <HeaderWithDetailItem
          title="shift request id"
          renderTextWrapper={false}
        >
          {shift.shiftRequestId}{' '}
          <CopyTextIcon textToCopy={shift.shiftRequestId} />
        </HeaderWithDetailItem>
        {shift.schedules && (
          <HeaderWithDetailItem title="shift schedule">
            <ScheduleStrip schedules={shift.schedules}></ScheduleStrip>
          </HeaderWithDetailItem>
        )}
        <HeaderWithDetailItem title="forward fill max">
          {shift.forwardFillMax}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="estimated shift revenue">
          {getMoneyString(estimatedTotalRevenueForShift)}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem
          title="Shift Details Deeplink"
          tooltipText="Use this url to direct workers to this shift in the worker app"
          renderTextWrapper={false}
        >
          {shift.shiftDetailsDeeplink ? (
            <>
              {truncateString(shift.shiftDetailsDeeplink, 20)}
              <CopyTextIcon textToCopy={shift.shiftDetailsDeeplink} />
            </>
          ) : (
            '-'
          )}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem
          title="Field Monitor Link"
          renderTextWrapper={false}
        >
          <EnvironmentCopyLink
            subdirectory={`field-monitor/${shift.shiftId}`}
            meticulousIgnore={true}
          />
        </HeaderWithDetailItem>
      </Col>

      {/* Col 2 */}
      <Col>
        <HeaderWithDetailItem title="pay rate">
          {payRateString}{' '}
          {shift.payType === ShiftPayType.UNIT &&
            ` - ${shift.numberOfUnits} Units`}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="pay type">
          {shift.payType} ({getPaymentType(shift)})
        </HeaderWithDetailItem>
        {!!shift.paidBackupPayAmount && (
          <HeaderWithDetailItem title="paid backup pay amount">
            ${convertCentsToDollars(shift.paidBackupPayAmount)}
          </HeaderWithDetailItem>
        )}
        <HeaderWithDetailItem
          title={
            hotSettings?.allowStoringRequiredAttributeLevel
              ? 'requested Attributes'
              : 'required attributes'
          }
          renderTextWrapper={false}
        >
          <div>{requestedAttributes.length ? requestedAttributes : '-'}</div>
        </HeaderWithDetailItem>
        {hotSettings?.allowStoringRequiredAttributeLevel && (
          <HeaderWithDetailItem
            title="required attribute levels"
            renderTextWrapper={false}
          >
            <div>
              {shift.requiredAttributeLevels?.length
                ? shift.requiredAttributeLevels.map((attribute) => {
                    const roleAttribute = roleAttributes?.find(
                      (sa) => sa.type === attribute.attribute.type,
                    )
                    if (!roleAttribute) {
                      return null
                    }
                    return (
                      <AttributeBadge
                        attributeName={
                          roleAttribute?.displayName ?? attribute.attribute.type
                        }
                        attributeLevel={attribute.level}
                        attributeType={attribute.attribute.type}
                        levelType={roleAttribute?.levelType}
                        category={attribute.attribute.category}
                        fontSize={10}
                        iconSize={10}
                        readOnly
                        sm
                      />
                    )
                  })
                : '-'}
            </div>
          </HeaderWithDetailItem>
        )}
        <HeaderWithDetailItem
          title="required certifications"
          renderTextWrapper={false}
        >
          {shift.requiredCertifications?.map((c) => (
            <Badge sm title={c.replace('_', ' ')} key={`${c}_${shift.id}`} />
          ))}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="gender preference">
          {shift.genderPreference ? (
            <GenderBadge gender={shift.genderPreference} />
          ) : (
            '-'
          )}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="estimated revenue per worker shift">
          {getMoneyString(estimatedRevenuePerWorkerShift)}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="estimated payment per worker">
          {getMoneyString(estimatedGrossPayForWorkerShift)}
        </HeaderWithDetailItem>
      </Col>

      {/* Col 3 */}
      <Col>
        <HeaderWithDetailItem title="clock in / clock out">
          {shift.confirmationCode} / {shift.clockOutCode}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="break type / breaks">
          {shift.breakType} /{' '}
          {shift.scheduledBreaks.length > 0
            ? shift.scheduledBreaks
                .map((b) => `${b.count} x ${b.breakLength} minutes`)
                .join(', ')
            : '-'}
        </HeaderWithDetailItem>

        <HeaderWithDetailItem title="minimum accepted tier">
          {shift.minimumAcceptedTier}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="signup status">
          {shift.signupStatus}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="required multi shift">
          {String(shift.isInRequiredMultiShift)}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="invoice group">
          {invoiceGroupNameToDisplay}
          {invoiceGroupIdToDisplay}
        </HeaderWithDetailItem>
      </Col>

      {/* Col 4 */}
      <Col>
        <HeaderWithDetailItem title="unproven threshold">
          {shift.unprovenWorkerThreshold
            ? `${toPercentString(shift.unprovenWorkerThreshold)}%`
            : 'N/A'}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="min. paid time / markup">
          {shift.minimumPaidTime
            ? `${(shift.minimumPaidTime / 60).toFixed(2)}/hour`
            : 'No minimum'}{' '}
          / {trimmedCalculatedMarkup}%
        </HeaderWithDetailItem>

        <HeaderWithDetailItem title="backfill / tolerance">
          {shift.backfillSettings.allowsBackfill ? 'Yes' : 'No'}
          {shift.backfillSettings.allowsBackfill &&
            ` / ${shift.backfillSettings.lateTolerance} minutes`}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="company id" renderTextWrapper={false}>
          {shift.companyId} <CopyTextIcon textToCopy={shift.companyId} />
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="location id" renderTextWrapper={false}>
          <Row alignCenter>
            <Icon name="backPack" style={{ height: '16px', marginRight: 4 }} />
            <Text variant="body1" style={{ fontWeight: '500', marginRight: 4 }}>
              {shift.locationId}
            </Text>
            <CopyTextIcon textToCopy={shift.locationId} />
          </Row>
          {shift.parkingLocationId && (
            <Row alignCenter>
              <Icon name="parking" style={{ height: '16px', marginRight: 4 }} />
              <Text
                variant="body1"
                style={{ fontWeight: '500', marginRight: 4 }}
              >
                {shift.parkingLocationId}
              </Text>
              <CopyTextIcon textToCopy={shift.parkingLocationId} />
            </Row>
          )}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem
          title="Overbooking Method"
          renderTextWrapper={false}
        >
          {shift.enableDynamicOverbooking ? 'Dynamic' : 'Manual'}
        </HeaderWithDetailItem>
        <HeaderWithDetailItem title="BGC Requirement" renderTextWrapper={false}>
          <Badge
            title={shift.extraBGCRequirement}
            variant={
              shift.extraBGCRequirement === BGCRequirement.CLEAN
                ? 'danger'
                : 'info'
            }
          />
        </HeaderWithDetailItem>
      </Col>
    </S.ShiftDetailsWrapper>
  )
}
