import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { CoordinatesDto } from '@traba/types'
import { trabaApi } from 'src/api/helpers'

const WORKER_DISTANCES_QUERY_KEY = 'workerDistances'

export type WorkerZipCodes = {
  workerId: string
  zipCode?: string
}[]

const getWorkerDistances = async (
  workerZipCodes: WorkerZipCodes,
  shiftCoords?: CoordinatesDto,
) => {
  if (!shiftCoords) {
    return {}
  }
  try {
    const { data } = await trabaApi.post(`/google/query-geocode`, {
      workerZipCodes,
      shiftCoords,
    })
    return data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}

export const useWorkerDistances = (
  workerZipCodes: WorkerZipCodes,
  shiftCoords?: CoordinatesDto,
) => {
  const queryKey = [
    WORKER_DISTANCES_QUERY_KEY,
    shiftCoords?.latitude,
    shiftCoords?.longitude,
    workerZipCodes.map((w) => `${w.workerId}:${w.zipCode}`).join(','),
  ]
  const {
    isLoading,
    isError,
    data: workerDistances,
    error,
    isFetched,
  } = useQuery({
    queryKey,
    queryFn: () => getWorkerDistances(workerZipCodes, shiftCoords),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    workerDistances,
  }
}
