import {
  SentinelCheckpointBasedEventType,
  SentinelToastSettings,
  SentinelTriggerBasedEventType,
} from '@traba/types'

export const SentinelEventTypeToToastSettingsMap: Record<
  SentinelTriggerBasedEventType | SentinelCheckpointBasedEventType,
  SentinelToastSettings
> = {
  [SentinelTriggerBasedEventType.WORKER_LATE_CANCELED]:
    SentinelToastSettings.WORKER_LATE_CANCELED,
  [SentinelTriggerBasedEventType.WORKER_LEFT_JOBSITE]:
    SentinelToastSettings.WORKER_LEFT_JOBSITE,
  [SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL]:
    SentinelToastSettings.WORKER_ACCEPTED_BACKFILL_CALL,
  [SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP]:
    SentinelToastSettings.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP,
  [SentinelTriggerBasedEventType.WORKER_ON_SHIFT_CALLED]:
    SentinelToastSettings.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP,
  [SentinelTriggerBasedEventType.WORKER_ON_SHIFT_MESSAGED]:
    SentinelToastSettings.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP,
  [SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_CALLED]:
    SentinelToastSettings.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP,
  [SentinelTriggerBasedEventType.CONTACTED_WORKER_ON_WAITLIST_MESSAGED]:
    SentinelToastSettings.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP,
  [SentinelTriggerBasedEventType.WORKER_CONFIRMED_OVER_SLOTS_REQUESTED]:
    SentinelToastSettings.WORKER_CONFIRMED_OVER_SLOTS_REQUESTED,
  [SentinelCheckpointBasedEventType.WORKERS_PAYMENT_STOPPED]:
    SentinelToastSettings.WORKERS_PAYMENT_STOPPED,
  [SentinelCheckpointBasedEventType.WORKERS_NOT_CONFIRMED]:
    SentinelToastSettings.WORKERS_NOT_CONFIRMED,
  [SentinelCheckpointBasedEventType.WORKERS_LOCATION_STATUS]:
    SentinelToastSettings.WORKERS_LOCATION_STATUS,
  [SentinelCheckpointBasedEventType.WORKERS_NOT_CLOCKED_IN]:
    SentinelToastSettings.WORKERS_NOT_CLOCKED_IN,
  [SentinelCheckpointBasedEventType.WORKERS_NOT_CLOCKED_OUT]:
    SentinelToastSettings.WORKERS_NOT_CLOCKED_OUT,
  [SentinelCheckpointBasedEventType.SHIFT_NOT_FILLED]:
    SentinelToastSettings.SHIFT_NOT_FILLED,
  [SentinelCheckpointBasedEventType.WORKERS_TRANSIT_STATUS]:
    SentinelToastSettings.WORKERS_TRANSIT_STATUS,
}
