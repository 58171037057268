import { ARCHIVED_REGION_ID } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  COMPANY_WIDE_ID,
  Locations,
  OpsCreateLocationDto,
  OpsUpdateLocationDto,
} from '@traba/types'
import {
  isLocationActive,
  isLocationArchived,
  isLocationValid,
} from '@traba/utils'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

async function getLocations(
  companyId: string,
): Promise<Locations[] | undefined> {
  try {
    const res = await trabaApi.get(`companies/${companyId}/locations`)
    return res.data || []
  } catch (error: any) {
    console.error(
      'useLocations -> getLocations() ERROR',
      error.message ?? error,
    )
  }
}

const createLocation = async ({
  companyId,
  newLocation,
}: {
  companyId: string
  newLocation: OpsCreateLocationDto
}) => {
  const response = await trabaApi.post(
    `companies/${companyId}/locations`,
    newLocation,
  )
  return response.data
}

const editLocation = async ({
  companyId,
  locationId,
  updatedLocation,
}: {
  companyId: string
  locationId: string
  updatedLocation: OpsUpdateLocationDto
}) => {
  const response = await trabaApi.patch(
    `companies/${companyId}/locations/${locationId}`,
    updatedLocation,
  )
  return response.data
}

const archiveLocation = async ({
  companyId,
  locationId,
}: {
  companyId: string
  locationId: string
}) => {
  const response = await trabaApi.patch(
    `companies/${companyId}/locations/${locationId}/archive`,
  )
  return response.data
}

export function useLocations(companyId: string) {
  const { showError, handleError, showSuccess } = useAlert()
  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched,
    refetch,
  } = useQuery<Locations[] | undefined, Error>(
    `locations_${companyId}`,
    () => getLocations(companyId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const createLocationMutation = useMutation<any, AxiosError, any>(
    createLocation,
    {
      onSuccess: () => {
        showSuccess('', 'Location Created')
        refetch()
      },
      onError: (error) => {
        handleError(
          error,
          'useLocations -> createLocation()',
          JSON.stringify(error),
          'Error creating location',
        )
      },
    },
  )

  const editLocationMutation = useMutation<any, AxiosError, any>(editLocation, {
    onSuccess: (response: any) => {
      if (!response.updatedLocation) {
        return
      }
      let successMessage =
        'Arrival Location Changes were successfully saved. Future shifts tied to this location have been updated.'
      if (response.archivedLocationId) {
        successMessage +=
          ' Previous or ongoing shifts wont be updated. If you used the ops override, any shifts that have not started will be updated. Otherwise, only shifts starting later than 2 hours from now will be updated.' +
          ' Please update the shift area fence as well if necessary'
      }

      showSuccess(successMessage, 'Edit location successful')
      refetch()
    },
    onError: (error) => {
      if (error.message === 'edit-location/exceeds-radius-threshold') {
        showError(
          'The new arrival location you entered is greater than 1 mile from the original address. You can override this by using the ops override checkbox.',
          'Location edit exceeds radius threshold.',
        )
      } else {
        handleError(
          error,
          'useLocations -> editLocation()',
          JSON.stringify(error),
          'Error editing location',
        )
      }
    },
  })

  const archiveLocationMutation = useMutation<any, AxiosError, any>(
    archiveLocation,
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error) => {
        if (error.message === 'archive/active-shifts') {
          showError(
            'You cannot delete a location that is tied to shifts in the future.',
            'Unable to archive location',
          )
        } else {
          handleError(
            error,
            'useLocations -> archiveLocation()',
            JSON.stringify(error),
            'Error archiving location',
          )
        }
      },
    },
  )

  const validLocations = locations?.filter(isLocationValid) || []
  const activeLocations = locations?.filter(isLocationActive) || []
  const activeValidLocations = activeLocations.filter(isLocationValid)

  return {
    isLoading,
    isError,
    locations,
    validLocations,
    activeValidLocations,
    error,
    isFetched,
    refetch,
    archiveLocation: archiveLocationMutation.mutate,
    createLocationAsync: createLocationMutation.mutateAsync,
    editLocation: editLocationMutation.mutate,
  }
}

export function useRegionLocationMap(companyId: string) {
  const { isLoading, validLocations, activeValidLocations } =
    useLocations(companyId)

  const regionLocationMap = validLocations.reduce<Record<string, Locations[]>>(
    (acc, location) => {
      const key = isLocationArchived(location)
        ? ARCHIVED_REGION_ID
        : location.regionId || COMPANY_WIDE_ID
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(location)
      return acc
    },
    {},
  )

  const activeRegionsWithLocationsMap = activeValidLocations.reduce<
    Record<string, Locations[]>
  >((acc, location) => {
    if (!isLocationArchived(location)) {
      const key = location.regionId || COMPANY_WIDE_ID
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(location)
    }
    return acc
  }, {})

  return { isLoading, regionLocationMap, activeRegionsWithLocationsMap }
}
